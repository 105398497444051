import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {copyRight} from '../../../utils/Variables';
import * as Reqst from '../../../utils/Reqst';
import Sanitize from '../../../utils/Sanitize';
import {notify} from 'react-notify-toast';
import {Nav,Navbar,NavDropdown,Row,Col,Table,Modal} from 'react-bootstrap';
import swal from 'sweetalert';

import FullPageLoader from '../../../containers/FullPageLoader';

const styles = {
	testTd: {
	    fontSize: '15px',
		fontWeight: '600',
		borderBottom: '1px solid #dedede',
		paddingBottom: '5px',
		marginBottom: '3px',
		cursor: 'pointer',
	},
	unitStyle: {
		fontSize: '12px',
		paddingLeft: '10px',
	},
	rangeStyle: {
		fontSize: '13px',
		color: 'grey',
	},
	priceStyle: {
		float: 'right',
		paddingRight: '30px',
	}
}

class Edit extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginId: Sanitize.getData('loginUser_id'),
			companyId: Sanitize.getData('labUser_clientid'),
			masterId: Sanitize.getData('labUser_masterid'),
			saveText: 'UPDATE PROFILE',
			isRedirect: false,
			showLoader: false,
			showFullLoader: false,
			showAddModal: false,
			showTestList: false,
			groupData: [],
			editData: this.props.editData,
			isView: this.props.viewOnly ? true : false,
			testList: [],
			selectedTest: [],
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		
		this.loadGroupMasters();
		var gid = this.props.editData.groupid;
		console.log(this.props.editData);
		this.loadTestByGroup(gid);
		if(this.state.isView)
		{
			$(".in-test").attr('readonly',true);			
		}
	}
	
	
	loadGroupMasters(){
		this.setState({showFullLoader:true});
		var token = Reqst.TimeStamp();
		Reqst.axGet('groupmaster/getactivegroups/'+this.state.masterId+'/'+this.state.companyId+'/'+token).then(res=>{
			if(res['data'].status=='1')
			{
				this.setState({groupData: res['data'].data, showFullLoader:false});	
			}
			else
			{
				this.setState({showFullLoader:false});	
			}
		});
	}
	
	loadTestByGroup(groupId){
		
		if(groupId==null || groupId=='') return;
		var tList  = this.props.editData.testlist;
		this.setState({showFullLoader:true,testList: []});
		var token = Reqst.TimeStamp();
		Reqst.axGet('testmaster/testlistbygroup/'+this.state.masterId+'/'+this.state.companyId+'/'+groupId+'/'+token).then(res=>{
			if(res['data'].status=='1')
			{
				var t = res['data'].data;
				var tl =tList.split(',');
				var dt = [];
				if(Object.keys(t).length>0 && tl.length>0)
				{
					for(var i=0;i<tl.length;i++)
					{
						var _d = t.filter(function(obj){
							return (obj.id==tl[i]);
						});
						dt.push(_d);
					}
				}
				this.setState({testList: res['data'].data,selectedTest: dt, showFullLoader:false});	
			}
			else
			{
				this.setState({showFullLoader:false});	
			}
		});
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			var dt = this.state.selectedTest;
			if(Object.keys(dt).length>0)
			{
				this.setState({showLoader:true,saveText:'Updating..'});
				var form = new FormData();
				var d = Sanitize.objectifyForm($("#frmAdd").serializeArray());
								
				Reqst.axPost('profilemaster/updateprofile',$("#frmAdd").serialize()).then(res=>{
				
					if(res['data'].status=='1')
					{
						//document.getElementById("frmAdd").reset();
						notify.show(res['data'].msg,'success');
						this.setState({showLoader:false, saveText:'UPDATE PROFILE'});
						if(this.props.afterFinish) this.props.afterFinish();
					}
					else
					{
						notify.show(res['data'].msg,'error');
						this.setState({showLoader:false,saveText:'UPDATE PROFILE'});
					}
				});
			}
			else
			{
				swal({title:'WARNING',text: 'Please select atleast one test to update the Profile.!'});
			}
		}
		catch(error){
			alert('UNKNOWN ERROR PLEASE CONTACT THE ADMIN..!');
		}
	}
	
	checkTestInSelectedList(testId){
		try{
			
			var d = this.state.selectedTest.filter(function(obj){
				return (obj[0].id==testId);
			});
			//console.log(d);
			return (d.length>0) ? true : false;
		}
		catch(error){
			console.log(error);
			return false;
		}
	}
	
	handleTestTdClick(testId){
		try{
			
			var dT =this.state.selectedTest;
			//console.log(dT);
			var index = dT.findIndex(function(obj){
				return (obj[0].id==testId);
			});
			
			if(index > -1)
			{
				dT.splice(index,1);
				this.setState({selectedTest:dT});
				//console.log(d);
			}
			else
			{
				var t = this.state.testList.filter(function(obj){
					return (obj.id==testId);
				});
				dT.push(t);
				this.setState({selectedTest:dT});
			}
		}
		catch(error){
			console.log(error);
		}
	}
	
	getData(field){
		var l = this.state.editData;
		return l[field];
	}
	
	setData(field,e){
		var l = this.state.editData;
		l[field] = e.target.value;
		this.setState({editData: l });
	}
	
	render(){
		
		return(
			<div>			
				<FullPageLoader loading={this.state.showFullLoader} />
				
				<form action="" method="post" encType="multipart/form-data" id="frmAdd" onSubmit={this.handleFormSubmit} >
					<input type="hidden" name="i__id" value={this.getData('id')} />
					<input type="hidden" name="i__lid" value={this.state.loginId} />
					<input type="hidden" name="i__company" value={this.state.companyId} />
					<input type="hidden" name="i__master" value={this.state.masterId} />
						{
							this.state.selectedTest.map((test,key)=>{
								return <input type="hidden" name="i__test[]" value={test[0].id} />;
							})
						}
					<Row>
					{this.state.isView ? null :
						<Col md={12} >
							<div className="pull-right">
								<button type="button" className="btn btn-warning btn-sm" onClick={()=>{this.setState({showTestList: !this.state.showTestList})}} >
									{this.state.showTestList ? 'Add' : 'Add (or) Remove Test'}
								</button>
							</div>
					</Col>}
						<Col md={4} >
							<Row>
								<Col md={12} >
									<label>Profile Code <span className="text-danger">*</span></label>
									<input type="text" name="i__code" id="i__code" className="form-control input-sm in-test" required value={this.getData('profilecode')} onChange={this.setData.bind(this,'profilecode')} />
								</Col>
							</Row>
							<Row>
								<Col md={12} >
									<label>Profile Name <span className="text-danger">*</span></label>
									<input type="text" name="i__name" id="i__name" className="form-control input-sm in-test" required value={this.getData('profilename')} onChange={this.setData.bind(this,'profilename')} />
								</Col>
							</Row>
							<Row>
								<Col md={12} >
									<label>Under Group <span className="text-danger">*</span></label>
									<select name="i__group" id="i__group" className="form-control input-sm in-test" required onChange={(e)=>{this.loadTestByGroup(e.target.value)}} >
										<option value="">Select Group</option>
									{
										this.state.groupData.map((item,i)=>{
											return <option value={item.id} selected={this.getData('groupid')==item.id ? 'selected' : ''} > {item.groupname} </option>;
										})
									}
									</select>
								</Col>
							</Row>
							<Row>
								<Col md={12} >
									<label>Profile Price <span className="text-danger"></span></label>
									<input type="number" name="i__price" id="i__price" className="form-control input-sm in-test" value={this.getData('profilerate')} onChange={this.setData.bind(this,'profilerate')}  />
								</Col>
							</Row>
							<Row>
								<Col md={12} >
									<label>Active Status <span className="text-danger">*</span></label>
									<select name="i__status" id="i__status" className="form-control input-sm in-test" required >
										<option value="1" selected={this.getData('profilestatus')=='1' ? 'selected' : ''} > Active </option>
										<option value="0" selected={this.getData('profilestatus')=='0' ? 'selected' : ''}  > In Active </option>
									</select>
								</Col>
							</Row>
						</Col>
						{this.state.showTestList ? 
						<Col md={8} >
							<label>Select Test <span className="text-danger"></span></label>
							<div className="testlist-div scroll-style-3" >
							<table style={{ width: '100%' }} align="center" >
								{Object.keys(this.state.testList).length>0 ?
									<tbody>
									{
										this.state.testList.map((test,key)=>{									
										return <tr onClick={this.handleTestTdClick.bind(this,test.id)} >
												<td style={styles.testTd} className={this.checkTestInSelectedList(test.id) ? 'test-td-selected' : 'no-selected'} >
													<div >{test.testname} <span style={styles.unitStyle} >{test.testunit}</span></div>
													<div style={styles.rangeStyle} >{test.referencerange}
														<span style={styles.priceStyle} >&#8377;  {test.price}</span>
													</div>
												</td>
											</tr>;
										})
									}
								</tbody>
								:
								
								<tr>
									<td align="center" >
										<div className="text-danger">
											No test found.!<br />Please select anyone group
										</div>
									</td>
								</tr>}
							</table>
							</div>
						</Col>
						:
						<Col md={8} >
							<label>Test Under Profile <span className="text-danger"></span></label>
							<div className="testlist-div scroll-style-3" >
							<table style={{ width: '100%' }} align="center" >
								{Object.keys(this.state.selectedTest).length>0 ?
									<tbody>
									{
										this.state.selectedTest.map((test,key)=>{									
										return <tr>
												<td style={styles.testTd} >
													<div >{test[0].testname} <span style={styles.unitStyle} >{test[0].testunit}</span></div>
													<div style={styles.rangeStyle} >{test[0].referencerange}
														<span style={styles.priceStyle} >&#8377;  {test[0].price}</span>
													</div>
												</td>
											</tr>;
										})
									}
								</tbody>
								:
								
								<tr>
									<td align="center" >
										<div className="text-danger">
											No test found.!
										</div>
									</td>
								</tr>}
							</table>
							</div>
						</Col>
						}
					</Row>
					{this.state.showTestList || this.state.isView ? null :
					<Row>
						<Col md={12} >
							<div className="text-right" style={{ marginTop: 25 }} >
								<button type="submit" className="btn btn-primary btn-sm btn-success_2" disabled={this.state.saveText!='UPDATE PROFILE'} >
									<i className="fa fa-check"></i> {this.state.saveText} 
								</button>
							</div>
						</Col>
					</Row>}
				</form>
			</div>
		);
	}
	
}
export default Edit;
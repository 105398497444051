import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {appName,copyRight} from '../../../utils/Variables';
import * as Reqst from '../../../utils/Reqst';
import Sanitize from '../../../utils/Sanitize';
import {notify} from 'react-notify-toast';
import {Helmet} from "react-helmet";
import {Nav,Navbar,NavDropdown,Row,Col,Table,Modal,DropdownButton, Dropdown} from 'react-bootstrap';
import Toggle from 'react-bootstrap-toggle';
import "react-bootstrap-toggle/dist/bootstrap2-toggle.css";
import Roles from '../../../utils/Roles';
import swal from 'sweetalert';
import Pagination from "react-js-pagination";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

import PageTitle from '../../../containers/PageTitle';
import WithoutHeader from '../Print/WihtoutHeader';

class DailyReport extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginId: Sanitize.getData('loginUser_id'),
			companyId: Sanitize.getData('labUser_clientid'),
			masterId: Sanitize.getData('labUser_masterid'),
			loginText: 'LOGIN',
			isRedirect: false,
			showAddModal: false,
			showPrintModal: false,
			inDayView: false,
			currentPage: 1,
			todosPerPage: 10,
			activePage:1,
			dataList: [],
			dataView: [],
			editData: [],
			doctorsList: [],
			printId: '',
			today: '',
		}
		
		this.loadReports = this.loadReports.bind(this);
	}
	
	componentDidMount(){
		
		var today = new Date();
		var date = today.getFullYear()+'-'+('0' + (today.getMonth()+1)).slice(-2)+'-'+('0' + today.getDate()).slice(-2);
		this.setState({today: date});
		
		this.loadDoctorsMasters();
	}

	loadDoctorsMasters(){
		this.setState({showLoader:true});
		var token = Reqst.TimeStamp();
		Reqst.axGet('doctorsmaster/getdoctorslist/'+this.state.masterId+'/'+this.state.companyId+'/'+token).then(res=>{
			if(res['data'].status=='1')
			{
				this.setState({doctorsList: res['data'].data, showLoader:false});	
			}
			else
			{
				this.setState({showLoader:false});	
			}
		});
	}

	loadReports(){
		this.setState({showLoader:true});
		var token = Reqst.TimeStamp();
		var ft = $("#i__fromdate").val();
		var tt = $("#i__todate").val();
		var did = $("#i__doctor").val();
		
		var form = new FormData();
		form.append('masterid', this.state.masterId);
		form.append('companyid', this.state.companyId);
		form.append('fromdate', ft);
		form.append('todate', tt);
		form.append('doctorid', did);
		form.append('patientid', '');
		var data = form;
		
		Reqst.axPost('reportmaster/dailyreport/', data).then(res=>{
			if(res['data'].status=='1')
			{
				if(this.state.inDayView)
				{
					var dayList = Sanitize.groupByMultiple(res['data'].data,function(item){
						return [item.reportdate];
					});
					this.setState({dataView: dayList});
				}
				else{
					this.setState({dataView: res['data'].data});
				}
				this.setState({dataList: res['data'].data, showLoader:false});	
			}
			else
			{
				notify.show(res['data'].msg,'error');
				this.setState({dataList: [], dataView: [],showLoader:false});	
			}
		});
	}
	
	handleViewClick(val,e){
		try{
			if(val=='day')
			{
				var dayList = Sanitize.groupByMultiple(this.state.dataList,function(item){
					return [item.reportdate];
				});
				
				this.setState({dataView: dayList, inDayView: true});
			}
			else if(val=='list')
			{
				this.setState({dataView: this.state.dataList, inDayView: false});
			}
		}
		catch(error){ }
	}

	getTotalFromArray(array){
		try{

			var netAmount = 0;
			var doctorAmount = 0;
			var ownerAmount = 0;

			if(Object.keys(array).length>0)
			{
				array.map((item,i)=>{
					netAmount += parseFloat(item.netamount);
					var dra = Sanitize.calculageAmountFromPercentage(item.netamount,item.doctorpercentage);
					doctorAmount += parseFloat(dra);
					var owa = parseFloat(item.netamount) - parseFloat(dra);
					ownerAmount += parseFloat(owa);
				})

				return {'netamount': netAmount,'docotramount': doctorAmount,'owneramount': ownerAmount};
			}
			return {'netamount':'0','docotramount':'0','owneramount':'0'};
		}
		catch(error){ return {'netamount':'0','docotramount':'0','owneramount':'0'}; }
	}
	
	render(){
		
		
		 let sno = 1;
		 var netAmount = 0;
		 var doctorAmount = 0;
		 var ownerAmount = 0;
		 
		 const exportName = $("#i__fromdate").val()+"_"+$("#i__todate").val();
		 
		return(
			<div>
			
				<Helmet>
					<title>{appName} | Daily Report</title>
				</Helmet>
				
				<PageTitle title="Daily Report" loading={this.state.showLoader} > 
					<div className="text-right">
							{this.state.inDayView ?<button type="button" className="btn btn-primary btn-sm" onClick={this.handleViewClick.bind(this,'list')} >
								<i className="fa fa-list"></i> List view
							</button> :							
							<button type="button" className="btn btn-primary btn-sm" onClick={this.handleViewClick.bind(this,'day')} >
								<i className="fa fa-calendar-o"></i> Day view
							</button>
							}
							&nbsp;
						{Roles.Can('reports','dailyreports','canexport') ?
							<ReactHTMLTableToExcel
								id="test-table-xls-button"
								className="btn btn-warning btn-sm"
								table="tbl"
								filename={exportName}
								sheet="Sheet1"
						buttonText={<span><i className="fa fa-download"></i> Download as XLS</span>} /> : null }
					
							&nbsp;
							<button className="btn btn-primary btn-sm" onClick={this.loadReports} >
								<i className="fa fa-refresh"></i> 
							</button>
					</div>
				</PageTitle>
				
				<Row style={{ marginBottom: 5 }} >
					
					<Col md={3} >
						<Row>
							<Col md={4} style={{ paddingTop: '5px', paddingRight: '0px'}}  >From Date</Col>
							<Col md={8} style={{ paddingLeft: '0px' }}  > 
								<input type="date" className="form-control input-sm" max={this.state.today} defaultValue={this.state.today} id="i__fromdate" /> 
							</Col>
						</Row>
					</Col>
					<Col md={3} >
						<Row>
							<Col md={4} style={{ paddingTop: '5px', paddingRight: '0px'}}  >To Date</Col>
							<Col md={8} style={{ paddingLeft: '0px' }}  > 
								<input type="date" className="form-control input-sm" max={this.state.today} defaultValue={this.state.today} id="i__todate" /> 
							</Col>
						</Row>
					</Col>
					<Col md={5} >
						<Row>
							<Col md={2} style={{ paddingTop: '5px', paddingRight: '0px'}}  >Doctor</Col>
							<Col md={10} style={{ paddingLeft: '0px' }}  > 
								<select name="i__doctor" id="i__doctor" className="form-control input-sm" >
									<option value=""> -All Doctors- </option>
									{this.state.doctorsList.map((item,i)=>{
										return <option value={item.id} > {item.doctorname} </option>;
								 	})
									}
								</select>
							</Col>
						</Row>
					</Col>
					<Col md={1} style={{ padding: '0px' }} >
						<button type="button" onClick={this.loadReports} style={{ right: '-2px' }} className="btn btn-danger btn-sm" >
							<i className="fa fa-search"></i> VIEW
						</button>
					</Col>
				</Row>
				
				<Row>
					
					<Col md={12} >
						<Table bordered hover size="sm" className="list-table" id="tbl" >
							<thead>
							{this.state.inDayView ? 
								<tr>
									<th width="60" >Sl.No</th>
									<th>Report Date</th>
									<th>Amount</th>
									<th>Dr.(Rs)</th>
									<th>Own.(Rs)</th>
								</tr>
							:
								<tr>
									<th width="60" >Sl.No</th>
									<th>Report Id</th>
									<th>Report Date</th>
									<th>Patient Id</th>
									<th>Patient Name</th>
									<th>Age/Sex</th>
									<th>Doctor</th>
									<th>Amount</th>
									<th>Dr.(Rs)</th>
									<th>Own.(Rs)</th>
								</tr>
							}
							</thead>
							<tbody>
							{Object.keys(this.state.dataView).length>0 ?
								this.state.inDayView ? 
									this.state.dataView.map((item,key)=>{
									
										var t = this.getTotalFromArray(item);

										netAmount += parseFloat(t.netamount);
										doctorAmount += parseFloat(t.docotramount);
										ownerAmount += parseFloat(t.owneramount);
										
										return <tr key={key} >
											<td>{sno++}</td>
											<td>{Sanitize.dmy(item[0].reportdate)}</td>
											<td align="right" >{parseFloat(t.netamount).toFixed(2)}</td>
											<td align="right" >{parseFloat(t.docotramount).toFixed(2)}</td>
											<td align="right" >{parseFloat(t.owneramount).toFixed(2)}</td>
										</tr>;
									})
								
								: this.state.dataView.map((item,key)=>{									
									netAmount += parseFloat(item.netamount);
									var dra = Sanitize.calculageAmountFromPercentage(item.netamount,item.doctorpercentage);
									doctorAmount += parseFloat(dra);
									var owa = parseFloat(item.netamount) - parseFloat(dra);
									ownerAmount += parseFloat(owa);
									
									return <tr key={key} >
										<td>{sno++}</td>
										<td>{item.reportno}</td>
										<td>{Sanitize.dmy(item.reportdate)}</td>
										<td>{item.patientcode}</td>
										<td>{item.patientname}</td>
										<td align="" >{item.agesex}</td>
										<td align="" >{item.doctorname}</td>
										<td align="right" >{item.netamount}</td>
										<td align="right" >{parseFloat(dra).toFixed(2)}</td>
										<td align="right" >{parseFloat(owa).toFixed(2)}</td>
									</tr>;
								})
								:
								<tr>
									<td colSpan="10" align="center" >
										<br />
										No data found..!
									</td>
								</tr>
							}
							{Object.keys(this.state.dataView).length>0 ?
								this.state.inDayView ? 
									<tr>
										<td colSpan="2" align="right"> Total :   </td>
										<td align="right" >{parseFloat(netAmount).toFixed(2)}</td>
										<td align="right" >{parseFloat(doctorAmount).toFixed(2)}</td>
										<td align="right" >{parseFloat(ownerAmount).toFixed(2)}</td>
									</tr>
										:
									<tr>
										<td colSpan="7" align="right"> Total :   </td>
										<td align="right" >{parseFloat(netAmount).toFixed(2)}</td>
										<td align="right" >{parseFloat(doctorAmount).toFixed(2)}</td>
										<td align="right" >{parseFloat(ownerAmount).toFixed(2)}</td>
									</tr> : null}
							</tbody>
						</Table>
					</Col>
				</Row>
			
				
				<Modal size="lg" show={this.state.showPrintModal} onHide={()=>{this.setState({showPrintModal:false})}} >
					<Modal.Header closeButton >
						<Modal.Title>Report Print Preview</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<WithoutHeader viewId={this.state.printId} />
					</Modal.Body>
				</Modal>
				
			</div>
		);
	}
	
}
export default DailyReport;
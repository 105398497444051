import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {copyRight} from '../../../utils/Variables';
import * as Reqst from '../../../utils/Reqst';
import Sanitize from '../../../utils/Sanitize';
import {notify} from 'react-notify-toast';
import {Nav,Navbar,NavDropdown,Row,Col,Table,Modal} from 'react-bootstrap';


class Add extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginId: Sanitize.getData('loginUser_id'),
			companyId: Sanitize.getData('labUser_clientid'),
			masterId: Sanitize.getData('labUser_masterid'),
			saveText: 'SAVE PROFILE',
			isRedirect: false,
			showLoader: false,
			showAddModal: false,
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		this.setState({showLoader:true,saveText:'Saving..'});
		var formData = Sanitize.objectifyForm($("#frmAdd").serializeArray());
		Reqst.axPost('doctorsmaster/saveprofile',{token: Reqst.Token(formData)}).then(res=>{
			if(res['data'].status=='1')
			{
				document.getElementById("frmAdd").reset();
				notify.show(res['data'].msg,'success');
				this.setState({showLoader:false,saveText:'SAVE PROFILE'});
				if(this.props.afterFinish) this.props.afterFinish();
			}
			else
			{
				notify.show(res['data'].msg,'error');
				this.setState({showLoader:false,saveText:'SAVE PROFILE'});
			}
		});
	}
	
	render(){
		
		return(
			<div>			
				<form action="" method="post" encType="multipart/form-data" id="frmAdd" onSubmit={this.handleFormSubmit} >
					<input type="hidden" name="i__lid" value={this.state.loginId} />
					<input type="hidden" name="i__company" value={this.state.companyId} />
					<input type="hidden" name="i__master" value={this.state.masterId} />
					<Row>
						<Col md={12} >
							<label>Doctor Name <span className="text-danger">*</span></label>
							<input type="text" name="i__name" id="i__name" className="form-control input-sm" required />
						</Col>
					</Row>
					<Row>
						<Col md={8} >
							<label>Qualification <span className="text-danger"></span></label>
							<input type="text" name="i__qualification" id="i__qualification" className="form-control input-sm" />
						</Col>
						<Col md={4} >
							<label>Commission (%)<span className="text-danger">*</span></label>
							<input type="number" name="i__commission" id="i__commission" className="form-control input-sm" defaultValue="0" required />
						</Col>
					</Row>
					
					<Row>
						<Col md={12} >
							<label>Specialist In <span className="text-danger"></span></label>
							<input type="text" name="i__specialist" id="i__specialist" className="form-control input-sm" />
						</Col>
					</Row>
					<Row>
						<Col md={6} >
							<label>Phone No <span className="text-danger"></span></label>
							<input type="text" name="i__phone" id="i__phone" className="form-control input-sm" />
						</Col>
						<Col md={6} >
							<label>Mobile No <span className="text-danger"></span></label>
							<input type="text" name="i__mobile" id="i__mobile" className="form-control input-sm" />
						</Col>
					</Row>
					
					<Row>
						<Col md={12} >
							<label>Email Id <span className="text-danger"></span></label>
							<input type="text" name="i__email" id="i__email" className="form-control input-sm" />
						</Col>
					</Row>
					<Row>
						<Col md={12} >
							<label>Active Status <span className="text-danger">*</span></label>
							<select name="i__status" id="i__status" className="form-control input-sm" required >
								<option value="1"> Active </option>
								<option value="0"> In Active </option>
							</select>
						</Col>
					</Row>
					<Row>
						<Col md={12} >
							<br />
							<div className="text-right" >
								<button type="submit" className="btn btn-primary btn-sm btn-success_2" disabled={this.state.saveText!='SAVE PROFILE'} >
									<i className="fa fa-check"></i> {this.state.saveText} 
								</button>
							</div>
						</Col>
					</Row>
				</form>
			</div>
		);
	}
	
}
export default Add;
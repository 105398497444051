import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {appName,copyRight} from '../../../utils/Variables';
import * as Reqst from '../../../utils/Reqst';
import Sanitize from '../../../utils/Sanitize';
import {notify} from 'react-notify-toast';
import {Helmet} from "react-helmet";
import {Nav,Navbar,NavDropdown,Row,Col,Table,Modal,DropdownButton, Dropdown} from 'react-bootstrap';
import Toggle from 'react-bootstrap-toggle';
import "react-bootstrap-toggle/dist/bootstrap2-toggle.css";
import Roles from '../../../utils/Roles';
import swal from 'sweetalert';
import Pagination from "react-js-pagination";

import PageTitle from '../../../containers/PageTitle';
import WithoutHeader from '../Print/WihtoutHeader';
import Receipt from '../Print/Receipt';

class List extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginId: Sanitize.getData('loginUser_id'),
			companyId: Sanitize.getData('labUser_clientid'),
			masterId: Sanitize.getData('labUser_masterid'),
			loginText: 'LOGIN',
			isRedirect: false,
			showAddModal: false,
			showPrintModal: false,
			showReceipt: false,
			currentPage: 1,
			todosPerPage: 10,
			activePage:1,
			dataList: [],
			dataView: [],
			editData: [],
			printId: '',
			reportDate: '',
			today: '',
		}
		
		this.handleSearch = this.handleSearch.bind(this);
		this.loadReports = this.loadReports.bind(this);
		this.showPrintView = this.showPrintView.bind(this);
		this.printReceipt = this.printReceipt.bind(this);
	}
	
	componentDidMount(){
		
		var today = new Date();
		var date = today.getFullYear()+'-'+('0' + (today.getMonth()+1)).slice(-2)+'-'+('0' + today.getDate()).slice(-2);
		this.setState({today: date});
		
		this.loadReports();
	}

	showPrintView(pid, rDt,e){
		
		this.setState({printId: pid, reportDate: rDt,showPrintModal:true});
	}
	
	printReceipt(pid, pdt,e){
		
		this.setState({printId: pid,reportDate: pdt, showReceipt:true});
		
	}
	
	loadReports(){
		this.setState({showLoader:true});
		var token = Reqst.TimeStamp();
		var dt = $("#i__rdate").val();
		if(dt=='' || dt==null) dt = '0';
		Reqst.axGet('reportmaster/getreports/'+this.state.masterId+'/'+this.state.companyId+'/'+dt+'/'+token).then(res=>{
			if(res['data'].status=='1')
			{
				this.setState({dataList: res['data'].data, dataView: res['data'].data, showLoader:false});	
			}
			else
			{
				notify.show(res['data'].msg,'error');
				this.setState({dataList: [], dataView: [],showLoader:false});	
			}
		});
	}
	
	handleSearch(e){
		try{
			
			var list = this.state.dataList;
			var v = e.target.value.toUpperCase();
			var l = list.filter(function(obj){
				const gname = obj.patientname.toUpperCase();
				const gmobile = obj.mobile.toUpperCase();
				const gmail = obj.reportno.toUpperCase();
				return ((gname.indexOf(v)>-1) || (gmobile.indexOf(v)>-1) || (gmail.indexOf(v)>-1));
			});
			this.setState({dataView:l});
		}
		catch(error){
			console.log(error);
		}
	}
	
	handleEdit(id,e){
		var list = this.state.dataView;
		var index = list.findIndex(function(obj){ return obj.id==id });
		var l = list[index];
		this.setState({editData: l, showEditModal: true});
	}
	
	removeValue(id,e){
		this.setState({showLoader:true});
		var token = Reqst.TimeStamp();
		Reqst.axGet('reportmaster/removereport/'+this.state.masterId+'/'+this.state.companyId+'/'+id+'/'+token).then(res=>{
			if(res['data'].status=='1')
			{
				var l = this.state.dataList;
				var index = l.findIndex(function(obj){
					return (obj.id==id);
				});
				l.splice(index,1);
				this.setState({dataList:l,dataView:l,  showLoader:false});
				notify.show(res['data'].msg,'success');
			}
			else
			{
				notify.show(res['data'].msg,'error');
				this.setState({showLoader:false});	
			}
		});
	}
	
	handleRemoveClick(id,e){
		
		swal({title:'Confirm',text:'Do you want to Delete?',buttons:['No','Yes']}).then((willYes)=>{
			if(willYes) this.removeValue(id,e);
		});
				
	}
	render(){
		
		
		 const indexOfLastTodo = this.state.currentPage * this.state.todosPerPage;
		 const indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
		 const currentTodos = this.state.dataView.slice(indexOfFirstTodo, indexOfLastTodo);
		 let sno = indexOfFirstTodo + 1;
		 
		return(
			<div>
			
				<Helmet>
					<title>{appName} | Reports List</title>
				</Helmet>
				
				<PageTitle title="Reports List" loading={this.state.showLoader} > 
					<div className="text-right">
						{Roles.Can('reports','reportslist','canadd') ?
							<Link className="btn btn-primary btn-sm" to="/reports/reportsentry" >
								<i className="fa fa-plus"></i>  Add Report
							</Link> : null }
							&nbsp;
							<button className="btn btn-primary btn-sm" onClick={this.loadReports} >
								<i className="fa fa-refresh"></i> 
							</button>
					</div>
				</PageTitle>
				
				<Row style={{ marginBottom: 5 }} >
					<Col md={3} >
						<input type="text" className="form-control input-sm" placeholder="Search by [Name, Mobile, ReportNo]" onChange={this.handleSearch} />
					</Col>
					<Col md={2} >
						<input type="date" className="form-control input-sm" max={this.state.today} id="i__rdate" />
						<button type="button" className="btn-pt-search" onClick={this.loadReports} style={{ right: '-2px' }} >
							<i className="fa fa-search"></i>
						</button>
					</Col>
					<Col md={9} >
						<div className="text-right" >
							
						</div>
					</Col>
				</Row>
				
				<Row>
					
					<Col md={12} >
						<Table striped bordered hover size="sm" className="list-table" >
							<thead>
								<tr>
									<th width="60" >Sl.No</th>
									<th>Report Id</th>
									<th>Report Date</th>
									<th>Patient Name</th>
									<th>Doctor</th>
									<th>Contact</th>
									<th>Amount</th>
									<th>Status</th>
									<th width="120" >Manage</th>
								</tr>
							</thead>
							<tbody>
							{Object.keys(currentTodos).length>0 ?
								currentTodos.map((item,key)=>{
									return <tr key={key} >
										<td>{sno++}</td>
										<td>{item.reportno}</td>
										<td>{Sanitize.dmy(item.reportdate)}</td>
										<td>{item.patientname}</td>
										<td align="" >{item.doctorname}</td>
										<td>
											{item.mobile}<br/><span style={{ color:'#8c8c8c' }} >{item.email}</span>
										</td>
										<td align="right" >Rs. {item.netamount}</td>
										<td align="center" >{Sanitize.getStatus(item.reportstatus)}</td>
										<td align="center" >
										{Roles.Can('reports','reportslist','canedit') ?
											<Link className="btn btn-sm btn-warning btn-lowpadding" to={"/reports/reportedit/"+item.reportid+'/'+item.reportdate} >
												<i className="fa fa-edit"></i>
										</Link> : null }
											&nbsp;
											<button type="button" className="btn btn-primary btn-xs" onClick={this.showPrintView.bind(this,item.reportid, item.reportdate)} >
												<i className="fa fa-eye"></i>
											</button> 
											&nbsp;
											<button type="button" className="btn btn-info btn-xs" onClick={this.printReceipt.bind(this,item.reportid, item.reportdate)} title="Print Receipt" >
												<i className="fa fa-print"></i>
											</button> 
											&nbsp;
										{Roles.Can('reports','reportslist','canedit') ?
											<button className="btn btn-sm btn-danger btn-lowpadding" onClick={this.handleRemoveClick.bind(this,item.reportid)} >
												<i className="fa fa-remove"></i>
										</button> : null }
										</td>
									</tr>;
								})
								:
								<tr>
									<td colSpan="9" align="center" >
										<br />
										No data found..!
									</td>
								</tr>
							}
							</tbody>
						</Table>
					</Col>
				</Row>
				
				<Row>
					<Col md={1} >
						<select className="form-control input-sm" onChange={(e)=>{this.setState({activePage:1,currentPage:Number(1), todosPerPage: e.target.value})}} >
							<option value="10"> 10 </option>
							<option value="15"> 15 </option>
							<option value="20"> 20 </option>
							<option value="50"> 50 </option>
							<option value="100"> 100 </option>
						</select>
					</Col>
					<Col md={10} >
						 <Pagination
						  activePage={this.state.activePage}
						  itemsCountPerPage={this.state.todosPerPage}
						  totalItemsCount={(this.state.dataView).length}
						  pageRangeDisplayed={7}
						  onChange={(pN)=>{this.setState({activePage:pN, currentPage: Number(pN)})}}
						/>
					</Col>
				</Row>
				
				<Modal size="lg" show={this.state.showPrintModal} onHide={()=>{this.setState({showPrintModal:false})}} >
					<Modal.Header closeButton >
						<Modal.Title>Report Print Preview</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<WithoutHeader viewId={this.state.printId} reportDate={this.state.reportDate} />
					</Modal.Body>
				</Modal>
				
				{this.state.showReceipt ?
				<div style={{display:'none'}} >
					<Receipt viewId={this.state.printId} reportDate={this.state.reportDate} autoPrint atClose={()=>{this.setState({printReceipt:false,printId:''})}} />
				</div> : null}
				
			
			</div>
		);
	}
	
}
export default List;
import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {appName,copyRight,apiUrl} from '../../../utils/Variables';
import * as Reqst from '../../../utils/Reqst';
import Sanitize from '../../../utils/Sanitize';
import {notify} from 'react-notify-toast';
import {Helmet} from "react-helmet";
import {Nav,Navbar,NavDropdown,Row,Col,Table,Modal,DropdownButton, Dropdown,Card,Tabs,Tab} from 'react-bootstrap';
import Toggle from 'react-bootstrap-toggle';
import "react-bootstrap-toggle/dist/bootstrap2-toggle.css";
import Roles from '../../../utils/Roles';
import swal from 'sweetalert';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Pagination from "react-js-pagination";
import ReactFileReader from 'react-file-reader';

import PageTitle from '../../../containers/PageTitle';
import LOGO from '../../../assets/img/lablogo.png';

class LabSettings extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginId: Sanitize.getData('loginUser_id'),
			companyId: Sanitize.getData('labUser_clientid'),
			masterId: Sanitize.getData('labUser_masterid'),
			saveText: 'Update Settings',
			isRedirect: false,
			showAddModal: false,
			showEditModal: false,
			currentPage: 1,
			todosPerPage: 10,
			activePage:1,
			logoPath: '',
			newLogo: '',
			dataList: [],
			dataView: [],
			editData: [],
		}
		
		this.handleUpdate = this.handleUpdate.bind(this);
		this.handleLogoChange = this.handleLogoChange.bind(this);
	}
	
	componentDidMount(){
		
		this.loadSettings();

	}
	
	loadSettings(){
		this.setState({showLoader:true});
		var token = Reqst.TimeStamp();
		Reqst.axGet('settings/get/'+this.state.masterId+'/'+this.state.companyId+'/'+token).then(res=>{
			if(res['data'].status=='1')
			{
				this.setState({logoPath: apiUrl+'public/uploads/'+res['data'].data.logo, dataList: res['data'].data, showLoader:false});	
			}
			else
			{
				notify.show(res['data'].msg,'error');
				this.setState({showLoader:false});	
			}
		});
	}
	
	getValue(field){
		try{
			
			var d = this.state.dataList;
			return d[field];
		}
		catch(error){
			return '';
		}
	}
	
	setValue(field,e){
		try{
			
			var v = e.target.value;
			var d = this.state.dataList;
			d[field] = v;
			this.setState({dataList: d});
		}
		catch(error){
			
		}
	}
	
	handleUpdate(e){
		try{
			
			e.preventDefault();
			
			this.setState({showLoader:true, saveText:' Updating..'});
			Reqst.axPost('settings/update/',$("#frmSettings").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					notify.show(res['data'].msg,'success');
					this.setState({showLoader:false,saveText: 'Update Settings'});
				}
				else
				{
					notify.show(res['data'].msg,'error');
					this.setState({showLoader:false,saveText: 'Update Settings'});
				}
			});
		}
		catch(error){ }
	}

	handleLogoChange(files){
		try{
			this.setState({logoPath: files.base64, newLogo: files.base64});
		}
		catch(error){
			
		}
	}
	
	
	render(){
		
		
		const cmpLogo = this.state.logoPath!=null && this.state.logoPath!='' && this.state.logoPath.length>5 ? this.state.logoPath : LOGO;
		 
		return(
			<div>
			
				<Helmet>
					<title>{appName} | Settings</title>
				</Helmet>
				
				<PageTitle title="Settings" loading={this.state.showLoader} > 
					<div className="text-right">
					
					</div>
				</PageTitle>
				
				
				
				<Row>
					
					<Col md={{ span: 8,offset: 2 }} >
					
						<Card>
							<Card.Header>Settings</Card.Header>
							<Card.Body>
								<form action="" method="post" encType="multipart/form-data" id="frmSettings" onSubmit={this.handleUpdate} >
									
										<input type="hidden" name="i__lid" value={this.state.loginId} />
										<input type="hidden" name="i__company" value={this.state.companyId} />
										<input type="hidden" name="i__master" value={this.state.masterId} />
										<input type="hidden" name="i__logo" value={this.state.newLogo} />
								<Row>
									<Col md={9} >
									
									<Row style={{ marginBottom: '15px' }} >
										<Col md={4} >
											<label>Company Name</label>
										</Col>
										<Col md={8} >
											<input type="text" className="form-control input-sm" name="i__name" id="i__name" required value={this.getValue('name')} onChange={this.setValue.bind(this,'name')} />
										</Col>
									</Row>
									
									<Row style={{ marginBottom: '15px' }} >
										<Col md={4} >
											<label>Company Place</label>
										</Col>
										<Col md={8} >
											<input type="text" className="form-control input-sm" name="i__place" id="i__place" required value={this.getValue('place')} onChange={this.setValue.bind(this,'place')} />
										</Col>
									</Row>
									
									<Row style={{ marginBottom: '15px' }} >
										<Col md={4} >
											<label>Company Address</label>
										</Col>
										<Col md={8} >
											<textarea className="form-control input-sm" name="i__address" id="i__address" required rows="4" value={this.getValue('address')} onChange={this.setValue.bind(this,'address')} ></textarea>
										</Col>
									</Row>
								</Col>
								<Col md={3} >
									<label>Logo</label>
									<ReactFileReader handleFiles={this.handleLogoChange} fileTypes={['.png','.jpg','.jpeg']} base64={true} >
									  <img src={cmpLogo} style={{width: '100%', border: '1px solid grey', cursor: 'pointer' }}  />
									</ReactFileReader>
									
									<div className="text-center" >
										<br />
										Click over the image to upload new one.!
									</div>
							
								</Col>
							</Row>
									
									<Row style={{ marginBottom: '15px' }} >
										<Col md={3} >
											<label>Name of the Country</label>
										</Col>
										<Col md={9} >
											<input type="text" className="form-control input-sm" name="i__country" id="i__country" value={this.getValue('country')} onChange={this.setValue.bind(this,'country')} />
										</Col>
									</Row>
									
									<Row style={{ marginBottom: '15px' }} >
										<Col md={3} >
											<label>Name of the State</label>
										</Col>
										<Col md={9} >
											<input type="text" className="form-control input-sm" name="i__state" id="i__state" value={this.getValue('state')} onChange={this.setValue.bind(this,'state')} />
										</Col>
									</Row>
									
									<Row style={{ marginBottom: '15px' }} >
										<Col md={3} >
											<label>Pincode</label>
										</Col>
										<Col md={9} >
											<input type="number" className="form-control input-sm" name="i__pincode" id="i__pincode" value={this.getValue('pincode')} onChange={this.setValue.bind(this,'pincode')} />
										</Col>
									</Row>
									
									<Row style={{ marginBottom: '15px' }} >
										<Col md={3} >
											<label>Mobile Number</label>
										</Col>
										<Col md={9} >
											<input type="text" className="form-control input-sm" name="i__mobile" id="i__mobile" value={this.getValue('mobile')} onChange={this.setValue.bind(this,'mobile')}  />
										</Col>
									</Row>
									
									<Row style={{ marginBottom: '15px' }} >
										<Col md={3} >
											<label>Email Id</label>
										</Col>
										<Col md={9} >
											<input type="text" className="form-control input-sm" name="i__email" id="i__email" value={this.getValue('email')} onChange={this.setValue.bind(this,'email')} />
										</Col>
									</Row>
									
									<Row>
										<Col md={12} >
											<hr />
											<div className="text-right" >
												<button type="submit" className="btn btn-success btn-md" >
													{this.state.saveText}
												</button>
											</div>
										</Col>
									</Row>
									
								</form>
							</Card.Body>
						</Card>
						
					</Col>
				</Row>
				
				
				
				
			</div>
		);
	}
	
}
export default LabSettings;
import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {copyRight} from '../../../utils/Variables';
import * as Reqst from '../../../utils/Reqst';
import Sanitize from '../../../utils/Sanitize';
import {notify} from 'react-notify-toast';
import {Nav,Navbar,NavDropdown,Row,Col,Table,Modal} from 'react-bootstrap';

class Edit extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginId: Sanitize.getData('loginUser_id'),
			companyId: Sanitize.getData('labUser_clientid'),
			masterId: Sanitize.getData('labUser_masterid'),
			saveText: 'UPDATE PROFILE',
			isRedirect: false,
			showLoader: false,
			editData: this.props.editData,
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		this.setState({showLoader:true,saveText:'Updating..'});
		var formData = Sanitize.objectifyForm($("#frmAdd").serializeArray());
		Reqst.axPost('employeemaster/updateprofile',{token: Reqst.Token(formData)}).then(res=>{
			if(res['data'].status=='1')
			{
				notify.show(res['data'].msg,'success');
				this.setState({showLoader:false,saveText:'UPDATE PROFILE'});
				if(this.props.afterFinish) this.props.afterFinish();
			}
			else
			{
				notify.show(res['data'].msg,'error');
				this.setState({showLoader:false,saveText:'UPDATE PROFILE'});
			}
		});
	}
	
	getData(field){
		var l = this.state.editData;
		return l[field];
	}
	
	setData(field,e){
		var l = this.state.editData;
		l[field] = e.target.value;
		this.setState({editData: l });
	}
	
	render(){
		
		return(
			<div>			
				<form action="" method="post" encType="multipart/form-data" id="frmAdd" onSubmit={this.handleFormSubmit} >
					<input type="hidden" name="i__id" value={this.getData('id')} />
					<input type="hidden" name="i__lid" value={this.state.loginId} />
					<input type="hidden" name="i__company" value={this.state.companyId} />
					<input type="hidden" name="i__master" value={this.state.masterId} />
				
					<Row>
						<Col md={3} >
							<label>Employee Code<span className="text-danger">*</span></label>
							<input type="text" name="i__code" id="i__code" className="form-control input-sm" required value={this.getData('employeecode')} onChange={this.setData.bind(this,'employeecode')} />
						</Col>
						<Col md={9} >
							<label>Employee Name <span className="text-danger">*</span></label>
							<input type="text" name="i__name" id="i__name" className="form-control input-sm" required value={this.getData('employeename')} onChange={this.setData.bind(this,'employeename')} />
						</Col>
					</Row>
					<Row>
						<Col md={4} >
							<label>Joining Date <span className="text-danger"></span></label>
							<input type="date" name="i__joining" id="i__joining" className="form-control input-sm" value={this.getData('joiningdate')} onChange={this.setData.bind(this,'joiningdate')} />
						</Col>
						<Col md={4} >
							<label>Education <span className="text-danger"></span></label>
							<input type="text" name="i__education" id="i__education" className="form-control input-sm" value={this.getData('employeeducation')} onChange={this.setData.bind(this,'employeeducation')} />
						</Col>
						<Col md={4} >
							<label>Basic Pay<span className="text-danger">*</span></label>
							<input type="number" name="i__pay" id="i__pay" className="form-control input-sm" defaultValue="0" value={this.getData('basicpay')} onChange={this.setData.bind(this,'basicpay')} />
						</Col>
					</Row>
					
					<Row>
						<Col md={12} >
							<label>Address <span className="text-danger"></span></label>
							<input type="text" name="i__address" id="i__address" className="form-control input-sm" value={this.getData('address')} onChange={this.setData.bind(this,'address')} />
						</Col>
					</Row>
				
					<Row>
						<Col md={6} >
							<label>Phone No <span className="text-danger"></span></label>
							<input type="text" name="i__phone" id="i__phone" className="form-control input-sm" value={this.getData('phone')} onChange={this.setData.bind(this,'phone')} />
						</Col>
						<Col md={6} >
							<label>Mobile No <span className="text-danger"></span></label>
							<input type="text" name="i__mobile" id="i__mobile" className="form-control input-sm" value={this.getData('mobile')} onChange={this.setData.bind(this,'mobile')} />
						</Col>
					</Row>
					
					<Row>
						<Col md={12} >
							<label>Email Id <span className="text-danger"></span></label>
							<input type="text" name="i__email" id="i__email" className="form-control input-sm" value={this.getData('email')} onChange={this.setData.bind(this,'email')} />
						</Col>
					</Row>
					<Row>
						<Col md={12} >
							<label>Active Status <span className="text-danger">*</span></label>
							<select name="i__status" id="i__status" className="form-control input-sm" required >
								<option value="1" selected={this.getData('employeestatus')=='1' ? 'selected' : ''} > Active </option>
								<option value="0" selected={this.getData('employeestatus')=='0' ? 'selected' : ''}  > In Active </option>
							</select>
						</Col>
					</Row>
					<Row>
						<Col md={12} >
							<br />
							<div className="text-right" >
								<button type="submit" className="btn btn-primary btn-sm btn-success_2" disabled={this.state.saveText!='UPDATE PROFILE'} >
									<i className="fa fa-check"></i> {this.state.saveText} 
								</button>
							</div>
						</Col>
					</Row>
				</form>
			</div>
		);
	}
	
}
export default Edit;
import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {appName,copyRight} from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import {notify} from 'react-notify-toast';
import {Helmet} from "react-helmet";
import {Nav,Navbar,NavDropdown,Row,Col,Table,Modal,DropdownButton, Dropdown,Card,Tabs,Tab} from 'react-bootstrap';
import Toggle from 'react-bootstrap-toggle';
import "react-bootstrap-toggle/dist/bootstrap2-toggle.css";
import Roles from '../../utils/Roles';
import swal from 'sweetalert';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Pagination from "react-js-pagination";

import PageTitle from '../../containers/PageTitle';

class Dashboard extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginId: Sanitize.getData('loginUser_id'),
			companyId: Sanitize.getData('labUser_clientid'),
			masterId: Sanitize.getData('labUser_masterid'),
			saveText: 'Update Settings',
			isRedirect: false,
			amount: '0.00',
			count: '0',
			reports: [],
		}
		
	}
	
	componentDidMount(){
		
		this.loadData();
	}
	
	loadData()
	{
		try{
			this.setState({showLoader:true});
			Reqst.axGet('home/get/'+this.state.masterId+'/'+this.state.companyId).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({
						amount: res['data'].amount,
						count: res['data'].count,
						reports: res['data'].record
					});
				}
				this.setState({showLoader:false});
			});
		}
		catch(error){
			this.setState({showLoader:false});
		}
	}
	
	
	
	render(){
		
		
		 
		return(
			<div>
			
				<Helmet>
					<title>{appName} | Dashboard</title>
				</Helmet>
				
				<PageTitle title="Dashboard" loading={this.state.showLoader} > 
					<div className="text-right">
					
					</div>
				</PageTitle>
				
				
				
				<Row>
					<Col md={9} >
						<Row>
							
							<Col md={4} >
								<Card>
									<Card.Body className="bg-success" >
										<h4 className="dash-header" >Total Collection</h4>
										<h3 className="dash-value">Rs. {parseFloat(this.state.amount).toFixed(2)}</h3>
									</Card.Body>
								</Card>
							</Col>
							
							<Col md={4} >
								<Card>
									<Card.Body className="bg-info" >
										<h4 className="dash-header" >Today Reports</h4>
										<h3 className="dash-value" style={{ textAlign:'center'}} >{this.state.count}</h3>
									</Card.Body>
								</Card>
							</Col>
							
						
							
						</Row>
					</Col>
					<Col md={3} >
						<Card>
							<Card.Body style={{padding:'10px 0px'}} >
								<h5 className="quick-link-title" >&emsp;Quick Links</h5>
								
							</Card.Body>
						</Card>
					</Col>
				</Row>
				
				<Row style= {{ marginTop: '15px' }} >
					<Col md={12} >
						<h5>Recent Reports</h5>
						<hr />
					</Col>
					<Col md={12} >
						<div className="table-responsive" >
							<Table>
								<thead>
									<tr>
										<th>Report Id</th>
										<th>Report Date</th>
										<th>Patient Name</th>
										<th>Doctor</th>
										<th>Contact</th>
										<th>Amount</th>
									</tr>
								</thead>
								<tbody>
									{Object.keys(this.state.reports).length>0 ?
										this.state.reports.map((item,key)=>{
											return <tr key={key} >
												<td>{item.reportno}</td>
												<td>{Sanitize.dmy(item.reportdate)}</td>
												<td>{item.patientname}</td>
												<td align="" >{item.doctorname}</td>
												<td>
													{item.mobile}<br/><span style={{ color:'#8c8c8c' }} >{item.email}</span>
												</td>
												<td align="right" >Rs. {item.netamount}</td>
												
											</tr>;
										})
										:
										<tr>
											<td colSpan="9" align="center" >
												<br />
												No data found..!
											</td>
										</tr>
								}
								</tbody>
							</Table>
						</div>
					</Col>
				</Row>
				
				
				
				
			</div>
		);
	}
	
}
export default Dashboard;
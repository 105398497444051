import React, { Component } from 'react';

import Routes from '../routes';


export default () =>(

	<Routes />

);

import React, { Component, Suspense, lazy  } from 'react';
import { BrowserRouter, Route, Link, HashRouter, IndexRoute } from 'react-router-dom';

import Theme from '../layouts/Theme';
import DriveTheme from '../layouts/DriveTheme';
import Login from '../views/Login';
import Dashboard from '../views/Dashboard';
import GroupMaster from '../views/Manage/GroupMaster';
import TestMaster from '../views/Manage/TestMaster';
import DoctorsMaster from '../views/Manage/DoctorsMaster';
import EmployeeMaster from '../views/Manage/EmployeeMaster';
import ProfileMaster from '../views/Manage/ProfileMaster';
import PatientMaster from '../views/Manage/PatientMaster';
import LabSettings from '../views/Settings/LabSettings';
import MyProfile from '../views/Settings/MyProfile';
import ReportEntry from '../views/Reports/Entry';
import ReportEdit from '../views/Reports/Edit';
import ReportList from '../views/Reports/List';
import DailyReport from '../views/Reports/DailyReport';
import Receipt from '../views/Reports/Print/Receipt';

import ManageRoles from '../views/ManageRoles';
import UsersMgt from '../views/UsersMgt';
import AddUser from '../views/UsersMgt/AddUser';
import EditUser from '../views/UsersMgt/EditUser';

import NotFound from '../views/NotFound';
import Download from '../views/Download';

import Roles from '../utils/Roles';


import Test from '../views/Dashboard/Test';

import TestProfile from '../views/Test/Profile';
import ProfileArticles from '../views/Test/ProfileArticles';
import SingleArticle from '../views/Test/SingleArticle';

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route {...rest} render={props => (
    <Layout>
      <Component {...props} />
    </Layout>
  )} />
)

export default () => (
	
<HashRouter>
	<div>
	
		<Suspense fallback={<div>Loading...</div>}>
		 
		{/*<Route path="/" exact component={Test} />*/}
		<Route path="/" exact component={Login} />
		
		<Route path="/test" exact component={Test} />
		
		{/*<Route path="/:username" exact component={TestProfile} />
		
		<Route path="/:username/articles" exact component={ProfileArticles} />
		<Route path="/:username/articles/:articlename" exact component={SingleArticle} />*/}
		
		<AppRoute path="/dashboard" exact layout={Theme} component={Dashboard}  />
		
		<AppRoute path="/manage/groupmaster" exact layout={Theme} 
					component={Roles.Can('manage','groupmaster','canuse') ? GroupMaster : NotFound}  />
		<AppRoute path="/manage/testmaster" exact layout={Theme} 
					component={Roles.Can('manage','testmaster','canuse') ? TestMaster : NotFound}  />
		<AppRoute path="/manage/doctorsmaster" exact layout={Theme} 
					component={Roles.Can('manage','doctorsmaster','canuse') ? DoctorsMaster : NotFound}  />
		
		<AppRoute path="/manage/employeemaster" exact layout={Theme} 
					component={Roles.Can('manage','employeemaster','canuse') ? EmployeeMaster : NotFound}  />
					
		<AppRoute path="/manage/profilemaster" exact layout={Theme} 
					component={Roles.Can('manage','profilemaster','canuse') ? ProfileMaster : NotFound}  />
					
		<AppRoute path="/patients" exact layout={Theme} 
					component={Roles.Can('patients','patients','canuse') ? PatientMaster : NotFound}  />

	<AppRoute path="/manage/labsettings" exact layout={Theme} 
					component={Roles.Can('manage','labsettings','canuse') ? LabSettings : NotFound}  />
					
	<AppRoute path="/reports/reportsentry" exact layout={Theme} 
					component={Roles.Can('reports','reportsentry','canadd') ? ReportEntry : NotFound}  />
					
	<AppRoute path="/reports/reportslist" exact layout={Theme} 
					component={Roles.Can('reports','reportsentry','canuse') ? ReportList : NotFound}  />
					
	<AppRoute path="/reports/reportedit/:id/:rdt" exact layout={Theme} 
					component={Roles.Can('reports','reportsentry','canedit') ? ReportEdit : NotFound}  />
					
	<AppRoute path="/reports/dailyreport" exact layout={Theme} 
					component={Roles.Can('reports','dailyreports','canuse') ? DailyReport : NotFound}  />
					
	<AppRoute path="/manage/manageusers" exact layout={Theme} 
					component={Roles.Can('manage','manageusers','canuse') ? UsersMgt : NotFound}  />
	<AppRoute path="/manage/manageusers/add" exact layout={Theme} 
					component={Roles.Can('manage','manageusers','canadd') ? AddUser : NotFound}  />
	<AppRoute path="/manage/manageusers/edit/:id/:vl" exact layout={Theme} 
					component={Roles.Can('manage','manageusers','canedit') ? EditUser : NotFound}  />
		
		<AppRoute path="/myprofile" exact layout={Theme} component={MyProfile}  />
		
		<AppRoute path="/receipt" exact layout={Theme} component={Receipt}  />
		
		<AppRoute path="/manageroles" exact layout={Theme} component={ManageRoles}  />
		
		<Route exact path="/download" component={Download} />
		
	</Suspense>
	
	</div>
</HashRouter>

);
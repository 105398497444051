import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import Sanitize from '../../utils/Sanitize';
import Notifications, {notify} from 'react-notify-toast';
import {Row, Col, Nav, Navbar, NavDropdown,Form, FormControl, Button } from 'react-bootstrap';


import Header from './header';
import Footer from './footer';

class Theme extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
		
	}
	
	componentDidMount(){
		
	}


	render(){
	
		
		return(
			<div style={{ overflowX: 'hidden' }} className="theme-orange" >
				
				<Notifications options={{zIndex: 9999 }} />
				
				<Header />
					
					<div id="container">
						
						
						<div id="content">
							<div id="main-content">
								<div className="container">
								
									{this.props.children}
								
								</div>
					
							</div>
						</div>
						
					
					</div>
				
				
				<Footer />
				
			</div>
		);
	}
	
}
export default Theme;


import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {appName,copyRight} from '../../../utils/Variables';
import * as Reqst from '../../../utils/Reqst';
import Sanitize from '../../../utils/Sanitize';
import {notify} from 'react-notify-toast';
import {Helmet} from "react-helmet";
import {Nav,Navbar,NavDropdown,Row,Col,Table,Modal,DropdownButton, Dropdown} from 'react-bootstrap';
import Toggle from 'react-bootstrap-toggle';
import "react-bootstrap-toggle/dist/bootstrap2-toggle.css";
import Roles from '../../../utils/Roles';
import swal from 'sweetalert';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Pagination from "react-js-pagination";

import PageTitle from '../../../containers/PageTitle';
import Add from './Add';
import Edit from './Edit';

class GroupMaster extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginId: Sanitize.getData('loginUser_id'),
			companyId: Sanitize.getData('labUser_clientid'),
			masterId: Sanitize.getData('labUser_masterid'),
			loginText: 'LOGIN',
			isRedirect: false,
			showAddModal: false,
			showEditModal: false,
			currentPage: 1,
			todosPerPage: 10,
			activePage:1,
			dataList: [],
			dataView: [],
			editData: [],
		}
		
		this.handleSearch = this.handleSearch.bind(this);
		this.loadGroupMasters = this.loadGroupMasters.bind(this);
	}
	
	componentDidMount(){
		
		this.loadGroupMasters();
	}
	
	loadGroupMasters(){
		this.setState({showLoader:true});
		var token = Reqst.TimeStamp();
		Reqst.axGet('groupmaster/getgrouplist/'+this.state.masterId+'/'+this.state.companyId+'/'+token).then(res=>{
			if(res['data'].status=='1')
			{
				this.setState({dataList: res['data'].data, dataView: res['data'].data, showLoader:false});	
			}
			else
			{
				notify.show(res['data'].msg,'error');
				this.setState({showLoader:false});	
			}
		});
	}
	
	handleSearch(e){
		try{
			
			var list = this.state.dataList;
			var v = e.target.value.toUpperCase();
			var l = list.filter(function(obj){
				const gname = obj.groupname.toUpperCase();
				const gcode = obj.groupcode.toUpperCase();
				return ((gname.indexOf(v)>-1) || (gcode.indexOf(v)>-1));
			});
			this.setState({dataView:l});
		}
		catch(error){
			console.log(error);
		}
	}
	
	handleEdit(id,e){
		var list = this.state.dataView;
		var index = list.findIndex(function(obj){ return obj.id==id });
		var l = list[index];
		this.setState({editData: l, showEditModal: true});
	}
	
	removeValue(id,e){
		this.setState({showLoader:true});
		var token = Reqst.TimeStamp();
		Reqst.axGet('groupmaster/removegroup/'+this.state.masterId+'/'+this.state.companyId+'/'+id+'/'+token).then(res=>{
			if(res['data'].status=='1')
			{
				var l = this.state.dataList;
				var index = l.findIndex(function(obj){
					return (obj.id==id);
				});
				l.splice(index,1);
				this.setState({dataList:l,dataView:l,  showLoader:false});
				notify.show(res['data'].msg,'success');
			}
			else
			{
				notify.show(res['data'].msg,'error');
				this.setState({showLoader:false});	
			}
		});
	}
	
	handleRemoveClick(id,e){
		
		swal({title:'Confirm',text:'Do you want to Delete?',buttons:['No','Yes']}).then((willYes)=>{
			if(willYes) this.removeValue(id,e);
		});
				
	}
	
	render(){
		
		const indexOfLastTodo = this.state.currentPage * this.state.todosPerPage;
		 const indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
		 const currentTodos = this.state.dataView.slice(indexOfFirstTodo, indexOfLastTodo);
		 let sno = indexOfFirstTodo + 1;
		 
		return(
			<div>
			
				<Helmet>
					<title>{appName} | Group Master</title>
				</Helmet>
				
				<PageTitle title="Group Master" loading={this.state.showLoader} > 
					<div className="text-right">
					{/*<button className="btn btn-primary btn-sm btn-primary_2" onClick={()=>{this.setState({showAddModal:true})}} ><i className="fa fa-plus"></i> 
						Add Group
					</button>*/}
					{Roles.Can('manage','groupmaster','canadd') ?
							<button className="btn btn-primary btn-sm" onClick={()=>{this.setState({showAddModal:true})}} >
								<i className="fa fa-plus"></i>  Add Group
						</button> : null }
						
						&nbsp;
						<button className="btn btn-primary btn-sm" onClick={this.loadGroupMasters} >
							<i className="fa fa-refresh"></i> 
						</button>
						&nbsp;
						{Roles.Can('manage','groupmaster','canexport') ?
						  <ReactHTMLTableToExcel
							id="table-xls"
							className="btn btn-primary btn-sm"
							table="table-to-xls"
							filename="TestGroups"
							sheet="Groups"
						buttonText={<span><i className="fa fa-file-excel-o"></i> Download as XLS</span>} /> : null }
					</div>
				</PageTitle>
				
				<Row style={{ marginBottom: 5 }} >
					<Col md={3} >
						<input type="text" className="form-control input-sm" placeholder="Search by [Code, Name]" onChange={this.handleSearch} />
					</Col>
					<Col md={9} >
						<div className="text-right" >
							
							
						</div>
					</Col>
				</Row>
				
				<Row>
					
					<Col md={12} >
						<Table hover responsive className="list-table" id="table-xls" >
							<thead>
								<tr>
									<th width="60" >Sl.No</th>
									<th>Group Code</th>
									<th>Group Name</th>
									<th>Status</th>
									<th width="80" >Manage</th>
								</tr>
							</thead>
							<tbody>
							{
								currentTodos.map((item,key)=>{
									return <tr key={key} >
										<td>{sno++}</td>
										<td>{item.groupcode}</td>
										<td>{item.groupname}</td>
										<td align="center" >{Sanitize.getStatus(item.groupstatus)}</td>
										<td align="center" >
										{Roles.Can('manage','groupmaster','canedit') ?
											<button className="btn btn-sm btn-warning btn-lowpadding" onClick={this.handleEdit.bind(this,item.id)} >
												<i className="fa fa-edit"></i>
											</button> : null }
											&emsp;
										{Roles.Can('manage','groupmaster','candelete') ?
											<button className="btn btn-sm btn-danger btn-lowpadding" onClick={this.handleRemoveClick.bind(this,item.id)} >
												<i className="fa fa-remove"></i>
											</button> : null}
										</td>
									</tr>;
								})
							}
							</tbody>
						</Table>
					</Col>
				</Row>
				
				<Row>
					<Col md={1} >
						<select className="form-control input-sm" onChange={(e)=>{this.setState({activePage:1,currentPage:Number(1), todosPerPage: e.target.value})}} >
							<option value="10"> 10 </option>
							<option value="15"> 15 </option>
							<option value="20"> 20 </option>
							<option value="50"> 50 </option>
							<option value="100"> 100 </option>
						</select>
					</Col>
					<Col md={10} >
						 <Pagination
						  activePage={this.state.activePage}
						  itemsCountPerPage={this.state.todosPerPage}
						  totalItemsCount={(this.state.dataView).length}
						  pageRangeDisplayed={7}
						  onChange={(pN)=>{this.setState({activePage:pN, currentPage: Number(pN)})}}
						/>
					</Col>
				</Row>
				
				<Modal size="md" show={this.state.showAddModal} onHide={()=>{this.setState({showAddModal:false})}} className="right" >
					<Modal.Header closeButton >
						<Modal.Title>Add Group</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Add afterFinish={this.loadGroupMasters} />
					</Modal.Body>
				</Modal>
				
				<Modal size="md" show={this.state.showEditModal} onHide={()=>{this.setState({showEditModal:false})}} className="right" >
					<Modal.Header closeButton >
						<Modal.Title>Edit Group</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Edit editData={this.state.editData} afterFinish={this.loadGroupMasters} />
					</Modal.Body>
				</Modal>
				
			</div>
		);
	}
	
}
export default GroupMaster;
import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {appName,copyRight} from '../../../utils/Variables';
import * as Reqst from '../../../utils/Reqst';
import Sanitize from '../../../utils/Sanitize';
import {notify} from 'react-notify-toast';
import {Nav,Navbar,NavDropdown,Row,Col,Table,Modal,DropdownButton, Dropdown} from 'react-bootstrap';

const styles = {
	groupStyle: {
		fontWeight: '600',
	},
	resultBold: {
		fontWeight: '500',
	},
}

class WithoutHeader extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginId: Sanitize.getData('loginUser_id'),
			companyId: Sanitize.getData('labUser_clientid'),
			masterId: Sanitize.getData('labUser_masterid'),
			withHeader: Sanitize.getData('labUser_reportwithheader'),
			dataList: [],
			dataView: [],
			reportResult: [],
		}
		
	}
	
	componentDidMount(){
        
		if(this.props.resultData)
		{
			var data = this.props.resultData;
			var d = Sanitize.groupByMultiple(data, function(item){
					return [item.groupid, item.profileid];
			});
				
			this.setState({dataList: data, dataView: data, reportResult: d, showLoader:false});
		}
		else
		{
			this.loadReport();			
		}
    }

	loadReport(){
		this.setState({showLoader:true});
		var token = Reqst.TimeStamp();
		var rDt = this.props.reportDate;
		Reqst.axGet('reportmaster/getreport/'+this.state.masterId+'/'+this.state.companyId+'/'+this.props.viewId+'/1/'+rDt+'/'+token).then(res=>{
			if(res['data'].status=='1')
			{
				var d = Sanitize.groupByMultiple(res['data'].data, function(item){
					return [item.groupid, item.profileid];
				});
				
				this.setState({dataList: res['data'].data, dataView: res['data'].data, reportResult: d, showLoader:false});	
			}
			else
			{
				notify.show(res['data'].msg,'error');
				this.setState({showLoader:false});	
			}
		});
	}
	
	getResultWithBold(result){
		try{
			var tr = [];
			result.map((test,k)=>{
				var range = (test.gender=='Female' && test.femalerange!='') ? test.femalerange : test.referencerange;
				var bold = Sanitize.isBold(test);
				
				 tr.push(<tr> 
					<td> &nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp; {test.testname} </td>	
						{bold ? <td style={{ fontWeight: '700' }}  >{test.result} &nbsp; {test.testunit} </td>
						: <td  >{test.result} &nbsp; {test.testunit} </td> }
					<td>{range} &nbsp; {test.testunit} </td>
				</tr>);
			});
			
			return tr;
		}
		catch(error){
			console.log(error);
		}
	}
	
	printDocument(printId){
		if(this.state.withHeader=='0') 
			document.getElementById("print_margin_top").style.height = "150px";
		
		 var content = document.getElementById(printId);
		 var pri = document.getElementById("print_frame").contentWindow;
		 pri.document.open();
		 pri.document.write(content.innerHTML);
		 pri.document.close();
		 pri.focus();
		 pri.print();
		 
		if(this.state.withHeader=='0') 
			document.getElementById("print_margin_top").style.height = "0px";
	}
	
	render(){
		
		 
		return(
			<div>
				{Object.keys(this.state.dataView).length>0 ?
				<React.Fragment>
				
				<div id="print_div" >
					<div id="print_margin_top"></div>
					{this.state.withHeader=='1' ? <div>
						<img src={Sanitize.getData('labUser_reportheader')} style={{width:'100%'}} />
					</div> : null}
                <table width={this.state.withHeader=='1' ? '90%' : '100%'} align="center" style={{ fontSize: '14px' }} >
                    <tr>
                        <td width="70" >Name</td><td width="20" > : </td>
                        <td> {this.state.dataView[0]['patientname']} </td>
                        <td width="100" >Report Id</td><td width="20" > : </td>
                        <td> {this.state.dataView[0]['reportno']} </td>
                    </tr>
                    <tr>
                        <td>Patient Id</td><td> : </td>
                        <td> {this.state.dataView[0]['patientcode']} </td>
                        <td>Sample Date</td><td> : </td>
                        <td> {Sanitize.dmy(this.state.dataView[0]['sampledate'])}  {Sanitize.formatAMPM(this.state.dataView[0]['sampletime'])} </td>
                    </tr>
                    <tr>
                        <td>Age/Sex</td><td> : </td>
                        <td> {this.state.dataView[0]['agesex']} </td>
                        <td>Report Date</td><td> : </td>
                        <td> {Sanitize.dmy(this.state.dataView[0]['reportdate'])}  {Sanitize.formatAMPM(this.state.dataView[0]['reporttime'])} </td>
                    </tr>
                    <tr>
                        <td>Ref.By</td><td> : </td>
                        <td> {this.state.dataView[0]['doctorname']} </td>
                        <td colSpan="3">
                        </td>
                    </tr>
					
                    <tr>
                        <td colSpan="6">
                            <table width="100%" align="center" styl={{ borderCollapse:'collapse'}}  >
                                <thead styl={{ borderCollapse:'collapse'}}  >
                                    <tr style={{ borderTop: '1px solid dimgrey', borderCollapse: 'collapse',borderBottom: '1px solid dimgrey', textAlign:'center' }} >
                                        <th style={{ borderTop: '1px solid dimgrey', borderCollapse: 'collapse',borderBottom: '1px solid dimgrey', textAlign:'center' }} >TEST NAME</th>
                                        <th style={{ borderTop: '1px solid dimgrey', borderCollapse: 'collapse',borderBottom: '1px solid dimgrey', textAlign:'center' }} >RESULT</th>
                                        <th style={{ borderTop: '1px solid dimgrey', borderCollapse: 'collapse',borderBottom: '1px solid dimgrey', textAlign:'center' }} >REFERENCE</th>
                                    </tr>
                                </thead>
								<tbody styl={{ borderCollapse:'collapse'}}  >
								{this.state.reportResult.map((item,i)=>{
									
									return <React.Fragment> <tr styl={{ borderCollapse:'collapse'}}  >
										<td colSpan={3} style={styles.groupStyle}  > &nbsp; {item[0]['groupname']} </td>
									</tr> 
									
									{item[0]['profileid']!='0' && item[0]['profileid']!=null ? <tr>
										<td colSpan={3} style={styles.groupStyle}  > &nbsp;&nbsp;&nbsp; {item[0]['profilename']} </td>
									</tr> : '' }
									
									{this.getResultWithBold(item)}
									
									</React.Fragment>;
								})
								}
								</tbody>
                            </table>
                        </td>
                    </tr>
                </table>
				</div>
				{!this.props.printButton ?
				<Row>
					<Col md={12} >
						<div className="text-center" >
							<hr />
							<button type="button" className="btn btn-danger btn-sm" onClick={this.printDocument.bind(this,'print_div')} >
								PRINT REPORT
							</button>
						</div>
					</Col>
				</Row> : null}
				</React.Fragment>
				
				:
				<center style={{ minHeight: '150px' }} >
					<h3> Loading... </h3>
				</center> }
				
				<iframe name="print_frame" id="print_frame" width="0" height="0" frameBorder="0" src="about:blank"></iframe>
			
			</div>
		);
	}
	
}
export default WithoutHeader;
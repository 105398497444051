import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import Sanitize from '../../utils/Sanitize';
import {styles,appName} from '../../utils/Variables';
import RenderMenu from './rendermenu';
import {Row, Col, Nav, Navbar, NavDropdown,Form, FormControl, Button } from 'react-bootstrap';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';

import PATIENT_ICON from '../../assets/img/icons/patients.png';

import LOGO from '../../assets/img/labsoft_logo_v1.png';

class Header extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			isRedirect:false,
		}
		this.handleLogout = this.handleLogout.bind(this);
		this.openMasterPanel = this.openMasterPanel.bind(this);
	}
	
	componentDidMount(){
		
	}
	
	handleLogout(){
		swal({
			title:'Confirm',
		text: 'Do you want to Logout?',
		buttons:['No','Yes']}).then((willYes)=>{
			if(willYes){
				const cookie = new Cookies();
				cookie.remove('lab_userRoles');
				cookie.remove('lab_logged');
				
				var rol = [];				
				rol.push({nme:'tes'});
				
				window.localStorage.removeItem('lab_userRoles');
				window.localStorage.setItem('lab_logged','no');
				window.localStorage.setItem('lab_userRoles',rol);
				
				
				this.setState({isRedirect: true});
			}
		});		
	}
	
	getActiveUrl(url)
	{
		var s = url.split("/");
		return s[1];
	}

	openMasterPanel()
	{
		try{
			
			const cookie = new Cookies();
			var masterId = cookie.get('lab_master_logid');
			var masteUserName = cookie.get('lab_master_loguser');
			var masterPassword = cookie.get('lab_master_logpass');
			
			var url = 'http://localhost/test/lab.master?key';
			//alert(url);
			//window.open('https://google.com','_self');
			return url;
		}		
		catch(error){
			//console.log(error);
			return 'javascript:;';
		}
	}
	
	render(){
		
		const cookie = new Cookies();
		if(cookie.get('lab_logged')!='yes')
		{
			return( <Redirect to='/' /> );
		}
		else if( this.state.isRedirect )
		{
			return( <Redirect to='/' /> );
		}
		else{
		
			let activeMenu = window.location.hash.split( '/' )[1];
			let _activeMenu = '/'+window.location.hash.split( '/' );
			_activeMenu = _activeMenu.replace(/,/g, '/').slice(2);
		
			return(
				 <Navbar bg="primary_2" expand="lg" fixed="top" >
				  <Navbar.Brand href="javascript:;">
					<img src={LOGO} style={{ width: 100, marginTop: -10 }}  />
				  </Navbar.Brand>
				  <Navbar.Toggle aria-controls="basic-navbar-nav" />
				  <Navbar.Collapse id="basic-navbar-nav">
					<Nav className="mr-auto">
					 
						<RenderMenu />
					 
					</Nav>
					
					 {cookie.get('lab_master_logged')=='yes' ?
						<a href="" className="nav-link-item btn btn-info" href={this.openMasterPanel()}  >
							Open Master Panel
						 </a>
					 : null}
					
					 <Nav>
					
					   <NavDropdown title={Sanitize.getData('labUser_name')}   id="dropdown-menu-align-right" alignRight >
						<NavDropdown.Item href="#/myprofile">My Profile</NavDropdown.Item>
						{/*<NavDropdown.Item href="#/manage/labsettings">Settings</NavDropdown.Item>*/}
						<NavDropdown.Divider />
						<NavDropdown.Item href="javascript:;" onClick={this.handleLogout} >Logout</NavDropdown.Item>
					  </NavDropdown>
					  <Nav.Link eventKey={2} href="javascript:;" onClick={this.handleLogout} >
						<i className="fa fa-sign-out" style={{ fontSize: '15px' }} ></i>
					  </Nav.Link>
					</Nav>
					
				  </Navbar.Collapse>
				</Navbar>
				
			);
		}
	}
	
}
export default Header;


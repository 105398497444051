import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import Sanitize from '../../utils/Sanitize';
import Notifications, {notify} from 'react-notify-toast';
import {Row, Col, Nav, Navbar, NavDropdown,Form, FormControl, Button } from 'react-bootstrap';

import Roles from '../../utils/Roles';
import * as Role from './RoleList';

class RenderMenu extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
		
	}
	
	componentDidMount(){
		
	}

	getMenu(obj)
	{
		
	}

	render(){
	
		const rv = 	Role.RoleList.map((item,i)=>{
					return  item.subgroups ? 
					 <NavDropdown title={item.groupname} id="basic-nav-dropdown">
						 {
							 item.subgroups.map((obj,j)=>{
								 return (obj.menuid=='divider') ? 
								 <NavDropdown.Divider />  :
								  Roles.CanMenu(item.groupid,obj.menuid) ? 
									 <NavDropdown.Item href={obj.path} >{obj.menuname}</NavDropdown.Item>
									: null;
							 })
						 }
					 </NavDropdown>
					: Roles.CanMenu(item.groupid,item.menuid) ?
						<Nav.Link href={item.path} >{item.groupname}</Nav.Link> 
						: null;
			  });

		
		return rv;
	}
	
}
export default RenderMenu;


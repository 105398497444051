
//
// //
// // // For notifications
// //
//
var defaultWidth = window.screen.width > 768 ? window.screen.width * 1 / 3 : window.screen.width;

var appName = 'LabSoft';

var copyRight = {
	name: 'Peace Soft',
	url: 'http://peacesoft.in',
	version: '1.0'
};

//var apiUrl = 'http://localhost/labsoft/php/';
//var apiUrl = 'https://peacesofttechnologies.com/labsoft/api/';
var apiUrl = 'https://labsoft.hosaid.com/api/';
//var apiUrl = 'http://hostinglive.in/1912001/api/';

var imagePath = {
	patientImage: apiUrl+'public/uploads/patients/'
}

// *******************************
// Don't change this Secret key
//var PSJSC = 'B175001D4E951FaABbDAF98e13BI9';
var PSJSC = 'B17014E1FbDf9e1';
// Please keep it to working fine
// *******************************

var styles = {
	//headerBackground: 'linear-gradient(to right, rgb(232, 21, 0), rgb(255, 217, 26))',
	//headerBackground: 'linear-gradient(to right, rgb(5, 94, 127), rgb(12, 171, 75))',
	headerBackground: 'linear-gradient(90deg,#3baf30 0,#499ee4)',
	sidebarBorder: 'red'
};


module.exports = {
  defaultWidth,
  appName,
  imagePath,
  copyRight,
  apiUrl,
  PSJSC,
  styles
};

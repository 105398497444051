import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {appName,copyRight} from '../../../utils/Variables';
import * as Reqst from '../../../utils/Reqst';
import Sanitize from '../../../utils/Sanitize';
import {notify} from 'react-notify-toast';
import {Nav,Navbar,NavDropdown,Row,Col,Table,Modal,DropdownButton, Dropdown} from 'react-bootstrap';

const styles = {
	groupStyle: {
		fontWeight: '600',
	},
	resultBold: {
		fontWeight: '500',
	},
}

class Receipt extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginId: Sanitize.getData('loginUser_id'),
			companyId: Sanitize.getData('labUser_clientid'),
			masterId: Sanitize.getData('labUser_masterid'),
			dataList: [],
			dataView: [],
			reportResult: [],
		}
		
	}
	
	componentDidMount(){
        
		this.loadReport();
		
    }

	loadReport(){
		this.setState({showLoader:true});
		var token = Reqst.TimeStamp();
		//Reqst.axGet('reportmaster/getreport/1/1/89/1/'+token).then(res=>{
		Reqst.axGet('reportmaster/getreport/'+this.state.masterId+'/'+this.state.companyId+'/'+this.props.viewId+'/1/'+this.props.reportDate+'/'+token).then(res=>{
			if(res['data'].status=='1')
			{
				var d = Sanitize.groupByMultiple(res['data'].data, function(item){
					return [item.groupid, item.profileid];
				});
				
				this.setState({dataList: res['data'].data, dataView: res['data'].data, reportResult: d, showLoader:false});	
				if(this.props.autoPrint)
					this.printDocument("print_div_receipt");
			}
			else
			{
				notify.show(res['data'].msg,'error');
				this.setState({showLoader:false});	
			}
		});
	}
	
	getValue(fieldName){
		try{
			var d = this.state.dataView;
			return d[0][fieldName];
		}
		catch(error){
			return '';
		}
	}
	
	
	printDocument(printId){
		 var content = document.getElementById(printId);
		 var pri = document.getElementById("print_frame_receipt").contentWindow;
		 pri.document.open();
		 pri.document.write(content.innerHTML);
		 pri.document.close();
		 pri.focus();
		 pri.print();
		 if(this.props.atClose)
			 this.props.atClose();
	}
	
	render(){
		
		 
		return(
			<div>
				{Object.keys(this.state.dataView).length>0 ?
				<React.Fragment>
				
				<div style={{width:'755px',height:'430px', margin:'auto',background:'#fff',padding:'20px',fontSize:'13px'}} >
					<div id="print_div_receipt" >
						
						<table width="100%" align="center" >
							<tr>
								<td width="40%" >
								
								</td>
								<td style={{padding:'10px'}} >
									<table width="100%" align="center" >
										<tr><td align="right"><h5>{Sanitize.getData('labUser_name')}</h5></td></tr>
										<tr><td align="right">{Sanitize.getData('labUser_address')}</td></tr>
										<tr><td align="right">{Sanitize.getData('labUser_place')}, {Sanitize.getData('labUser_city')}, {Sanitize.getData('labUser_state')} - {Sanitize.getData('labUser_pincode')}</td></tr>
										<tr><td align="right">Ph: {Sanitize.getData('labUser_mobile')}, Email: {Sanitize.getData('labUser_email')}</td></tr>
									</table>
								</td>
							</tr>
							<tr>
								<td colSpan="2" align="center"><b>CASH RECEIPT</b></td>
							</tr>	
							<tr>
								<td colSpan="2" style={{borderTop:'1px solid black'}} ></td>
							</tr>
						</table>
						<table width="100%" align="center" >
							<tr>
								<td height="40" valign="middle" width="80%" >
									&emsp; Date : <div style={{width:'100px',borderBottom:'1px solid grey',marginTop: '-19px',marginLeft:'55px'}}> {this.getValue('reportdate')} </div>
								</td>
								<td height="40" valign="middle" align="" >
									&emsp; Receipt No : &emsp; <div style={{width:'120px',borderBottom:'1px solid grey',marginTop: '-19px',marginLeft:'95px'}}> R-{this.getValue('reportid')} </div>
								</td>
							</tr>
						</table>
						<table width="96%" align="center" >
							<tr>
								<td height="30" width="23%"  >Amount received from : </td>
								<td  style={{borderBottom:'1px solid grey'}} >&emsp; <b>{this.getValue('patientname')}</b></td>
							</tr>
							<tr>
								<td height="30"  >fot the report : </td>
								<td style={{borderBottom:'1px solid grey'}} >&emsp; <b> ID: {this.getValue('reportno')} &emsp; Date : {this.getValue('reportdate')} </b></td>
							</tr>
							<tr>
								<td height="30" >Amount : </td>
								<td style={{borderBottom:'1px solid grey'}} >&emsp; <b>{this.getValue('netamount')}</b></td>
							</tr>
							<tr>
								<td></td>
								<td align="right" height="130" valign="bottom" >
									For <b>{Sanitize.getData('labUser_name')}</b>
								</td>
							</tr>
						</table>
					
					</div>					
				</div>
				
				<div className="text-center">
					<button type="button" className="btn btn-warning btn-md" onClick={this.printDocument.bind(this,"print_div_receipt")} >
						Print Receipt
					</button>
				</div>
				
				</React.Fragment>
				
				:
				<center style={{ minHeight: '150px' }} >
					<h3> Loading... </h3>
				</center> }
				
				<iframe name="print_frame" id="print_frame_receipt" width="0" height="0" frameBorder="0" src="about:blank"></iframe>
			
			</div>
		);
	}
	
}
export default Receipt;
import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import Sanitize from '../../utils/Sanitize';
import {styles,appName} from '../../utils/Variables';
import RenderMenu from './rendermenu';
import {Row, Col, Nav, Navbar, NavDropdown,Form, FormControl, Button } from 'react-bootstrap';
import swal from 'sweetalert';

import PATIENT_ICON from '../../assets/img/icons/patients.png';

import LOGO from '../../assets/img/labsoft_logo_v1.png';

class Header extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			isRedirect:false,
			sidebarHasOpen: true,
		}
		this.handleLogout = this.handleLogout.bind(this);
		this.handleSidebarToggle = this.handleSidebarToggle.bind(this);
	}
	
	componentDidMount(){
		
	}
	
	handleLogout(){
		swal({
			title:'Confirm',
		text: 'Do you want to Logout?',
		buttons:['No','Yes']}).then((willYes)=>{
			if(willYes){
				var rol = [];
				rol.push({nme:'tes'});
				localStorage.removeItem('lab_userRoles');
				localStorage.setItem('lab_logged','no');
				this.setState({isRedirect: true});
			}
		});		
	}
	
	getActiveUrl(url)
	{
		var s = url.split("/");
		return s[1];
	}
	
	handleSidebarToggle(){
		if( $("#sidebar-wrapper").is(".minifed-menu") )
		{
			this.setState({sidebarHasOpen:true});
			$("#sidebar-wrapper").removeClass('minifed-menu');
		}
		else
		{
			this.setState({sidebarHasOpen:false});
			$("#sidebar-wrapper").addClass('minifed-menu');
		}
		
	}


	render(){
		

			let activeMenu = window.location.hash.split( '/' )[1];
			let _activeMenu = '/'+window.location.hash.split( '/' );
			_activeMenu = _activeMenu.replace(/,/g, '/').slice(2);
		
			return(
			<>
				<Navbar  expand="lg" fixed="top" className="fixed-top-primary" >
					<div className="container" >
						<Row style={{ width: '100%' }} >
							<Col md={3} >
								{/*<img src={LOGO} style={{ width: 100, marginTop: -10 }}  />*/}
								<img src="http://www.vedicvidyashram.com/images/logo-header.JPG" style={{ height: '67px' }} />
							</Col>
							<Col md={7} >
								<div className="text-right logo-help-text">
									<div> Ph: +91 9487900120 </div>
									<div> Mail: peacesoft.developer@gmail.com </div>
								</div>
							</Col>
							<Col md={2} >
								<div className="text-right" >
									<h3 className="logo-text" >Kalvisalai.in</h3>
								</div>
							</Col>
						</Row>
					</div>
				</Navbar>
				
				 <Navbar  expand="lg" fixed="top" className="fixed-top-2"  >
					
				 
				  <Navbar.Toggle aria-controls="basic-navbar-nav" />
				  <Navbar.Collapse id="basic-navbar-nav">
					<Nav className="mr-auto">
						
						<Nav.Link href="javascript:;" className="menu-open-hide" onClick={this.handleSidebarToggle}  >
						{this.state.sidebarHasOpen ? <i className="fa fa-arrow-left"></i> : <i className="fa fa-th"></i>}
						</Nav.Link>
						
						<RenderMenu />
					 
					</Nav>
					
					 <Nav>
						
					   <NavDropdown title="Subbiah P"   id="dropdown-menu-align-right" alignRight >
						<NavDropdown.Item href="#action/3.1">My Profile</NavDropdown.Item>
						<NavDropdown.Item href="#action/3.2">Settings</NavDropdown.Item>
					  </NavDropdown>
					  <Nav.Link eventKey={2} className="sing-out" href="javascript:;" onClick={this.handleLogout} >
						  {/*<i className="fa fa-sign-out" style={{ fontSize: '15px' }} ></i>*/}
						  Sign Out
					  </Nav.Link>
					</Nav>
					
				  </Navbar.Collapse>
				</Navbar>
				
			</>);

	}
	
}
export default Header;


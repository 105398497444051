import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {appName,copyRight} from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import {notify} from 'react-notify-toast';
import {Helmet} from "react-helmet";
import {Nav,Navbar,NavDropdown,Row,Col,Table,Modal,DropdownButton, Dropdown, Card} from 'react-bootstrap';
import Toggle from 'react-bootstrap-toggle';
import "react-bootstrap-toggle/dist/bootstrap2-toggle.css";
import Roles from '../../utils/Roles';
import swal from 'sweetalert';
import Pagination from "react-js-pagination";

import PageTitle from '../../containers/PageTitle';

class AddUser extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginId: Sanitize.getData('loginUser_id'),
			companyId: Sanitize.getData('labUser_clientid'),
			masterId: Sanitize.getData('labUser_masterid'),
			saveText: 'Save User',
			isRedirect: false,
			roleList: [],
			accessGiven: [],
			canUseAll: false,
			canListAll: false,
			canAddAll: false,
			canViewAll: false,
			canEditAll: false,
			canDeleteAll: false,
			canPrintAll: false,
			canExportAll: false,
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		
	}
	
	componentDidMount(){
		//this.loadProfile();
		this.loadUserRoles();
	}

	loadUserRoles(){
		try{
			
			this.setState({showl:true});
			Reqst.axGet('userrole/getrolelist/'+this.state.masterId+'/'+this.state.companyId).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({roleList: res['data'].data});
				}
				this.setState({showLoader:false});
			});
		}
		catch(error){
			
		}
	}
		
	loadProfile(){
		this.setState({showLoader:true});
		var token = Reqst.TimeStamp();
		
		Reqst.axGet('settings/getlogin/'+this.state.masterId+'/'+this.state.companyId+'/'+this.state.loginId+'/'+token).then(res=>{
			if(res['data'].status=='1')
			{
				this.setState({ dataView: res['data'].data, showLoader:false});	
			}
			else
			{
				notify.show(res['data'].msg,'error');
				this.setState({dataList: [], dataView: [],showLoader:false});	
			}
		});
	}
	
	
	handleClick(groupId,menuId,opTion,e){
		try{
			
			var d = this.state.accessGiven;
			var has = d.findIndex(function(item){
				return(item.groupid==groupId && item.menuid==menuId);
			});
			
			if(has > -1)
			{
				if(d[has][opTion]=='1')
					d[has][opTion] = '0';
				else d[has][opTion] = '1';
			}
			else
			{
				var canUse = opTion=='canuse' ? '1' : '0';
				//var canUse = opTion=='canuse' ? '1' : '0';
				d.push({'groupid':groupId,'menuid':menuId, 'canuse':canUse});
			}
			
			this.setState({accessGiven: d});
		}
		catch(error){ 
			console.log(error);
		}
	}
	
	checkHasRole(groupId,menuId,opTion){
		try{
			
			var d = this.state.accessGiven;
			
			var has = d.filter(function(item){
				return(item.groupid==groupId && item.menuid==menuId);
			});
			
			if(Object.keys(has).length>0)
			{
				return (has[0][opTion]=='1');
			}
			
			return false;
		}
		catch(error){
			console.log(error);
			return false;
		}
	}
	
	handleSelectAll(opTion){
		
		try{
			var sta = false;
			if(opTion=='canuse'){
				sta = !this.state.canUseAll;
				this.state.canUseAll = sta;
			}
			else if(opTion=='canlist'){
				sta = !this.state.canListAll;
				this.state.canListAll = sta;
			}
			else if(opTion=='canadd'){
				sta = !this.state.canAddAll;
				this.state.canAddAll = sta;
			}
			else if(opTion=='canview'){
				sta = !this.state.canViewAll;
				this.state.canViewAll = sta;
			}
			else if(opTion=='canedit'){
				sta = !this.state.canEditAll;
				this.state.canEditAll = sta;
			}
			else if(opTion=='candelete'){
				sta = !this.state.canDeleteAll;
				this.state.canDeleteAll = sta;
			}
			else if(opTion=='canprint'){
				sta = !this.state.canPrintAll;
				this.state.canPrintAll = sta;
			}
			else if(opTion=='canexport'){
				sta = !this.state.canExportAll;
				this.state.canExportAll = sta;
			}
			
			var roles = this.state.roleList;
			var d = this.state.accessGiven;
				roles.map((obj,i)=>{
					var groupId = obj.groupid;
					var menuId = obj.menuid;
					
					
					var has = d.findIndex(function(item){
						return(item.groupid==groupId && item.menuid==menuId);
					});
					
					if(has > -1)
					{
						if(sta)
							d[has][opTion] = '1';
						else
							d[has][opTion] = '0';
					}
					else
					{
						var canUse = opTion=='canuse' ? '1' : '0';
						//var canUse = opTion=='canuse' ? '1' : '0';
						d.push({'groupid':groupId,'menuid':menuId, 'canuse':canUse});
					}
					
				})
			this.setState({accessGiven: d});
		}
		catch(error){
			
		}
	}
	
	handleFormSubmit(e){
		try{
			e.preventDefault();
			this.setState({showLoader:true, saveText:'Saving..'});
			Reqst.axPost('userrole/adduser/', $("#frmUser").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					notify.show(res['data'].msg,'success');
					document.getElementById("frmUser").reset();
					this.setState({accessGiven:[], showLoader:false, saveText: 'Save User'});
				}
				else
				{
					notify.show(res['data'].msg,'error');
					this.setState({showLoader:false, saveText: 'Save User'});
				}
			});
		}
		catch(error){
			console.log(error);
			this.setState({showLoader:false, saveText: 'Save User'});
		}
	}

	render(){
		
		
		let sno = 1;
		
		return(
			<div>
			
				<Helmet>
					<title>{appName} | Add User</title>
				</Helmet>
				
				<PageTitle title="Add User" loading={this.state.showLoader} > 
					<div className="text-right" >
						<Link to="/manage/manageusers" className="btn btn-primary btn-sm" >
							<i className="fa fa-arrow-left"></i>&nbsp;&nbsp; Back to List
						</Link>
					
					</div>
				</PageTitle>
			
				<form id="frmUser" action="" method="post" encType="mutlipart/form-data" onSubmit={this.handleFormSubmit} >
				
						<input type="hidden" name="i__lid" value={this.state.loginId} />
					<input type="hidden" name="i__company" value={this.state.companyId} />
					<input type="hidden" name="i__master" value={this.state.masterId} />
					<input type="hidden" name="i__access" id="i__access" value={JSON.stringify(this.state.accessGiven)} />
				
					<Row>
						<Col md={3} >
							<label>Username <span className="text-danger">*</span></label>
							<input type="text" name="i__username" id="i__username" className="form-control input-sm" required autoComplete="off" />
						</Col>						
						<Col md={3} >
							<label>User Role <span className="text-danger">*</span></label>
							<select name="i__role" id="i__role" className="form-control input-sm" required >
								<option value="staff" > Staff </option>
								<option value="branchadmin" > Branch Admin </option>
								<option value="admin" > Admin </option>
							</select>
						</Col>	
						<Col md={2} >
							<label>Password <span className="text-danger">*</span></label>
							<input type="password" name="i__password" id="i__password" className="form-control input-sm" required autoComplete="off" />
						</Col>	
						<Col md={2} >
							<label>Confirm Password <span className="text-danger">*</span></label>
							<input type="password" name="i__cpassword" id="i__cpassword" className="form-control input-sm" required autoComplete="off" />
						</Col>	
						<Col md={2} style={{ marginTop: '26px' }} >
							<button type="submit" className="btn btn-success btn-sm btn-block" disabled={this.state.saveText!='Save User'} >
								{this.state.saveText}
							</button>
						</Col>
					</Row>
					
					<Row style={{marginTop:'15px'}} >
						<Col md={12} >
							<Card>
								<Card.Header>
									Access Permissions
								</Card.Header>
								<Card.Body style={{ padding: '0px' }} >
									<Table >
										<thead>
											<tr>
												<th>S.No</th>
												<th>Group</th>
												<th>Menu</th>
												<th>Use
													<input type="checkbox" checked={this.state.canUseAll} onChange={this.handleSelectAll.bind(this,'canuse')} style={{ float:'right' }}/>
												</th>
												<th>List
													<input type="checkbox" checked={this.state.canListAll} onChange={this.handleSelectAll.bind(this,'canlist')} style={{ float:'right' }}/>
												</th>
												<th>Add
													<input type="checkbox" checked={this.state.canAddAll} onChange={this.handleSelectAll.bind(this,'canadd')} style={{ float:'right' }}/>
												</th>
												<th>View
													<input type="checkbox" checked={this.state.canViewAll} onChange={this.handleSelectAll.bind(this,'canview')} style={{ float:'right' }}/>
												</th>
												<th>Edit
													<input type="checkbox" checked={this.state.canEditAll} onChange={this.handleSelectAll.bind(this,'canedit')} style={{ float:'right' }}/>
												</th>
												<th>Delete
													<input type="checkbox" checked={this.state.canDeleteAll} onChange={this.handleSelectAll.bind(this,'candelete')} style={{ float:'right' }}/>
												</th>
												<th>Print
													<input type="checkbox" checked={this.state.canPrintAll} onChange={this.handleSelectAll.bind(this,'canprint')} style={{ float:'right' }}/>
												</th>
												<th>Export
													<input type="checkbox" checked={this.state.canExportAll} onChange={this.handleSelectAll.bind(this,'canexport')} style={{ float:'right' }}/>
												</th>
											</tr>
										</thead>
										<tbody>
										{this.state.roleList.map((role,i)=>{
											var canUse = this.checkHasRole(role.groupid,role.menuid,'canuse');
											var canList = this.checkHasRole(role.groupid,role.menuid,'canlist');
											var canAdd = this.checkHasRole(role.groupid,role.menuid,'canadd');
											var canView = this.checkHasRole(role.groupid,role.menuid,'canview');
											var canEdit = this.checkHasRole(role.groupid,role.menuid,'canedit');
											var canDelete = this.checkHasRole(role.groupid,role.menuid,'candelete');
											var canPrint = this.checkHasRole(role.groupid,role.menuid,'canprint');
											var canExport = this.checkHasRole(role.groupid,role.menuid,'canexport');
											
											return <tr key={i} >
												<td>{sno++}</td>
												<td>{role.groupid.toUpperCase()}</td>
												<td>{role.menuid.toUpperCase()}</td>
												<td onClick={this.handleClick.bind(this,role.groupid,role.menuid,'canuse')} style={{backgroundColor:  canUse ? '#97e897' : '#fff'}} >
													{canUse ? 'Yes' : ''}
												</td>
												
												<td onClick={this.handleClick.bind(this,role.groupid,role.menuid,'canlist')} style={{backgroundColor:  canList ? '#97e897' : '#fff'}} >
													{canList ? 'Yes' : ''}
												</td>
												
												<td onClick={this.handleClick.bind(this,role.groupid,role.menuid,'canadd')} style={{backgroundColor:  canAdd ? '#97e897' : '#fff'}} >
													{canAdd ? 'Yes' : ''}
												</td>
												
												<td onClick={this.handleClick.bind(this,role.groupid,role.menuid,'canview')} style={{backgroundColor:  canView ? '#97e897' : '#fff'}} >
													{canView ? 'Yes' : ''}
												</td>
												
												<td onClick={this.handleClick.bind(this,role.groupid,role.menuid,'canedit')} style={{backgroundColor:  canEdit ? '#97e897' : '#fff'}} >
													{canEdit ? 'Yes' : ''}
												</td>
												
												<td onClick={this.handleClick.bind(this,role.groupid,role.menuid,'candelete')} style={{backgroundColor:  canDelete ? '#97e897' : '#fff'}} >
													{canDelete ? 'Yes' : ''}
												</td>
												
												<td onClick={this.handleClick.bind(this,role.groupid,role.menuid,'canprint')} style={{backgroundColor:  canPrint ? '#97e897' : '#fff'}} >
													{canPrint ? 'Yes' : ''}
												</td>
												
												<td onClick={this.handleClick.bind(this,role.groupid,role.menuid,'canexport')} style={{backgroundColor:  canExport ? '#97e897' : '#fff'}} >
													{canExport ? 'Yes' : ''}
												</td>
												
											</tr>;
										})
										}
										</tbody>
									</Table>
								</Card.Body>
							</Card>
						</Col>
					</Row>
					
				</form>
				
			</div>
		);
	}
	
}
export default AddUser;
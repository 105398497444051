import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {copyRight} from '../../../utils/Variables';
import * as Reqst from '../../../utils/Reqst';
import Sanitize from '../../../utils/Sanitize';
import {notify} from 'react-notify-toast';
import {Nav,Navbar,NavDropdown,Row,Col,Table,Modal} from 'react-bootstrap';

import FullPageLoader from '../../../containers/FullPageLoader';

class Edit extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginId: Sanitize.getData('loginUser_id'),
			companyId: Sanitize.getData('labUser_clientid'),
			masterId: Sanitize.getData('labUser_masterid'),
			saveText: 'UPDATE TEST',
			isRedirect: false,
			showLoader: false,
			editData: this.props.editData,
			groupData: [],
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		
		this.loadGroupMasters();
	}
	
	loadGroupMasters(){
		this.setState({showFullLoader:true});
		var token = Reqst.TimeStamp();
		Reqst.axGet('groupmaster/getactivegroups/'+this.state.masterId+'/'+this.state.companyId+'/'+token).then(res=>{
			if(res['data'].status=='1')
			{
				this.setState({groupData: res['data'].data, showFullLoader:false});	
			}
			else
			{
				this.setState({showFullLoader:false});	
			}
		});
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		this.setState({showFullLoader:true,saveText:'Updating..'});
		var formData = Sanitize.objectifyForm($("#frmAdd").serializeArray());
		Reqst.axPost('testmaster/updatetest',{token: Reqst.Token(formData)}).then(res=>{
			if(res['data'].status=='1')
			{
				notify.show(res['data'].msg,'success');
				this.setState({showFullLoader:false,saveText:'UPDATE TEST'});
				if(this.props.afterFinish) this.props.afterFinish();
			}
			else
			{
				notify.show(res['data'].msg,'error');
				this.setState({showFullLoader:false,saveText:'UPDATE TEST'});
			}
		});
	}
	
	getData(field){
		var l = this.state.editData;
		return l[field];
	}
	
	setData(field,e){
		var l = this.state.editData;
		l[field] = e.target.value;
		this.setState({editData: l });
	}
	
	render(){
		
		return(
			<div>			
			
				<FullPageLoader loading={this.state.showFullLoader} />
			
				<form action="" method="post" encType="multipart/form-data" id="frmAdd" onSubmit={this.handleFormSubmit} >
					<input type="hidden" name="i__id" value={this.getData('id')} />
					<input type="hidden" name="i__lid" value={this.state.loginId} />
					<input type="hidden" name="i__company" value={this.state.companyId} />
					<input type="hidden" name="i__master" value={this.state.masterId} />
					
					<Row>
						<Col md={3} >
							<label>Test Code <span className="text-danger">*</span></label>
							<input type="text" name="i__code" id="i__code" className="form-control input-sm" required value={this.getData('testcode')} onChange={this.setData.bind(this,'testcode')} />
						</Col>
						<Col md={9} >
							<label>Test Name <span className="text-danger">*</span></label>
							<input type="text" name="i__name" id="i__name" className="form-control input-sm" required value={this.getData('testname')} onChange={this.setData.bind(this,'testname')} />
						</Col>
					</Row>
					
					<Row>
						<Col md={3} >
							<label>Unit <span className="text-danger"></span></label>
							<input type="text" name="i__unit" id="i__unit" className="form-control input-sm" value={this.getData('testunit')} onChange={this.setData.bind(this,'testunit')} />
						</Col>
						<Col md={9} >
							<label>Under Group <span className="text-danger">*</span></label>
							<select name="i__group" id="i__group" className="form-control input-sm" required >
								<option value="">Select Group</option>
							{
								this.state.groupData.map((item,i)=>{
									return <option value={item.id} selected={this.getData('groupid')==item.id ? 'selected' : ''} > {item.groupname} </option>;
								})
							}
							</select>
						</Col>
					</Row>
					
					<Row>
						<Col md={3} >
							<label>Reference Range <span className="text-danger">*</span></label>
							<input type="text" name="i__referencerange" id="i__referencerange" className="form-control input-sm" value={this.getData('referencerange')} onChange={this.setData.bind(this,'referencerange')} />
						</Col>
						<Col md={3} >
							<label>Condition <span className="text-danger"></span></label>
							<select name="i__referencecondition" id="i__referencecondition" className="form-control input-sm" >
								<option value="Nothing" selected={this.getData('referencecondition')=='Nothing' ? 'selected' : ''} >Nothing</option>
								<option value="Equal" selected={this.getData('referencecondition')=='Equal' ? 'selected' : ''} >Equal</option>
								<option value="Nill" selected={this.getData('referencecondition')=='Nill' ? 'selected' : ''} >Nill</option>
								<option value="Contain" selected={this.getData('referencecondition')=='Contain' ? 'selected' : ''} >Contain</option>
								<option value="Less_Than" selected={this.getData('referencecondition')=='Less_Than' ? 'selected' : ''} >Less_Than</option>
								<option value="Greater_Than" selected={this.getData('referencecondition')=='Greater_Than' ? 'selected' : ''} >Greater_Than</option>
								<option value="Between" selected={this.getData('referencecondition')=='Between' ? 'selected' : ''} >Between</option>
							</select>
						</Col>
						<Col md={3} >
							<label>From <span className="text-danger"></span></label>
							<input type="text" name="i__referencefrom" id="i__referencefrom" className="form-control input-sm" value={this.getData('referencefrom')} onChange={this.setData.bind(this,'referencefrom')} />
						</Col>
						<Col md={3} >
							<label>To <span className="text-danger"></span></label>
							<input type="text" name="i__referenceto" id="i__referenceto" className="form-control input-sm" value={this.getData('referenceto')} onChange={this.setData.bind(this,'referenceto')} />
						</Col>
					</Row>
					
					<Row>
						<Col md={3} >
							<label>Female Range <span className="text-danger">*</span></label>
							<input type="text" name="i__femalerange" id="i__femalerange" className="form-control input-sm" value={this.getData('femalerange')} onChange={this.setData.bind(this,'femalerange')} />
						</Col>
						<Col md={3} >
							<label>Condition <span className="text-danger"></span></label>
							<select name="i__femalecondition" id="i__femalecondition" className="form-control input-sm" >
								<option value="Nothing" selected={this.getData('femalecondition')=='Nothing' ? 'selected' : ''} >Nothing</option>
								<option value="Equal" selected={this.getData('femalecondition')=='Equal' ? 'selected' : ''} >Equal</option>
								<option value="Nill" selected={this.getData('femalecondition')=='Nill' ? 'selected' : ''} >Nill</option>
								<option value="Contain" selected={this.getData('femalecondition')=='Contain' ? 'selected' : ''} >Contain</option>
								<option value="Less_Than" selected={this.getData('femalecondition')=='Less_Than' ? 'selected' : ''} >Less_Than</option>
								<option value="Greater_Than" selected={this.getData('femalecondition')=='Greater_Than' ? 'selected' : ''} >Greater_Than</option>
								<option value="Between" selected={this.getData('femalecondition')=='Between' ? 'selected' : ''} >Between</option>
							</select>
						</Col>
						<Col md={3} >
							<label>From <span className="text-danger"></span></label>
							<input type="text" name="i__femalefrom" id="i__femalefrom" className="form-control input-sm" value={this.getData('femalefrom')} onChange={this.setData.bind(this,'femalefrom')} />
						</Col>
						<Col md={3} >
							<label>To <span className="text-danger"></span></label>
							<input type="text" name="i__femaleto" id="i__femaleto" className="form-control input-sm" value={this.getData('femaleto')} onChange={this.setData.bind(this,'femaleto')} />
						</Col>
					</Row>
					
					
					<Row>
						<Col md={3} >
							<label>Testing Price <span className="text-danger"></span></label>
							<input type="number" name="i__price" id="i__price" className="form-control input-sm" value={this.getData('price')} onChange={this.setData.bind(this,'price')} />
						</Col>
						<Col md={9} >
							<label>Technology Used <span className="text-danger"></span></label>
							<input type="text" name="i__technology" id="i__technology" className="form-control input-sm" value={this.getData('technologyused')} onChange={this.setData.bind(this,'technologyused')} />
						</Col>
					</Row>
					
					<Row>
						<Col md={3} >
							<label>Active Status <span className="text-danger">*</span></label>
							<select name="i__status" id="i__status" className="form-control input-sm" required >
								<option value="1" selected={this.getData('teststatus')=='1' ? 'selected' : ''} > Active </option>
								<option value="0" selected={this.getData('teststatus')=='0' ? 'selected' : ''}  > In Active </option>
							</select>
						</Col>
						<Col md={9} >
							<div className="text-right" style={{ marginTop: 25 }} >
								<button type="submit" className="btn btn-primary btn-sm btn-success_2" disabled={this.state.saveText!='UPDATE TEST'} >
									<i className="fa fa-check"></i> {this.state.saveText} 
								</button>
							</div>
						</Col>
					</Row>
					
					
				</form>
			</div>
		);
	}
	
}
export default Edit;
import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {copyRight} from '../../utils/Variables';
import Sanitize from '../../utils/Sanitize';

import {Row, Col, Nav, Navbar, NavDropdown,Form, FormControl, Button } from 'react-bootstrap';



class Footer extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			currentTime: '',
			isRedirect: false,
			redirectPath: '',
		}
		this.doDate = this.doDate.bind(this);
	}
	
	componentDidMount(){
		
		setInterval(this.doDate, 1000);
	}
	
	
	doDate()
	{
		var str = "";

		var days = new Array("Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday");
		var months = new Array("January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December");

		var now = new Date();

		str += "Today is: " + days[now.getDay()] + ", " + now.getDate() + " " + months[now.getMonth()] + " " + now.getFullYear() + " " + now.getHours() +":" + now.getMinutes() + ":" + now.getSeconds();
		this.setState({currentTime:str});
	}



	render(){
		
		var ip = localStorage.getItem('login_ip');
		
		if(this.state.isRedirect)
		{
			return (<Redirect to={this.state.redirectPath} />);
		}
	
		return(
				
		<footer className="footer">
		  <div className="containers">
			<div className="pull-left">
				Copyright &copy; {(new Date()).getFullYear()}<a href={copyRight.url} target="_blank"> {copyRight.name} </a> | Version {copyRight.version} 
				&emsp;<span style={{ color:'', fontWeight: '700' }} >IP : {ip}</span>
				&emsp; <span id="todaysDate" style={{ color:'', fontSize: '13px', fontWeight: '700' }} >{this.state.currentTime}</span>
			</div>
			
			<div className="pull-right">
				Help : +91 9487900120 (or) peacesoft.developer@gmail.com
			</div>
		  </div>
		</footer>
			
		);
	}
	
}
export default Footer;


import React, { Component } from 'react';

export const RoleList = [
	{
		groupid: 'dashboard',
		menuid: 'dashboard',
		groupname: 'Dashboard',
		path: '#/dashboard'
	},
	{
		groupid: 'manage',
		groupname: 'Manage',
		subgroups:[
			{
				menuid: 'testmaster',
				menuname: 'Test Master',
				path: '#/manage/testmaster'
			},
			{
				menuid: 'profilemaster',
				menuname: 'Profile Master',
				path: '#/manage/profilemaster'
			},
			{
				menuid: 'groupmaster',
				menuname: 'Group Master',
				path: '#/manage/groupmaster'
			},
			{ menuid: 'divider' },
			{
				menuid: 'doctorsmaster',
				menuname: 'Doctors Master',
				path: '#/manage/doctorsmaster'
			},
			{
				menuid: 'employeemaster',
				menuname: 'Employee Master',
				path: '#/manage/employeemaster'
			},
			{ menuid: 'divider' },
			{
				menuid: 'labsettings',
				menuname: 'Lab Settings',
				path: '#/manage/labsettings'
			},
			{
				menuid: 'smssettings',
				menuname: 'SMS Settings',
				path: '#/manage/smssettings'
			},
			{ menuid: 'divider' },
			{
				menuid: 'manageusers',
				menuname: 'Manage Users',
				path: '#/manage/manageusers'
			},
		]
	},
	{
		groupid: 'patients',
		menuid: 'patients',
		groupname: 'Patients',
		path: '#/patients'
	},
	{
		groupid: 'reports',
		groupname: 'Reports',
		subgroups:[
			{
				menuid: 'reportsentry',
				menuname: 'Report Entry',
				path: '#/reports/reportsentry'
			},
			{
				menuid: 'reportsentry',
				menuname: 'Report List',
				path: '#/reports/reportslist'
			},
			{
				menuid: 'reportsentry',
				menuname: 'Daily Report',
				path: '#/reports/dailyreport'
			}
		]
	},
];
import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {appName,copyRight} from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import {notify} from 'react-notify-toast';
import {Helmet} from "react-helmet";
import {Nav,Navbar,NavDropdown,Row,Col,Table,Modal,DropdownButton, Dropdown,Card,Tabs,Tab} from 'react-bootstrap';
import Toggle from 'react-bootstrap-toggle';
import "react-bootstrap-toggle/dist/bootstrap2-toggle.css";
import Roles from '../../utils/Roles';
import swal from 'sweetalert';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Pagination from "react-js-pagination";

import PageTitle from '../../containers/PageTitle';

class Test extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginId: Sanitize.getData('loginUser_id'),
			companyId: Sanitize.getData('labUser_clientid'),
			masterId: Sanitize.getData('labUser_masterid'),
			saveText: 'Update Settings',
			isRedirect: false,
			amount: '0.00',
			count: '0',
			reports: [],
		}
		
	}
	
	componentDidMount(){
		
	}
	
		
	render(){
		
		
		 
		return(
			<div>
			
				<Helmet>
					<title>{appName} | Dashboard</title>
				</Helmet>
				
				<PageTitle title="Dashboard" loading={this.state.showLoader} > 
					<div className="text-right">
					
					</div>
				</PageTitle>
				
				<h3> Hi this is Test Component {this.props.message} </h3>
				
				
				
			</div>
		);
	}
	
}
export default Test;
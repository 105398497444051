import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {copyRight,imagePath} from '../../../utils/Variables';
import * as Reqst from '../../../utils/Reqst';
import Sanitize from '../../../utils/Sanitize';
import {notify} from 'react-notify-toast';
import {Nav,Navbar,NavDropdown,Row,Col,Table,Modal} from 'react-bootstrap';
import NOIMAGE from '../../../assets/img/noimage.jpg';
import ARROW_TOP_RIGHT from '../../../assets/img/arrow-top-right.png';
import FullPageLoader from '../../../containers/FullPageLoader';

const styles = {
	patientImg: {
		width: '169px',
		cursor: 'pointer',
		border: '1px solid #dcdcdc',
	}
}

class Search extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginId: Sanitize.getData('loginUser_id'),
			companyId: Sanitize.getData('labUser_clientid'),
			masterId: Sanitize.getData('labUser_masterid'),
			saveText: 'UPDATE PROFILE',
			isRedirect: false,
			showLoader: false,
			showFullLoader: false,
			patientsList: [],
			patientsView: [],
			loadFromAll: false,
		}
		
		this.handleAllChkClick = this.handleAllChkClick.bind(this);
	}
	
	componentDidMount(){
		
			this.setState({loadFromAll:true});
			this.loadPatients();
		
	}
	
	handleAllChkClick(e){
		if(e.target.checked)
		{
			this.setState({loadFromAll:true});
			this.loadPatients();
		}
		else
		{
			this.setState({loadFromAll:false, patientsView: []});
		}
	}
	
	loadPatients(){
		this.setState({showFullLoader:true});
		var token = Reqst.TimeStamp();
		Reqst.axGet('patientmaster/getactivepatientslist/'+this.state.masterId+'/'+this.state.companyId+'/'+token).then(res=>{
			if(res['data'].status=='1')
			{
				this.setState({patientsList: res['data'].data, patientsView: res['data'].data, showFullLoader:false, loadFromAll: true});	
			}
			else
			{
				this.setState({showFullLoader:false});	
			}
		});
	}
	
	handleSearch(col,e){
		try{
			
			var d = this.state.patientsList;
			var v = e.target.value.toUpperCase();
			if(this.state.loadFromAll)
			{
				var l = d.filter(function(obj){
					console.log(obj[col]);
					const n = obj[col].toUpperCase();
					return (n.indexOf(v)>-1);
				});
				this.setState({patientsView: l});
			}
			else
			{
				
			}
		}
		catch(error){ console.log(error); }
	}
	
	handleSelectClick(pId){
		
		var d = this.state.patientsView;
		var l = d.filter(function(obj){
			return (obj.patientid==pId);
		});
		if(this.props.afterFinish) this.props.afterFinish(l);
	}
	
	render(){
		
		let sno=1;
		
		return(
			<div>			
				
				<Row>
					<Col md={2} >
						<label>Patient Id</label>
						<input type="text" className="form-control input-sm" onChange={this.handleSearch.bind(this,'patientcode')} />
						<button type="button" className="btn-search" onClick={this.handleSearch.bind(this,'patientcode')} >
							<i className="fa fa-search" ></i>
						</button>
					</Col>
					<Col md={4} >
						<label>Patient Name</label>
						<input type="text" className="form-control input-sm" onChange={this.handleSearch.bind(this,'patientname')} />
						<button type="button" className="btn-search" onClick={this.handleSearch.bind(this,'patientname')} >
							<i className="fa fa-search" ></i>
						</button>
					</Col>
					<Col md={3} >
						<label>Mobile</label>
						<input type="text" className="form-control input-sm" onChange={this.handleSearch.bind(this,'mobile')} />
						<button type="button" className="btn-search" onClick={this.handleSearch.bind(this,'mobile')} >
							<i className="fa fa-search" ></i>
						</button>
					</Col>
					<Col md={3} >
						<label for="i__loadall" style={{ marginTop: '30px' }} >
							<input type="checkbox" id="i__loadall" onChange={this.handleAllChkClick} /> &nbsp;&nbsp; Load all Patients
						</label>
					</Col>
				</Row>
				
				<Row>
					<Col md={12} style={{ height: '400px', overflowY:'sroll' }} className="scroll-style-3" >
						<FullPageLoader loading={this.state.showFullLoader} />
						<hr />
						<Table>
							<thead>
								<tr>
									<th>S.No</th>
									<th>Patient Id</th>
									<th>Name</th>
									<th>Age/Sex</th>
									<th>Mobile</th>
									<th>Address</th>
									<th>Select</th>
								</tr>
							</thead>
							<tbody>
							{Object.keys(this.state.patientsView).length>0 ?
								this.state.patientsView.map((item,key)=>{
									return <tr key={key} >
										<td>{sno++}</td>
										<td align="center" >
											{this.state.displayWithImage ? <img src={this.getImageUrl(item.patientpicture)} style={{width:55, border:'1px solid #909090'}} /> : null}
											{this.state.displayWithImage ? <br /> : null} <b>{item.patientcode}</b>
										</td>
										<td>{item.patientname}</td>
										<td>{Sanitize.getAge(item.dob,item.age)} / {item.gender} </td>
										<td>{item.mobile}</td>
										<td>{item.place}</td>
										<td>
											<button type="button" className="btn btn-success btn-sm" onClick={this.handleSelectClick.bind(this,item.patientid)} >
												Select
											</button>
										</td>
									</tr>;
								})
								:
								<tr>
									<td colSpan="7" align="center" >
										Data Not Found..!
									</td>
								</tr>
							}
							</tbody>
						</Table>
					</Col>
				</Row>
				
			</div>
		);
	}
	
}
export default Search;
import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import Sanitize from '../../utils/Sanitize';
import {styles,appName} from '../../utils/Variables';
import RenderMenu from './rendermenu';
import {Row, Col, Nav, Navbar, NavDropdown,Form, FormControl, Button } from 'react-bootstrap';
import swal from 'sweetalert';

import PATIENT_ICON from '../../assets/img/icons/patients.png';

import LOGO from '../../assets/img/labsoft_logo_v1.png';

class Sidebar extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			isRedirect:false,
		}

	}
	
	componentDidMount(){
		
	}
	
	
	
	getActiveUrl(url)
	{
		var s = url.split("/");
		return s[1];
	}


	render(){
		

			let activeMenu = window.location.hash.split( '/' )[1];
			let _activeMenu = '/'+window.location.hash.split( '/' );
			_activeMenu = _activeMenu.replace(/,/g, '/').slice(2);
		
			return(
			<div className="bg-light border-right" id="sidebar-wrapper">
				<div id="sidebar-minified" >
					<ul>
						<li>
							<a href="javascript:;" >
								<i className="fa fa-home"></i>
								Home
							</a>
						</li>
						<li>
							<a href="javascript:;" >
								<i className="fa fa-gift"></i>
								Product
							</a>
						</li>
						<li>
							<a href="javascript:;" >
								<i className="fa fa-book"></i>
								Billing
							</a>
						</li>
						<li>
							<a href="javascript:;" >
								<i className="fa fa-sitemap"></i>
								Manage
							</a>
						</li>
						<li>
							<a href="javascript:;" >
								<i className="fa fa-building-o"></i>
								Purchase
							</a>
						</li>
						<li>
							<a href="javascript:;" >
								<i className="fa fa-bar-chart"></i>
								Reports
							</a>
						</li>
					</ul>
				</div>
				<div id="sidebar-content">
					<ul>
						<li>Purchase Entry</li>
						<li>Purchase Report</li>
						<li>Purchase Payment</li>
						<li>Pending Payments</li>
					</ul>
				</div>
			</div>);

	}
	
}
export default Sidebar;


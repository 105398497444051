import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {appName,copyRight} from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import {notify} from 'react-notify-toast';
import {Helmet} from "react-helmet";
import {Nav,Navbar,NavDropdown,Row,Col,Table,Modal,DropdownButton, Dropdown, Card} from 'react-bootstrap';
import Toggle from 'react-bootstrap-toggle';
import "react-bootstrap-toggle/dist/bootstrap2-toggle.css";
import Roles from '../../utils/Roles';
import swal from 'sweetalert';
import Pagination from "react-js-pagination";

import PageTitle from '../../containers/PageTitle';

class UsersMgt extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginId: Sanitize.getData('loginUser_id'),
			companyId: Sanitize.getData('labUser_clientid'),
			masterId: Sanitize.getData('labUser_masterid'),
			saveText: 'Update Password',
			isRedirect: false,
			dataList: [],
			dataView: [],
		}
		
	}
	
	componentDidMount(){
		
		this.loadProfile();
	}

		
	loadProfile(){
		this.setState({showLoader:true});
		var token = Reqst.TimeStamp();
		
		Reqst.axGet('userrole/listusers/'+this.state.masterId+'/'+this.state.companyId+'/'+this.state.loginId+'/'+token).then(res=>{
			if(res['data'].status=='1')
			{
				this.setState({ dataList: res['data'].data, dataView: res['data'].data, showLoader:false});	
			}
			else
			{
				notify.show(res['data'].msg,'error');
				this.setState({dataList: [], dataView: [],showLoader:false});	
			}
		});
	}
	
	handleDelete(Id,e){
		try{
			if(!window.confirm('Do you want to delete?'))
				return false;
			
			this.setState({showLoader:true});
			var d = this.state.dataList;
			var index = d.findIndex(function(obj){
				return (obj.id==Id);
			});
			
			var form = new FormData();
			form.append('i__master', this.state.masterId);
			form.append('i__company', this.state.companyId);
			form.append('i__lid', this.state.loginId);
			form.append('i__id', Id);
			var data = form;
			Reqst.axPost('userrole/deleteuser/', data).then(res=>{
				if(res['data'].status=='1')
				{
					d.splice(index,1);
					this.setState({dataList:d, dataView:d, showLoader: false});
					notify.show(res['data'].msg,'success');
				}
				else
				{
					this.setState({showLoader:false});
					notify.show(res['data'].msg,'error');
				}
			});
		}
		catch(error){
			this.setState({showLoader:false});
		}		
	}

	render(){
		
		let sno = 1;
		
		return(
			<div>
			
				<Helmet>
					<title>{appName} | User Management</title>
				</Helmet>
				
				<PageTitle title="User Management" loading={this.state.showLoader} > 
					<div className="text-right" >
					{Roles.Can('manage','manageusers','canadd') ?
						<Link to="/manage/manageusers/add" className="btn btn-primary btn-sm" >
							<i className="fa fa-plus"></i>&nbsp;&nbsp;Add User
					</Link> : null}
					
					</div>
				</PageTitle>
			
				<Row>
					<Col md={12} >
							
						<Table hover stripped >
							<thead>
								<tr>
									<th width="60" >S.No</th>
									<th>Username</th>
									<th>Role</th>
									<th>Last login</th>
									<th>Last Ip</th>
									<th>Status</th>
									<th width="100" >Manage</th>
								</tr>
							</thead>
							<tbody>
							{this.state.dataView.map((item,i)=>{
								return <tr>
									<td>{sno++}</td>
									<td>{item.username}</td>
									<td>{item.role}</td>
									<td>{item.lastlogin}</td>
									<td>{item.lastip}</td>
									<td>{Sanitize.getStatus(item.activestatus)}</td>
									<td align="center" >
										{Roles.Can('manage','manageusers','canedit') ? <Link  className="btn btn-warning btn-sm" to={"/manage/manageusers/edit/"+item.id+'/'+Sanitize.random_value(6)} >
											<i className="fa fa-edit"></i>
										</Link> : null} &nbsp;
										{Roles.Can('manage','manageusers','candelete') ? <button type="button" className="btn btn-danger btn-sm" onClick={this.handleDelete.bind(this, item.id)} >
											<i className="fa fa-remove"></i>
										</button> : null}
									</td>
								</tr>;
								
							})
							}
							</tbody>
						</Table>
							
					</Col>
				</Row>
				
			</div>
		);
	}
	
}
export default UsersMgt;
import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {appName,copyRight} from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import {notify} from 'react-notify-toast';
import {Helmet} from "react-helmet";
import {Nav,Navbar,NavDropdown,Row,Col,Table,Modal,DropdownButton, Dropdown} from 'react-bootstrap';
import Toggle from 'react-bootstrap-toggle';
import "react-bootstrap-toggle/dist/bootstrap2-toggle.css";

import PageTitle from '../../containers/PageTitle';
import EditRoles from './EditRoles';

class ManageRoles extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginId: Sanitize.getData('loginUser_id'),
			companyId: Sanitize.getData('labUser_clientid'),
			masterId: Sanitize.getData('labUser_masterid'),
			loginText: 'LOGIN',
			isRedirect: false,
			showAddModal: false,
			showEditModal: false,
			dataList: [],
			dataView: [],
			editData: [],
		}
		
		this.handleSearch = this.handleSearch.bind(this);
		this.loadGroupMasters = this.loadGroupMasters.bind(this);
	}
	
	componentDidMount(){
		
		this.loadGroupMasters();
	}
	
	loadGroupMasters(){
		this.setState({showLoader:true});
		var token = Reqst.TimeStamp();
		Reqst.axGet('groupmaster/getgrouplist/'+this.state.masterId+'/'+this.state.companyId+'/'+token).then(res=>{
			if(res['data'].status=='1')
			{
				this.setState({dataList: res['data'].data, dataView: res['data'].data, showLoader:false});	
			}
			else
			{
				notify.show(res['data'].msg,'error');
				this.setState({showLoader:false});	
			}
		});
	}
	
	handleSearch(e){
		try{
			
			var list = this.state.dataList;
			var v = e.target.value.toUpperCase();
			var l = list.filter(function(obj){
				const gname = obj.groupname.toUpperCase();
				const gcode = obj.groupcode.toUpperCase();
				return ((gname.indexOf(v)>-1) || (gcode.indexOf(v)>-1));
			});
			this.setState({dataView:l});
		}
		catch(error){
			console.log(error);
		}
	}
	
	handleEdit(index,e){
		var list = this.state.dataView;
		var l = list[index];
		this.setState({editData: l, showEditModal: true});
	}
	
	handleRemoveClick(id,e){
		
		if(!window.confirm('Do you want to Remove?')) return;
		
		this.setState({showLoader:true});
		var token = Reqst.TimeStamp();
		Reqst.axGet('groupmaster/removegroup/'+this.state.masterId+'/'+this.state.companyId+'/'+id+'/'+token).then(res=>{
			if(res['data'].status=='1')
			{
				var l = this.state.dataList;
				var index = l.findIndex(function(obj){
					return (obj.id==id);
				});
				l.splice(index,1);
				this.setState({dataList:l,dataView:l,  showLoader:false});
				notify.show(res['data'].msg,'success');
			}
			else
			{
				notify.show(res['data'].msg,'error');
				this.setState({showLoader:false});	
			}
		});
		
	}
	
	render(){
		
		let sno = 1;
		
		return(
			<div>
			
				<Helmet>
					<title>{appName} | Manage User Roles</title>
				</Helmet>
				
				<PageTitle title="Manage User Roles" loading={this.state.showLoader} > 
					<div className="text-right">
					{/*<button className="btn btn-primary btn-sm btn-primary_2" onClick={()=>{this.setState({showAddModal:true})}} ><i className="fa fa-plus"></i> 
						Add Group
					</button>*/}
					</div>
				</PageTitle>
				
				<Row style={{ marginBottom: 5 }} >
					<Col md={3} >
						<input type="text" className="form-control input-sm" placeholder="Search by [Code, Name]" onChange={this.handleSearch} />
					</Col>
					<Col md={9} >
						<div className="text-right" >
							
							<button className="btn btn-primary btn-sm" onClick={()=>{this.setState({showAddModal:true})}} >
								<i className="fa fa-plus"></i>  Add Group
							</button>
							&nbsp;
							<button className="btn btn-primary btn-sm" onClick={this.loadGroupMasters} >
								<i className="fa fa-refresh"></i> 
							</button>
						</div>
					</Col>
				</Row>
				
				<Row>
					
					<Col md={12} >
						
						<EditRoles />
						
					</Col>
				</Row>
				
				
				
			</div>
		);
	}
	
}
export default ManageRoles;
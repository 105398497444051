import React, { Component } from 'react';


class Sanitize {
	
	
	static random_value(length) {
	   var result           = '';
	   var characters       = '.ABCDEFGHIJ.KLMNOPQRSTUV.WXYZabcdefghijklmnopqrst.uvwxyz012.3456789.';
	   var charactersLength = characters.length;
	   for ( var i = 0; i < length; i++ ) {
		  result += characters.charAt(Math.floor(Math.random() * charactersLength));
	   }
	   return result;
	}

	static capitalizeFirstLetter(str) {
	  return str.charAt(0).toUpperCase() + str.slice(1);
	}
	
	static integerKeyPress(e){
		if (e.which != 8 && (e.which < 48 || e.which > 57) ) {
			//showAdvice(this, "Integer values only");
			e.preventDefault();
			return false;
		}
	}
	
	static integerIndMobile(e){
		let len = e.target.value.length;
		if(len>=10){
			e.preventDefault();
			return false;
		}
		
		if (e.which != 8 && (e.which < 48 || e.which > 57) ) {
			//showAdvice(this, "Integer values only");
			e.preventDefault();
			return false;
		}
	}
	
	static decimalKeyPress(e){
		if ( e.which != 8 && e.which != 46 && (e.which < 48 || e.which > 57)) {
			//showAdvice(this, "Decimal numbers only");
			e.preventDefault();
			return false;
		}
		if (e.which == 46 && e.target.value.indexOf(".") != -1) {
		   // showAdvice(this, "Only one period allowed in decimal numbers");
		   e.preventDefault();
			return false;   // only one decimal allowed
		}
	}
	
	static lettersOnly(e){
		var inputValue = e.which;
        // allow letters and whitespaces only.
        if(e.which != 8 && !(inputValue >= 65 && inputValue <= 122) && (inputValue != 32 && inputValue != 0)) { 
            e.preventDefault(); 
			return(false);
        }
	}
	
	static dmy(d){
		if(d=='0000-00-00' || d==null || d=='') return '';
		var today = new Date(d);
		var date = today.getDate()+'/'+(today.getMonth()+1)+'/'+today.getFullYear();
		return date;
	}
	
	static getAge(d,age=''){
		if(d=='0000-00-00' || d==null || d=='') return age;
		var today = new Date(d);
		var date = today.getDate()+'/'+(today.getMonth()+1)+'/'+today.getFullYear();
		var ageDifMs = Date.now() - today.getTime();
		var ageDate = new Date(ageDifMs); // miliseconds from epoch
		return Math.abs(ageDate.getUTCFullYear() - 1970);
		//return date;
	}
	
	
	static dmyDash(d){
		if(d=='0000-00-00' || d==null || d=='') return '';
		var today = new Date(d);
		var date = today.getDate()+'-'+(today.getMonth()+1)+'-'+today.getFullYear();
		return date;
	}
	
	static today(){
		var today = new Date();
		var dd = today.getDate();
		var mm = today.getMonth() + 1; //January is 0!
		var yyyy = today.getFullYear();

		if (dd < 10) {
		  dd = '0' + dd;
		}

		if (mm < 10) {
		  mm = '0' + mm;
		}

		return yyyy+'-'+mm+'-'+dd;
	}
	
	static urlReplace(v){
		return (v.replace(new RegExp(' ', 'g'),'-').toLowerCase());
	}
	
	static getIndex(value, arr, prop) {
		for(var i = 0; i < arr.length; i++) {
			if(arr[i][prop] === value) {
				return i;
			}
		}
		return -1; //to handle the case where the value doesn't exist
	}
	
	static inArray(value, arr){

		var found = arr.find(function(element) {
		  return element.id == value;
		});

		return (found) ? true : false;
	}
	
	static setStorage(name,value){
		localStorage.setItem(name, value);
	}
	
	static getStoreageItem(item){
		return localStorage.getItem(item);
	}	
	
	static convertNumberToWords(amount) {
		var words = new Array();
		words[0] = '';
		words[1] = 'One';
		words[2] = 'Two';
		words[3] = 'Three';
		words[4] = 'Four';
		words[5] = 'Five';
		words[6] = 'Six';
		words[7] = 'Seven';
		words[8] = 'Eight';
		words[9] = 'Nine';
		words[10] = 'Ten';
		words[11] = 'Eleven';
		words[12] = 'Twelve';
		words[13] = 'Thirteen';
		words[14] = 'Fourteen';
		words[15] = 'Fifteen';
		words[16] = 'Sixteen';
		words[17] = 'Seventeen';
		words[18] = 'Eighteen';
		words[19] = 'Nineteen';
		words[20] = 'Twenty';
		words[30] = 'Thirty';
		words[40] = 'Forty';
		words[50] = 'Fifty';
		words[60] = 'Sixty';
		words[70] = 'Seventy';
		words[80] = 'Eighty';
		words[90] = 'Ninety';
		amount = amount.toString();
		var atemp = amount.split(".");
		var number = atemp[0].split(",").join("");
		var n_length = number.length;
		var words_string = "";
		if (n_length <= 9) {
			var n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
			var received_n_array = new Array();
			for (var i = 0; i < n_length; i++) {
				received_n_array[i] = number.substr(i, 1);
			}
			for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
				n_array[i] = received_n_array[j];
			}
			for (var i = 0, j = 1; i < 9; i++, j++) {
				if (i == 0 || i == 2 || i == 4 || i == 7) {
					if (n_array[i] == 1) {
						n_array[j] = 10 + parseInt(n_array[j]);
						n_array[i] = 0;
					}
				}
			}
			var value = "";
			for (var i = 0; i < 9; i++) {
				if (i == 0 || i == 2 || i == 4 || i == 7) {
					value = n_array[i] * 10;
				} else {
					value = n_array[i];
				}
				if (value != 0) {
					words_string += words[value] + " ";
				}
				if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
					words_string += "Crores ";
				}
				if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
					words_string += "Lakhs ";
				}
				if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
					words_string += "Thousand ";
				}
				if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
					words_string += "Hundred and ";
				} else if (i == 6 && value != 0) {
					words_string += "Hundred ";
				}
			}
			words_string = words_string.split("  ").join(" ");
		}
		return words_string;
	}
	
	static objectifyForm(formArray) {//serialize data function

	  var returnArray = {};
	  for (var i = 0; i < formArray.length; i++){
		returnArray[formArray[i]['name']] = formArray[i]['value'];
	  }
	  return returnArray;
	}
	
	
	static setData(data,prefix=""){
		
		for(var name in data){
			var storeName = prefix+"_"+name;
			localStorage.setItem(storeName, data[name]);
		}		
	}
	
	static getData(name){
		
		return localStorage.getItem(name);
	}
	
	static getStatus(sta){
		if(sta=='1') return  <span className="badge badge-success" style={{ fontSize: 12 }} >Active</span>;
		else if(sta=='0') return  <span className="badge badge-danger" style={{ fontSize:12 }} >In Active</span>;
	}
	
	/** Group by data using multiple column **/
	static groupByMultiple( array , f )
	{
		  var groups = {};
		  array.forEach( function( o )
		  {
			var group = JSON.stringify( f(o) );
			groups[group] = groups[group] || [];
			groups[group].push( o );  
		  });
		  return Object.keys(groups).map( function( group )
		  {
			return groups[group]; 
		  });
	}
	
	static formatAMPM(date,withAm=true) {
		try{
			
			  var hours = date.getHours();
			  var minutes = date.getMinutes();
			  var ampm = hours >= 12 ? 'PM' : 'AM';
			  hours = hours % 12;
			  hours = hours ? hours : 12; // the hour '0' should be '12'
			  hours = hours < 10 ? '0'+hours : hours;
			  minutes = minutes < 10 ? '0'+minutes : minutes;
			  var strTime = withAm ? hours + ':' + minutes + ' ' + ampm : hours + ':' + minutes;
			  return strTime;
		}
		catch(error){
			return date;
		}
	}
	
	static calculageAmountFromPercentage(netAmount,percenTage)
	{
		try{
			
			return ((parseFloat(netAmount)/100)* parseFloat(percenTage));
		}
		catch(error){
			return '0';
		}
	}
	
	static isBold(test){
		try{
			
			var condition = test.referencecondition;
			var from = test.referencefrom;
			var to = test.referenceto;
			var result = test.result;
			
			if(test.gender=='Female' && test.femalerange !='')
			{
				condition = test.femalecondition;
				from = test.femalefrom;
				to = test.femaleto;
			}
			
			if(condition=='Between')
			{
				return ((parseFloat(result) < parseFloat(from)) || (parseFloat(result) > parseFloat(to)));
			}
			else if(condition=='Less_Than')
			{
				return (parseFloat(result) < parseFloat(from));
			}
			else if(condition=='Greater_Than')
			{
				return (parseFloat(result) > parseFloat(from));
			}
			else if(condition=='Equal')
			{
				return (parseFloat(result) != parseFloat(from));
			}
			
			return false;
		}
		catch(error){
			return false;
		}
	}

}
export default Sanitize;
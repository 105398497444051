import React, { Component } from 'react';
import {Redirect,Link} from 'react-router-dom';
import $ from 'jquery';
import {appName,copyRight} from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import Notifications, {notify} from 'react-notify-toast';
import {Helmet} from 'react-helmet';
import {Row,Col,Card} from 'react-bootstrap';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';

class ProfileArticles extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginText: 'Login',
			isRedirect: false,
			showLoader: false,
			username: this.props.match.params.username,
		}
	}
	

	render(){
		
	
		return(
			<div style={{ padding: 50 }} >
				
				<h3>Articles  of {this.state.username} </h3>
				<p>
					<Link to={"/"+this.state.username} > Go to Profile </Link> &emsp;
					<Link to={"/"+this.state.username+"/articles/article-one-from-subbu"} > View Article </Link>
				</p>
			</div>
		);
	}
	
}
export default ProfileArticles;
import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {appName,copyRight} from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import Notifications, {notify} from 'react-notify-toast';
import {Helmet} from 'react-helmet';
import {Row,Col,Card} from 'react-bootstrap';
import swal from 'sweetalert';

import PageTitle from '../../containers/PageTitle';
import MICROSCOPE from '../../assets/img/microscope.png';


const styles = {
	firstDiv: {
		 backgroundImage: 'linear-gradient(#117a92, #095369)',
		 padding: 15,
		 margin:0, 
		 minHeight: 300		
	},
	loginTitle: {
	    borderBottom: '1px solid #e0dcdc',
		paddingBottom: '7px',
		width: '95%',
	}
}

class NotFound extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginText: 'Login',
			isRedirect: false,
			showLoader: false,
		}
		
		
	}
	
	

	render(){
		
		
		
		return(
			<div>
				
				<Helmet>
					<title>{appName} | (404) Page Not Found</title>
				</Helmet>
								
				<PageTitle title="Not Found" loading={this.state.showLoader} > 
					<div className="text-right">
						
					</div>
				</PageTitle>
				
				<div className="container" style={{ marginTop: 20 }} >
					<Row>
						<Col md={12} >
							<div className="text-center" >
								<h3>The Requested Page not found..!
									<br/><br/>
									(OR)
									<br /><br/>
									Its Removed from the application..!</h3>
							</div>
						</Col>
					</Row>
				</div>
				
			</div>
		);
	}
	
}
export default NotFound;
import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {copyRight} from '../../../utils/Variables';
import * as Reqst from '../../../utils/Reqst';
import Sanitize from '../../../utils/Sanitize';
import {notify} from 'react-notify-toast';
import {Nav,Navbar,NavDropdown,Row,Col,Table,Modal} from 'react-bootstrap';

class Edit extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginId: Sanitize.getData('loginUser_id'),
			companyId: Sanitize.getData('labUser_clientid'),
			masterId: Sanitize.getData('labUser_masterid'),
			saveText: 'UPDATE GROUP',
			isRedirect: false,
			showLoader: false,
			editData: this.props.editData,
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	componentDidMount(){
		var s = Reqst.TimeStamp();
		console.log(s);
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		this.setState({showLoader:true,saveText:'Updating..'});
		var formData = Sanitize.objectifyForm($("#frmAdd").serializeArray());
		Reqst.axPost('groupmaster/updategroup',{token: Reqst.Token(formData)}).then(res=>{
			if(res['data'].status=='1')
			{
				notify.show(res['data'].msg,'success');
				this.setState({showLoader:false,saveText:'UPDATE GROUP'});
				if(this.props.afterFinish) this.props.afterFinish();
			}
			else
			{
				notify.show(res['data'].msg,'error');
				this.setState({showLoader:false,saveText:'UPDATE GROUP'});
			}
		});
	}
	
	getData(field){
		var l = this.state.editData;
		return l[field];
	}
	
	setData(field,e){
		var l = this.state.editData;
		l[field] = e.target.value;
		this.setState({editData: l });
	}
	
	render(){
		
		return(
			<div>			
				<form action="" method="post" encType="multipart/form-data" id="frmAdd" onSubmit={this.handleFormSubmit} >
					<input type="hidden" name="i__id" value={this.getData('id')} />
					<input type="hidden" name="i__lid" value={this.state.loginId} />
					<input type="hidden" name="i__company" value={this.state.companyId} />
					<input type="hidden" name="i__master" value={this.state.masterId} />
					<Row>
						<Col md={12} >
							<label>Group Code <span className="text-danger">*</span></label>
							<input type="text" name="i__code" id="i__code" className="form-control input-sm" required value={this.getData('groupcode')} onChange={this.setData.bind(this,'groupcode')} />
						</Col>
					</Row>
					<Row>
						<Col md={12} >
							<label>Group Name <span className="text-danger">*</span></label>
							<input type="text" name="i__name" id="i__name" className="form-control input-sm" required value={this.getData('groupname')} onChange={this.setData.bind(this,'groupname')} />
						</Col>
					</Row>
					<Row>
						<Col md={12} >
							<label>Active Status <span className="text-danger">*</span></label>
							<select name="i__status" id="i__status" className="form-control input-sm" required >
								<option value="1" selected={this.getData('groupstatus')=='1' ? 'selected' : ''} > Active </option>
								<option value="0" selected={this.getData('groupstatus')=='0' ? 'selected' : ''}  > In Active </option>
							</select>
						</Col>
					</Row>
					<Row>
						<Col md={12} >
							<br />
							<div className="text-right" >
								<button type="submit" className="btn btn-primary btn-sm btn-success_2" disabled={this.state.saveText!='UPDATE GROUP'} >
									<i className="fa fa-check"></i> {this.state.saveText} 
								</button>
							</div>
						</Col>
					</Row>
				</form>
			</div>
		);
	}
	
}
export default Edit;
import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {appName,copyRight} from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import {notify} from 'react-notify-toast';
import {Helmet} from "react-helmet";
import {Nav,Navbar,NavDropdown,Row,Col,Table,Modal,DropdownButton, Dropdown, Card} from 'react-bootstrap';
import domtoimage from 'dom-to-image';

import WihtoutHeader from '../Reports/Print/WihtoutHeader';

import LOGO from '../../assets/img/lablogo.png';


class Download extends Component{
	constructor(props){
		super(props);
		this.state = {
			isRedirect: false,
			saveText: 'Download Now',
			showReport:false,
			dataList: [],
			dataView: [],
		}
		this.handleSubmit = this.handleSubmit.bind(this);
		this.saveFile = this.saveFile.bind(this);
	}
	
	componentDidMount(){
		
	}
	
	saveFile(){
		try{
			
			var h = document.getElementById("my-node").style.height;
			document.getElementById("my-node").style.height = "600px";
			
			domtoimage.toJpeg(document.getElementById('my-node'), { quality: 0.95 })
			.then(function (dataUrl) {
				var link = document.createElement('a');
				link.download = 'report.jpeg';
				link.href = dataUrl;
				link.click();
				document.getElementById("my-node").style.height = h;
			});
		}
		catch(error){
			alert('Error in download the report');
		}
	}
	
	handleSubmit(e){
		try{
			e.preventDefault();
			this.setState({saveText:'Please wait...'});
			Reqst.axPost('/download/report/',$("#frmDownload").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					this.setState({dataList:res['data'].data, saveText:'Download Now', showReport:true});
				}
				else
				{
					alert('Invalid Report Id');
					this.setState({saveText:'Download Now'});
				}
			});			
		}
		catch(error){
			alert('Error in Download Report');
			this.setState({saveText:'Download Now'});
		}
	}

	render(){
		
		let sno = 1;
		
		return(
			<div>
			
				<Helmet>
					<title>{appName} | Download Report</title>
				</Helmet>
				
				 <Navbar bg="dark" style={{ marginTop: '-81px' }} >
					<Navbar.Brand href="/#/download">
						<img src={LOGO} style={{ width: '140px' }}  /> 
					</Navbar.Brand>
				  </Navbar>
				  
				  <div className="container" style={{ marginTop: '30px' }} >
				  
				  {this.state.showReport ? 
					<Row>
						<Col md={{offset:'2',span:'8'}} >
							<div id="my-node" style={{ backgroundColor:'#fff', padding: '15px'  }} >
								<WihtoutHeader resultData={this.state.dataList} printButton />
							</div>
						</Col>
						<Col md={{offset:'2',span:'8'}} >
							<div className="text-center" >
								<hr />
								<button type="button" onClick={this.saveFile} className="btn btn-info">
									Download as Image
								</button>
								&nbsp;
								<button type="button" onClick={()=>{ this.setState({showReport:false})}} className="btn btn-danger">
									Reset
								</button>
							</div>
						</Col>
					</Row>
				  :
					  <Row>
						<Col md={{offset:'3',span:'6'}} >
					  
							<Card >
								<Card.Header>Download Report</Card.Header>
								<Card.Body>
									<form action="" method="post" encType="multipart/form-data" id="frmDownload" onSubmit={this.handleSubmit} >
										
										<Row style={{ marginBottom: '15px' }} >
											<Col md={4} >
												<label style={{fontSize:'14px',marginTop:'6px', fontWeight:'500'}} >Report Id <span className="text-danger">*</span> </label>
											</Col>
											<Col md={8} >
												<input type="text" name="i__reportno" id="i__reportno" className="form-control" required autoComplete="off" style={{ textTransform:'uppercase'}} />
											</Col>	
										</Row>
										
										<Row style={{ marginBottom: '15px' }} >
											<Col md={4} >
												<label style={{fontSize:'14px',marginTop:'6px', fontWeight:'500'}} >Register Mobile No <span className="text-danger">*</span> </label>
											</Col>
											<Col md={8} >
												<input type="number" name="i__mobile" id="i__mobile" className="form-control" required autoComplete="off" style={{ textTransform:'uppercase'}} />
											</Col>	
										</Row>
										
										<Row style={{ marginBottom: '15px' }} >
											<Col md={12} >
												<div className="text-center" >
													<hr />
													<button type="submit" className="btn btn-success btn-lg" disabled={this.state.saveText!='Download Now'} >
														{this.state.saveText}
													</button>
													
												</div>
											</Col>
										</Row>
										
									</form>
								</Card.Body>
							</Card>
							
						</Col>
					  </Row>
				  }
					  
				  </div>				  
				
			</div>
		);
	}
	
}
export default Download;
import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {appName,copyRight,imagePath} from '../../../utils/Variables';
import * as Reqst from '../../../utils/Reqst';
import Sanitize from '../../../utils/Sanitize';
import {notify} from 'react-notify-toast';
import {Helmet} from "react-helmet";
import {Nav,Navbar,NavDropdown,Row,Col,Table,Modal,DropdownButton, Dropdown} from 'react-bootstrap';
import Toggle from 'react-bootstrap-toggle';
import "react-bootstrap-toggle/dist/bootstrap2-toggle.css";
import Roles from '../../../utils/Roles';
import swal from 'sweetalert';
import Pagination from "react-js-pagination";
import NOIMAGE from '../../../assets/img/noimage.jpg';

import PageTitle from '../../../containers/PageTitle';
import Add from './Add';
import Edit from './Edit';

class PatientMaster extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginId: Sanitize.getData('loginUser_id'),
			companyId: Sanitize.getData('labUser_clientid'),
			masterId: Sanitize.getData('labUser_masterid'),
			loginText: 'LOGIN',
			isRedirect: false,
			showAddModal: false,
			showEditModal: false,
			currentPage: 1,
			todosPerPage: 10,
			activePage:1,
			dataList: [],
			dataView: [],
			editData: [],
			displayWithImage: false,
		}
		
		this.handleSearch = this.handleSearch.bind(this);
		this.loadTestMasters = this.loadTestMasters.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
	}
	
	componentDidMount(){
		
		this.loadTestMasters();
	}
	
	loadTestMasters(){
		this.setState({showLoader:true});
		var token = Reqst.TimeStamp();
		Reqst.axGet('patientmaster/getpatientslist/'+this.state.masterId+'/'+this.state.companyId+'/'+token).then(res=>{
			if(res['data'].status=='1')
			{
				this.setState({dataList: res['data'].data, dataView: res['data'].data, showLoader:false});	
			}
			else
			{
				notify.show(res['data'].msg,'error');
				this.setState({showLoader:false});	
			}
		});
	}
	
	handleSearch(e){
		try{
			
			var list = this.state.dataList;
			var v = e.target.value.toUpperCase();
			var l = list.filter(function(obj){
				const gname = obj.patientcode.toUpperCase();
				const gcode = obj.patientname.toUpperCase();
				const gmob = obj.mobile.toUpperCase();
				const gplace = obj.patientplace.toUpperCase();
				return ((gname.indexOf(v)>-1) || (gcode.indexOf(v)>-1) || (gmob.indexOf(v)>-1) || (gplace.indexOf(v)>-1));
			});
			this.setState({dataView:l});
		}
		catch(error){
			console.log(error);
		}
	}
	
	handleEdit(id,e){
		var list = this.state.dataView;
		var index = list.findIndex(function(obj){ return obj.id==id });
		var l = list[index];
		this.setState({editData: l, showEditModal: true});
	}
	
	removeValue(id,e){
		this.setState({showLoader:true});
		var token = Reqst.TimeStamp();
		Reqst.axGet('patientmaster/removeprofile/'+this.state.masterId+'/'+this.state.companyId+'/'+id+'/'+token).then(res=>{
			if(res['data'].status=='1')
			{
				var l = this.state.dataList;
				var index = l.findIndex(function(obj){
					return (obj.id==id);
				});
				l.splice(index,1);
				this.setState({dataList:l,dataView:l,  showLoader:false});
				notify.show(res['data'].msg,'success');
			}
			else
			{
				notify.show(res['data'].msg,'error');
				this.setState({showLoader:false});	
			}
		});
	}
	
	handleRemoveClick(id,e){
		
		swal({title:'Confirm',text:'Do you want to Delete?',buttons:['No','Yes']}).then((willYes)=>{
			if(willYes) this.removeValue(id,e);
		});
				
	}
	
	 handlePageChange(pageNumber) {
	
		this.setState({activePage: pageNumber});
		this.state.currentPage = Number(pageNumber);
	 }
	 
	 getImageUrl(img){
		 console.log(img);
		 return (img.length>5) ? imagePath.patientImage+img : NOIMAGE;
	 }
	  
	render(){
		
		 const indexOfLastTodo = this.state.currentPage * this.state.todosPerPage;
		 const indexOfFirstTodo = indexOfLastTodo - this.state.todosPerPage;
		 const currentTodos = this.state.dataView.slice(indexOfFirstTodo, indexOfLastTodo);
		 let sno = indexOfFirstTodo + 1;
	
		return(
			<div>
			
				<Helmet>
					<title>{appName} | Patients Master</title>
				</Helmet>
				
				<PageTitle title="Patients Master" loading={this.state.showLoader} > 
					<div className="text-right">
					{/*<button className="btn btn-primary btn-sm btn-primary_2" onClick={()=>{this.setState({showAddModal:true})}} ><i className="fa fa-plus"></i> 
						Add Group
					</button>*/}
							{Roles.Can('patients','patients','canadd') ?
								<button className="btn btn-primary btn-sm" onClick={()=>{this.setState({showAddModal:true})}} >
								<i className="fa fa-plus"></i>  Add Patient
							</button> : null }
							
							&nbsp;
							<button className="btn btn-primary btn-sm" onClick={this.loadTestMasters} >
								<i className="fa fa-refresh"></i> 
							</button>
					</div>
				</PageTitle>
				
				<Row style={{ marginBottom: 5 }} >
					<Col md={3} >
						<input type="text" className="form-control input-sm" placeholder="Search by [Code, Name, Mobile, Place]" onChange={this.handleSearch} />
					</Col>
					<Col md={2} >
						<label for="i__withimage" style={{ marginTop: 4 }} >
							<input type="checkbox" id="i__withimage" onChange={()=>{this.setState({displayWithImage: !this.state.displayWithImage})}} /> 
							Display with Profile Image
						</label>
					</Col>
					<Col md={7} >
						{/*<div className="text-right" >
							{Roles.Can('manage','testmaster','canadd') ?
								<button className="btn btn-primary btn-sm" onClick={()=>{this.setState({showAddModal:true})}} >
								<i className="fa fa-plus"></i>  Add Test
							</button> : null }
							
							&nbsp;
							<button className="btn btn-primary btn-sm" onClick={this.loadTestMasters} >
								<i className="fa fa-refresh"></i> 
							</button>
						</div>*/}
					</Col>
				</Row>
				
				<Row>
					
					<Col md={12} >

						<Table hover responsive className="list-table" >
							<thead>
								<tr>
									<th width="60" >Sl.No</th>
									<th>Type</th>
									<th>Patient ID</th>
									<th>Patient Name</th>
									<th>Age/Sex</th>
									<th>DOB</th>
									<th width="70" >Mobile</th>
									<th>Place</th>
									<th>Whatsapp</th>
									<th>Status</th>
									<th width="80" >Manage</th>
								</tr>
							</thead>
							<tbody>
							{Object.keys(currentTodos).length>0 ?
								currentTodos.map((item,key)=>{
									return <tr key={key} >
										<td>{sno++}</td>
										<td align="center" >
											{this.state.displayWithImage ? <img src={this.getImageUrl(item.patientpicture)} style={{width:55, border:'1px solid #909090'}} /> : null}
											{this.state.displayWithImage ? <br /> : null} <b>{item.patienttype}</b>
										</td>
										<td>{item.patientcode}</td>
										<td>{item.patientname}</td>
										<td>{Sanitize.getAge(item.dob,item.age)} / {item.gender} </td>
										<td>{Sanitize.dmy(item.dob)}</td>
										<td>{item.mobile}</td>
										<td>{item.place}</td>
										<td>{item.whatsapp}</td>
										<td align="center" >{Sanitize.getStatus(item.patientstatus)}</td>
										<td align="center" >
										{Roles.Can('patients','patients','canedit') ?
											<button className="btn btn-sm btn-warning btn-lowpadding" onClick={this.handleEdit.bind(this,item.id)} >
												<i className="fa fa-edit"></i>
											</button> : null }
											&emsp;
										{Roles.Can('patients','patients','candelete') ?
											<button className="btn btn-sm btn-danger btn-lowpadding" onClick={this.handleRemoveClick.bind(this,item.id)} >
												<i className="fa fa-remove"></i>
											</button> : null }
										</td>
									</tr>;
								})
							: <tr>
								<td colSpan="11" align="center" >
									<br />
									No data found..!
								</td>
							</tr>
							}
							</tbody>
						</Table>
					</Col>
				</Row>
				
				<Row>
					<Col md={1} >
						<select className="form-control input-sm" onChange={(e)=>{this.setState({activePage:1,currentPage:Number(1), todosPerPage: e.target.value})}} >
							<option value="10"> 10 </option>
							<option value="15"> 15 </option>
							<option value="20"> 20 </option>
							<option value="50"> 50 </option>
							<option value="100"> 100 </option>
						</select>
					</Col>
					<Col md={10} >
						 <Pagination
						  activePage={this.state.activePage}
						  itemsCountPerPage={this.state.todosPerPage}
						  totalItemsCount={(this.state.dataView).length}
						  pageRangeDisplayed={7}
						  onChange={(pN)=>{this.setState({activePage:pN, currentPage: Number(pN)})}}
						/>
					</Col>
				</Row>
				
				<Modal size="lg" show={this.state.showAddModal} onHide={()=>{this.setState({showAddModal:false})}} className="" >
					<Modal.Header closeButton >
						<Modal.Title>Add Patient</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Add afterFinish={this.loadTestMasters} />
					</Modal.Body>
				</Modal>
				
				<Modal size="lg" show={this.state.showEditModal} onHide={()=>{this.setState({showEditModal:false})}} className="" >
					<Modal.Header closeButton >
						<Modal.Title>Edit Test</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Edit editData={this.state.editData} afterFinish={this.loadTestMasters} />
					</Modal.Body>
				</Modal>
				
			</div>
		);
	}
	
}
export default PatientMaster;
import React, { Component } from 'react';
import {Link, Redirect} from 'react-router-dom';


const goBackStyle = {
    fontSize: '21px',
    float: 'left',
    color: '#5f5d5d',
    marginTop: '0px',
    marginLeft: '0px',
	cursor: 'pointer'
}

const styles = {
	loaderContainer: {
	    position: 'absolute',
		width: '100%',
		marginTop: '-20px',
		right: '-610px',
	}
}

class PageTitle extends Component {
	
	constructor(props){
		super(props);
		this.state = {
			backId: this.props.goback,
			tiTle: this.props.title,
			isRedirect: false
		}
		this.goBackClick = this.goBackClick.bind(this);
	}
	
	goBackClick(){
		
		//this.props.history.goBack();
		//alert(this.state.backId);
		(this.state.backId) ? this.setState({isRedirect:true}) : window.history.back();
	}
	
  render() {
	  if(this.state.isRedirect)
	  {
		  return( <Redirect to ={this.state.backId} /> );
	  }
	  else
	  {
		return (
		<div className="page-header" >
			{this.props.loading ? <div style={styles.loaderContainer} > 
						<div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
					</div> : ''}
			<div className="row">
				<div className="col-md-3" >
					<h5 className="title">&nbsp;{this.state.tiTle}</h5>
				</div>
				<div className="col-md-9" >
					{this.props.children}
				</div>
			</div>
		</div>
		);
	  }
  }
}

export default PageTitle;



import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {appName,copyRight} from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import Notifications, {notify} from 'react-notify-toast';
import {Helmet} from 'react-helmet';
import {Row,Col,Card} from 'react-bootstrap';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';

import PageTitle from '../../containers/PageTitle';
import MICROSCOPE from '../../assets/img/microscope.png';


const styles = {
	firstDiv: {
		 backgroundImage: 'linear-gradient(#117a92, #095369)',
		 padding: 15,
		 margin:0, 
		 minHeight: 300		
	},
	loginTitle: {
	    borderBottom: '1px solid #e0dcdc',
		paddingBottom: '7px',
		width: '95%',
	}
}

class Login extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginText: 'Login',
			isRedirect: false,
			showLoader: false,
		}
		
		this.handleLogin = this.handleLogin.bind(this);
	}
	
	
	handleLogin(e){
		try{
			
			e.preventDefault();
			this.setState({showLoader:true, loginText: 'Wait..'});
			Reqst.axPost('login/check/',$("#frmLogin").serialize()).then(res=>{
				if(res['data'].status=='1')
				{
					const cookie = new Cookies();
					cookie.set('lab_logged','yes');
					cookie.set('login_ip', res['data'].loginip);
					cookie.set('loginUser', res['data'].data);
					cookie.set('labUser', res['data'].lab);
					cookie.set('masterUser', res['data'].master);
					cookie.set('lab_userRoles', (res['data'].roles));
					cookie.set('lab_master_logged', 'no');
					
					if(res['data'].data['enable_master_panel']=='1')
					{
						cookie.set('lab_master_logged', 'yes');
						cookie.set('lab_master_logid',  res['data'].data['id']);
						cookie.set('lab_master_loguser',  res['data'].data['username']);
						cookie.set('lab_master_logpass',  res['data'].data['password']);
					}
					
					
					notify.show(res['data'].msg,'success');
					localStorage.setItem('lab_logged','yes');
					localStorage.setItem('login_ip', res['data'].loginip);
					Sanitize.setData(res['data'].data,'loginUser');
					Sanitize.setData(res['data'].lab,'labUser');
					Sanitize.setData(res['data'].master,'masterUser');
					window.localStorage.setItem('lab_userRoles', JSON.stringify(res['data'].roles));
					//this.setState({loginText: 'Login', isRedirect: true, showLoader: false});
					window.location.href = "";
				}
				else
				{
					notify.show(res['data'].msg,'error');
					this.setState({loginText: 'Login', showLoader: false});
				}
			});
		}
		catch(error){
			console.log(error);
		}
	}
	
	showAlert(){
		swal({
			title:'Message',
		text: 'Welcome..!',
		buttons:['No','Yes']}).then((willYes)=>{
			if(willYes){
				//alert('Ok');
			}
			else
			{
				//alert('No');
			}
				
		});
	}
	
	render(){
		
		const cookie = new Cookies();
		
		if(cookie.get('lab_logged')=='yes')
		{
			return (<Redirect to="/dashboard" />);
		}
		
		if(this.state.isRedirect)
		{
			return (<Redirect to="/dashboard" />);
		}
		
		if(this.state.toTest){
			
			return(<Redirect to={ {
			  pathname: '/test',
			  search: '?utm=techiediaries',
			  state: { message: 'Welcome to Test...' }
			} }/>);
		}
		
		return(
			<div>
				
				<Helmet>
					<title>{appName} | User Login</title>
				</Helmet>
				
				<Notifications />
				
				<div className="container" style={{ marginTop: 80 }} >
					<Row>
						
						<Col md={{span:6, offset:3}} >
							<Card style={{ borderRadius: 0 }} >
								<Card.Body style={{ padding: 0 }} >
									<Row className="login-row" >
										<Col md={6} style={styles.firstDiv} className="login-intro">
											<center>
												<img src={MICROSCOPE} style={{ width: 130 }}   />
												<br /><br />
											</center>
											<div style={{ color: '#fff' }} >
												Simplify Your Laboratory with LABSOFT's Laboratory Information System..
												<br /><br />
												<h5>Fast, Secure and Realtime </h5>
											</div>
										</Col>
										<Col md={6} style={{ padding: 15 }} >
											<h4 className="text-center" style={styles.loginTitle} >Login</h4>
											<form action="" method="post" encType="multipart/form-data" id="frmLogin" onSubmit={this.handleLogin} >
												<br />
												<Row style={{     padding: '0px 10px 0px 0px' }} >
													<Col md={12} >
														<div className="form-group">
															<div className="input-group">
																<div className="input-group-prepend">
																	<span className="input-group-text" id="basic-addon1" style={{ padding: '0px 8px' }} >
																		<i className="fa fa-user"></i>
																	</span>
																</div>
																<input type="text" className="form-control input-sm" required name="i__username" id="i__username" placeholder="Username" />
															</div>
														</div>
													</Col>
													<Col md={12} >
														<div className="form-group">
															<div className="input-group">
																<div className="input-group-prepend">
																	<span className="input-group-text" id="basic-addon1" style={{ padding: '0px 8px' }} >
																		<i className="fa fa-key"></i>
																	</span>
																</div>
																<input type="password" className="form-control input-sm" required name="i__password" id="i__password" placeholder="Password" />
															</div>
														</div>
													</Col>
													<Col md={12} >
														<div className="form-group">
															<a href="#">Forgot Password?</a>
															<div className="pull-right">
																<button type="submit" className="btn btn-primary btn-primary_2 btn-sm" value="Save" disabled={this.state.loginText!='Login'} >
																	{this.state.loginText!='Login' ? 
																	<i className="fa fa-spinner fa-spin"></i> : 
																	<i className="fa fa-check"></i>} {this.state.loginText}
																</button>
															</div>
														</div>
													</Col>
													{/*<Col md={12} >
														<button type="button" className="btn btn-warning" onClick={this.showAlert.bind(this)} >
															Show Confirm
														</button>	
													</Col>*/}
													<Col md={12} >
														<hr />
														<div className="text-center">
															{appName} {copyRight.version} &copy; {copyRight.name}
														</div>
													</Col>
												</Row>
											</form>
										</Col>
									</Row>
								</Card.Body>
							</Card>
						</Col>
					</Row>
					{/*<Row>
						<Col md={{span:6, offset:3}} style={{ marginTop: 15, paddingLeft:'0px' }} >
							<table className="table">
								<thead>
									<tr>
										<th>Role</th>
										<th>Username</th>
										<th>Password</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Admin</td>
										<td>peacelab</td>
										<td>12345</td>
									</tr>
									<tr>
										<td>Staff</td>
										<td>staff</td>
										<td>12345</td>
									</tr>
								</tbody>
							</table>
						</Col>
					</Row>*/}
				</div>
				
			</div>
		);
	}
	
}
export default Login;
import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import Sanitize from '../../utils/Sanitize';
import Notifications, {notify} from 'react-notify-toast';
import {Row, Col, Nav, Navbar, NavDropdown,Form, FormControl, Button } from 'react-bootstrap';

import './themedrive.css';

import Header from './header';
import Sidebar from './sidebar';
import Footer from './footer';

class DriveTheme extends Component{
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}
		
	}
	
	componentDidMount(){
		
	}


	render(){
	
		
		return(
			<div style={{ overflowX: 'hidden' }} className="theme-drive" >
				
				<Notifications options={{zIndex: 9999 }} />
				
				<Header />
					
				<Sidebar />
								
					<div id="content-wrapper" >
						
						{this.props.children}								
					
					</div>
								
				
				<Footer />
				
			</div>
		);
	}
	
}
export default DriveTheme;


import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {appName,copyRight} from '../../../utils/Variables';
import * as Reqst from '../../../utils/Reqst';
import Sanitize from '../../../utils/Sanitize';
import {notify} from 'react-notify-toast';
import {Helmet} from "react-helmet";
import {Nav,Navbar,NavDropdown,Row,Col,Table,Modal,DropdownButton, Dropdown, Card} from 'react-bootstrap';
import Toggle from 'react-bootstrap-toggle';
import "react-bootstrap-toggle/dist/bootstrap2-toggle.css";
import Roles from '../../../utils/Roles';
import swal from 'sweetalert';
import Pagination from "react-js-pagination";

import PageTitle from '../../../containers/PageTitle';

class MyProfile extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginId: Sanitize.getData('loginUser_id'),
			companyId: Sanitize.getData('labUser_clientid'),
			masterId: Sanitize.getData('labUser_masterid'),
			saveText: 'Update Password',
			isRedirect: false,
			dataList: [],
			dataView: [],
		}
		
		this.handlePasswordUpdate = this.handlePasswordUpdate.bind(this);
	}
	
	componentDidMount(){
		this.loadProfile();
	}

		
	loadProfile(){
		this.setState({showLoader:true});
		var token = Reqst.TimeStamp();
		
		Reqst.axGet('settings/getlogin/'+this.state.masterId+'/'+this.state.companyId+'/'+this.state.loginId+'/'+token).then(res=>{
			if(res['data'].status=='1')
			{
				this.setState({ dataView: res['data'].data, showLoader:false});	
			}
			else
			{
				notify.show(res['data'].msg,'error');
				this.setState({dataList: [], dataView: [],showLoader:false});	
			}
		});
	}
	
	getValue(field){
		try{
			
			var d = this.state.dataView;
			if(Object.keys(d).length>0)
			{
				return d[field] ? d[field] : '';
			}
			return '';
		}
		catch(error){}
	}
	
	handlePasswordUpdate(e){
		try{
			e.preventDefault();
			this.setState({saveText: 'Updating...', showLoader:true});
			Reqst.axPost('settings/updatepassword', $("#frmPass").serialize()).then(res=>{
				if(res['data'].status=='1'){

					document.getElementById("frmPass").reset();
					notify.show(res['data'].msg,'success');
					this.setState({saveText:'Update Password', showLoader:false});
				}
				else{
					notify.show(res['data'].msg,'error');
					this.setState({saveText:'Update Password', showLoader:false});
				}
			});
		}
		catch(error){ }
	}
	

	render(){
		
		
		
		return(
			<div>
			
				<Helmet>
					<title>{appName} | My Profile</title>
				</Helmet>
				
				<PageTitle title="My Profile" loading={this.state.showLoader} > 
					
				</PageTitle>
			
				<Row>
					
					<Col md={{ span: 8,offset: 2 }} >
						
						<Card>							
							<Card.Header>
								Profile
							</Card.Header>
							<Card.Body>
								<Row style={{ marginBottom: '15px' }} >
									<Col md={4} >
										<label>Username</label>
									</Col>
									<Col md={8} >
										<input type="text" className="form-control input-sm" readOnly value={this.getValue('username')} />
									</Col>
								</Row>
								<Row style={{ marginBottom: '15px' }} >
									<Col md={4} >
										<label>User Role</label>
									</Col>
									<Col md={8} >
										<input type="text" className="form-control input-sm" readOnly value={this.getValue('role')} />
									</Col>
								</Row>
								<Row style={{ marginBottom: '15px' }} >
									<Col md={4} >
										<label>Last Login</label>
									</Col>
									<Col md={8} >
										<input type="text" className="form-control input-sm" readOnly value={this.getValue('lastlogin')} />
									</Col>
								</Row>
								<Row style={{ marginBottom: '15px' }} >
									<Col md={4} >
										<label>Last IP</label>
									</Col>
									<Col md={8} >
										<input type="text" className="form-control input-sm" readOnly value={this.getValue('lastip')} />
									</Col>
								</Row>
								
							</Card.Body>
						</Card>
					
					</Col>
					
		
					<Col md={{ span: 8,offset: 2 }} style={{ marginTop: '15px' }} >
						
						<Card>							
							<Card.Header>
								Update Password
							</Card.Header>
							<Card.Body>
							<form action="" method="post" encType="multipart/form-data" id="frmPass" onSubmit={this.handlePasswordUpdate} >

								<input type="hidden" name="i__masterid" value={this.state.masterId} />
								<input type="hidden" name="i__company" value={this.state.companyId} />
								<input type="hidden" name="i__loginid" value={this.state.loginId} />

								<Row style={{ marginBottom: '15px' }} >
									<Col md={4} >
										<label>New Password</label>
									</Col>
									<Col md={8} >
										<input type="password" className="form-control input-sm" required name="i__newpass" id="i__newpass" />
									</Col>
								</Row>
								
								<Row style={{ marginBottom: '15px' }} >
									<Col md={4} >
										<label>Confirm Password</label>
									</Col>
									<Col md={8} >
										<input type="password" className="form-control input-sm" required name="i__confirmpass" id="i__confirmpass" />
									</Col>
								</Row>
									
								<Row style={{ marginBottom: '15px' }} >
									<Col md={12} >
										<div className="text-right" >
											
											<button type="submit" className="btn btn-success" disabled={this.state.saveText!='Update Password'} >
												{this.state.saveText} 
											</button>
											
										</div>
									</Col>
								</Row>
									
							</form>
							</Card.Body>
						</Card>
					
					</Col>
					
		
				</Row>
				
			</div>
		);
	}
	
}
export default MyProfile;
import React, { Component } from 'react';
import {Redirect,Link} from 'react-router-dom';
import $ from 'jquery';
import {appName,copyRight} from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import Notifications, {notify} from 'react-notify-toast';
import {Helmet} from 'react-helmet';
import {Row,Col,Card} from 'react-bootstrap';
import swal from 'sweetalert';
import Cookies from 'universal-cookie';

import ApiService from '../../utils/ApiService';

class SingleArticle extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginText: 'Login',
			isRedirect: false,
			showLoader: false,
			username: this.props.match.params.username,
			articlename: this.props.match.params.articlename,
		}
	}
	
	
	componentDidMount(){
		
		ApiService.post('v1/login',{param:''}).then(res=>{
			console.log(res);
		});
	}

	render(){
		
	
		return(
			<div style={{ padding: 50 }} >
				
				<h3>Name of the Article is {this.state.articlename} </h3>
				<p>
					<Link to={"/"+this.state.username} > Go to Profile </Link> &emsp;
					<Link to={"/"+this.state.username+"/articles"} > Go to Articles </Link>
				</p>
			</div>
		);
	}
	
}
export default SingleArticle;
import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {copyRight} from '../../../utils/Variables';
import * as Reqst from '../../../utils/Reqst';
import Sanitize from '../../../utils/Sanitize';
import {notify} from 'react-notify-toast';
import {Nav,Navbar,NavDropdown,Row,Col,Table,Modal} from 'react-bootstrap';
import swal from 'sweetalert';

import FullPageLoader from '../../../containers/FullPageLoader';

const styles = {
	testTd: {
	    fontSize: '15px',
		fontWeight: '600',
		borderBottom: '1px solid #dedede',
		paddingBottom: '5px',
		marginBottom: '3px',
		cursor: 'pointer',
	},
	unitStyle: {
		fontSize: '12px',
		paddingLeft: '10px',
	},
	rangeStyle: {
		fontSize: '13px',
		color: 'grey',
	},
	priceStyle: {
		float: 'right',
		paddingRight: '30px',
	}
}

class View extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginId: Sanitize.getData('loginUser_id'),
			companyId: Sanitize.getData('labUser_clientid'),
			masterId: Sanitize.getData('labUser_masterid'),
			saveText: 'SAVE PROFILE',
			isRedirect: false,
			showLoader: false,
			showFullLoader: false,
			showAddModal: false,
			showTestList: false,
			profileData: this.props.profileData,
			testList: [],
			selectedTest: [],
		}
		
	}
	
	componentDidMount(){
		
		this.loadGroupMasters();
	}
	
	loadGroupMasters(){
		this.setState({showFullLoader:true});
		var token = Reqst.TimeStamp();
		Reqst.axGet('groupmaster/getactivegroups/'+this.state.masterId+'/'+this.state.companyId+'/'+token).then(res=>{
			if(res['data'].status=='1')
			{
				this.setState({testList: res['data'].data, showFullLoader:false});	
			}
			else
			{
				this.setState({showFullLoader:false});	
			}
		});
	}
	
	
	render(){
		
		return(
			<div>			
				<FullPageLoader loading={this.state.showFullLoader} />
				
				<Row>
					<Col md={6} >
						<Row>
							<Col md={6} >
								<div className="input-td-label" > CODE : </div>
							</Col>
							<Col md={6} >
								<div className="input-td-label" > 254000125 </div>
							</Col>
						</Row>
					</Col>
					<Col md={6} >
						<Row>
							<Col md={6} >
								<div className="input-td-label" > RATE : </div>
							</Col>
							<Col md={6} >
								<div className="input-td-label" > 254000125 </div>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>					
					<Col md={3} >
						<div className="input-td-label" > NAME : </div>
					</Col>
					<Col md={9} >
						<div className="input-td-label" > PROFILE NAME </div>
					</Col>		
				</Row>
				
				<Row>					
					<Col md={3} >
						<div className="input-td-label" > GROUP : </div>
					</Col>
					<Col md={9} >
						<div className="input-td-label" > GROUP NAME </div>
					</Col>											
				</Row>
				
			</div>
		);
	}
	
}
export default View;
import React, { Component } from 'react';
import {Redirect, Link} from 'react-router-dom';
import $ from 'jquery';
import {appName,copyRight} from '../../../utils/Variables';
import * as Reqst from '../../../utils/Reqst';
import Sanitize from '../../../utils/Sanitize';
import {notify} from 'react-notify-toast';
import {Helmet} from "react-helmet";
import {Nav,Navbar,NavDropdown,Row,Col,Table,Modal,DropdownButton, Dropdown} from 'react-bootstrap';
import Toggle from 'react-bootstrap-toggle';
import "react-bootstrap-toggle/dist/bootstrap2-toggle.css";
import Roles from '../../../utils/Roles';
import swal from 'sweetalert';
import Pagination from "react-js-pagination";

import PageTitle from '../../../containers/PageTitle';
import PatientSearch from '../../Manage/PatientMaster/Search';

const styles = {
	referenceStyle: {
	    fontSize: '12px',
		color: '#006ebf',
		fontWeight: '600',
	}
}

class Entry extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginId: Sanitize.getData('loginUser_id'),
			companyId: Sanitize.getData('labUser_clientid'),
			masterId: Sanitize.getData('labUser_masterid'),
			saveText: 'SAVE REPORT',
			isRedirect: false,
			showAddModal: false,
			showSearchModal: false,
			showLoader:true,
			groupList: [],
			testList: [],
			testView: [],
			profileList: [],
			profileView: [],
			doctorsList: [],
			selectedPatient: [],
			selectedTest: [],
			reportId: '0',
			reportNo: '0',
			reportTime: '',
			reportDate: '',
			today: '',
			doctorpercentage: '0',
		}
		
		this.firstLoad = this.firstLoad.bind(this);
		this.setSelectedPatient = this.setSelectedPatient.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.updateDoctorPercentage = this.updateDoctorPercentage.bind(this);
	}
	
	componentDidMount(){
		
		var today = new Date();
		var date = today.getFullYear()+'-'+('0' + (today.getMonth()+1)).slice(-2)+'-'+('0' + today.getDate()).slice(-2);
		
		var time = today.getHours() + ":" + today.getMinutes();// + ":" + today.getSeconds();
		
		var todayDate = ('0' +(today.getMonth()+1)).slice(-2)+'/'+ ('0' +today.getDate()).slice(-2)+'/'+ today.getFullYear();
		
		this.setState({today:date, reportDate:todayDate, reportTime:Sanitize.formatAMPM(new Date(),false)});
		this.firstLoad();
	}
	
	setSelectedPatient(patient){
		try{
			var p = patient[0];
			this.setState({selectedPatient: p,showSearchModal: false});
		}
		catch(error){ }
	}
	
	firstLoad()
	{
		this.loadReportNo();
		this.loadGroupList();
		this.loadTestList();
		this.loadProfileList();
		this.loadDcoctorsList();
	}
	
	loadReportNo(){
		this.setState({showLoader:true});
		var token = Reqst.TimeStamp();
		Reqst.axGet('reportmaster/reportno/'+this.state.masterId+'/'+this.state.companyId+'/'+token).then(res=>{
			if(res['data'].status=='1')
			{
				this.setState({reportId: res['data'].reportid, reportNo: res['data'].reportno, showLoader:false});	
			}
			else
			{
				//notify.show(res['data'].msg,'error');
				this.setState({showLoader:false});	
			}
		});
	}
	
	loadGroupList(){
		this.setState({showGroupLoader:true});
		var token = Reqst.TimeStamp();
		Reqst.axGet('groupmaster/getactivegroups/'+this.state.masterId+'/'+this.state.companyId+'/'+token).then(res=>{
			if(res['data'].status=='1')
			{
				this.setState({groupList: res['data'].data, showGroupLoader:false});	
			}
			else
			{
				notify.show(res['data'].msg,'error');
				this.setState({showGroupLoader:false});	
			}
		});
	}
	
	loadTestList(){
		this.setState({showTestLoader:true});
		var token = Reqst.TimeStamp();
		Reqst.axGet('testmaster/loadactivetest/'+this.state.masterId+'/'+this.state.companyId+'/'+token).then(res=>{
			if(res['data'].status=='1')
			{
				this.setState({testList: res['data'].data, testView: res['data'].data, showTestLoader:false});	
			}
			else
			{
				notify.show(res['data'].msg,'error');
				this.setState({showTestLoader:false});	
			}
		});
	}
	
	loadProfileList(){
		this.setState({showProfileLoader:true});
		var token = Reqst.TimeStamp();
		Reqst.axGet('profilemaster/getactiveprofilelist/'+this.state.masterId+'/'+this.state.companyId+'/'+token).then(res=>{
			if(res['data'].status=='1')
			{
				this.setState({profileList: res['data'].data, profileView: res['data'].data, showProfileLoader:false});	
			}
			else
			{
				notify.show(res['data'].msg,'error');
				this.setState({showProfileLoader:false});	
			}
		});
	}
	
	loadDcoctorsList(){
		this.setState({showLoader:true});
		var token = Reqst.TimeStamp();
		Reqst.axGet('doctorsmaster/getactivedoctorslist/'+this.state.masterId+'/'+this.state.companyId+'/'+token).then(res=>{
			if(res['data'].status=='1')
			{
				this.setState({doctorsList: res['data'].data, showLoader:false});	
			}
			else
			{
				notify.show(res['data'].msg,'error');
				this.setState({showLoader:false});	
			}
		});
	}
	
	handleTestSearch(e){
		this.setState({showTestLoader:true});
		var v = e.target.value.toUpperCase();
		var d = this.state.testList.filter(function(obj){
			const tname = obj.testname.toUpperCase();
			return (tname.indexOf(v)>-1);
		});
		this.setState({testView: d, showTestLoader: false});
	}
	
	handleGroupClick(groupId,e){
		this.setState({showProfileLoader: true });
		var l = this.state.profileList;
		var d = l.filter(function(obj){ return obj.groupid==groupId; });
		this.setState({showProfileLoader: false, profileView: d});
	}
	
	handleTestClick(testId,profileId='',profileName=''){
		var d = this.state.testList;
		var l = d.filter(function(obj){ return obj.id==testId;});
		var p = [];
		if(Object.keys(l).length>0)
		{
			var t = l[0];
			p.push({groupid:t.groupid,profileid:profileId, profilename: profileName,testid: t.id,testcode: t.testcode,price: t.price,referencecondition: t.referencecondition, referencerange: t.referencerange,femalerange: t.femalerange,testname: t.testname,testunit: t.testunit});
			var s = this.state.selectedTest;
			var index = s.findIndex(function(obj){ return obj.testid==testId; });
			if(index==-1)
			{
				s.push(p[0]);
				this.setState({selectedTest:s});
			}
		}
	}
	
	getTotalPrice(){
		var d = this.state.selectedTest;
		var total = 0;
		d.map((item,i)=>{
			total += parseFloat(item.price);
		});
		var dis = $("#i__discount").val();
		var net = total;
		if(dis!='' && dis!=null) net = parseFloat(total) - parseFloat(dis);
		return ({total:parseFloat(total),discount:'0.00',netamount:net});
	}
	
	handleRemoveClick(testId,index,e){
		swal({ title: "Confirm",text:'Do you want to remove the Test?',buttons:['No','Yes'] }).then(yes=>{
			if(yes){
				var d = this.state.selectedTest;
				d.splice(index,1);
				this.setState({selectedTest:d});
			}
		});
	}
	
	handleProfileClick(profileId,e){
		var p = this.state.profileList;
		var l = p.filter(function(obj){ return obj.id==profileId; });
		var tList = l[0]['testlist'].split(',');
		for(var i=0;i<tList.length;i++)
		{
			this.handleTestClick(tList[i],l[0].id,l[0].profilename);
		}
	}
	
	updateDoctorPercentage(e)
	{
		try{
			var did = e.target.value;
			var d = this.state.doctorsList.filter(function(obj){
				return (obj.id==did);
			});
			
			this.setState({doctorpercentage: d[0].commision});
		}
		catch(error){
			
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();

		if(Object.keys(this.state.selectedPatient).length<1)
		{
			notify.show('Please select Patient Info.!','warning');
			return;
		}
		
		this.setState({showLoader:true,saveText: 'Saving..'});
		Reqst.axPost('reportmaster/save/',$("#frmReport").serialize()).then(res=>{
			if(res['data'].status=='1')
			{
				document.getElementById("frmReport").reset();
				this.setState({showLoader:false,saveText: 'SAVE REPORT', reportId: res['data'].reportid, reportNo: res['data'].reportno, selectedTest: [], selectedPatient: []});
				notify.show(res['data'].msg,'success');
			}
			else
			{
				this.setState({showLoader:false,saveText: 'SAVE REPORT'});
				notify.show(res['data'].msg,'error');
			}
		});
	}
	
	render(){
		
		let sno=1;
		const patient = this.state.selectedPatient;
		
		const total = this.getTotalPrice();
	
		return(
			<div>
			
				<Helmet>
					<title>{appName} | Report Entry</title>
				</Helmet>
				
				<PageTitle title="Report Entry" loading={this.state.showLoader} > 
					<div className="text-right">
						<Link className="btn btn-primary btn-sm" to="/reports/reportslist" >
							<i className="fa fa-arrow-left"></i>  Back to List
						</Link>
					</div>	
				</PageTitle>
				
				<form id="frmReport" action="" method="post" encType="multipart/form-data" onSubmit={this.handleFormSubmit} >
				
					<input type="hidden" name="i__lid" value={this.state.loginId} />
					<input type="hidden" name="i__company" value={this.state.companyId} />
					<input type="hidden" name="i__master" value={this.state.masterId} />
					<input type="hidden" name="i__pid" value={Object.keys(patient).length>0 ? patient.id : ''} />
					<input type="hidden" name="i__docotrpercentage" value={this.state.doctorpercentage} />
					<input type="hidden" name="i__test" value={JSON.stringify(this.state.selectedTest)} />
					
				<Row>
					
					<Col md={8} >
						<Row>
							<Col md={4} >
								<Row>
									<Col md={4}><div className="text-right mt-8" >Pt. ID</div></Col>
									<Col md={8}>
										<input type="text" className="form-control input-sm" readOnly value={Object.keys(patient).length>0 ? patient.patientcode : ''} name="i__pcode" id="i__pcode" />
										<button type="button" className="btn-pt-search" onClick={()=>{this.setState({showSearchModal:true})}} >
											<i className="fa fa-search"></i>
										</button>
										{/*<button type="button" className="btn-pt-add" >
											<i className="fa fa-plus"></i>
										</button>*/}
									</Col>
								</Row>
							</Col>
							<Col md={8} >
								<Row>
									<Col md={3}><div className="text-right mt-8" >Patient Name</div></Col>
									<Col md={9}>
										<input type="text" className="form-control input-sm" defaultValue="" readOnly value={Object.keys(patient).length>0 ? patient.patientname : ''} />
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col md={4} >
								<Row>
									<Col md={4}><div className="text-right mt-8" >Age/Sex</div></Col>
									<Col md={8}>
										<input type="text" name="i__agesex" className="form-control input-sm" defaultValue="" readOnly value={Object.keys(patient).length>0 ? Sanitize.getAge(patient.dob,patient.age) +' / '+ patient.gender : ''} />
									</Col>
								</Row>
							</Col>
							<Col md={8} >
								<Row>
									<Col md={3}><div className="text-right mt-8" >Address</div></Col>
									<Col md={9}>
										<input type="text" className="form-control input-sm" defaultValue=""  readOnly value={Object.keys(patient).length>0 ? patient.patientplace + patient.address : ''} />
									</Col>
								</Row>
							</Col>
						</Row>
						<Row>
							<Col md={4} >
								<Row>
									<Col md={4}><div className="text-right mt-8" >Mobile</div></Col>
									<Col md={8}>
										<input type="text" className="form-control input-sm" defaultValue="" readOnly value={Object.keys(patient).length>0 ? patient.mobile : ''} />
									</Col>
								</Row>
							</Col>
							<Col md={8} >
								<Row>
									<Col md={3}><div className="text-right mt-8" >Ref. Doctor</div></Col>
									<Col md={9}>
										<select name="i__doctor" id="i__doctor" className="form-control input-sm" required onChange={this.updateDoctorPercentage} >
											<option value=""> -Select- </option>
											{
												this.state.doctorsList.map((item,i)=>{
													return <option key={i} value={item.id} > {item.doctorname} </option>;
												})
											}
										</select>
									</Col>
								</Row>
							</Col>
						</Row>
					</Col>
					<Col md={4} >
						<Row>
							<Col md={3} className="no-padding" ><div className="text-right mt-8" >Report ID</div></Col>
							<Col md={4} className="pad-right-0" >
								<input type="text" readOnly className="form-control input-sm" defaultValue="1" value={this.state.reportNo} />
							</Col>
							<Col md={5} className="pad-left-0" >
								<input type="text" readOnly className="form-control input-sm input-test-rate" defaultValue="0.00" value={parseFloat(total.netamount).toFixed(2)} />
							</Col>
						</Row>
						<Row>
							<Col md={3} className="no-padding" ><div className="text-right mt-8" >Report Date</div></Col>
							<Col md={5} className="pad-right-0" >
								<input type="date" className="form-control input-sm" name="i__rdate" id="i__rdate" defaultValue={this.state.reportDate} required max={this.state.today} />								
							</Col>
							<Col md={4} className="pad-left-0" >
								<input type="time" className="form-control input-sm" defaultValue={this.state.reportTime} name="i__rtime" id="i__rtime" />
							</Col>
						</Row>
						<Row>
							<Col md={3} className="no-padding" ><div className="text-right mt-8" >Sample Date</div></Col>
							<Col md={5} className="pad-right-0" >
								<input type="date" className="form-control input-sm" defaultValue="" name="i__sdate" id="i__sdate" max={this.state.today}  />
							</Col>
							<Col md={4} className="pad-left-0" >
								<input type="time" className="form-control input-sm" defaultValue="" name="i__stime" id="i__stime" />
							</Col>
						</Row>
					</Col>
				</Row>
				
				<Row>
					<Col md={12} style={{ margin: 4 }} > </Col>
				</Row>
				
				<Row>
					<Col md={9} >
						<table align="center" width="100%" className="test-table my-list-table" >
							<thead>
								<tr>
									<th width="60" >S.No</th>
									<th>Profile Name</th>
									<th>Test Name</th>
									<th>Range</th>
									<th>Result</th>
									{/*<th>Reference</th>*/}
									<th width="70" >Amount</th>
									<th width="35"></th>
								</tr>
							</thead>
							<tbody className="scroll-style-3" >
							{Object.keys(this.state.selectedTest).length>0 ?
								this.state.selectedTest.map((item,i)=>{
									
									return <tr key={i} >
										<td align="center" >{sno++}</td>
										<td>{item.profilename}</td>
										<td>{item.testname}</td>
										<td>{item.referencecondition} <br /><span style={styles.referenceStyle} >{item.referencerange}</span></td>
										<td>
											<input type="text" style={{ width:'100%'}} name={"i__result["+ item.testid + "]"} />
										</td>
										{/*<td>
											<input type="text" style={{ width:'100%'}} />
										</td>*/}
										<td align="right" >{item.price}</td>
										<td align="center" >
											<button type="button" className="btn btn-danger btn-xs" onClick={this.handleRemoveClick.bind(this,item.testid,i)} >
												<i className="fa fa-remove"></i>
											</button>
										</td>
									</tr>;
								})
								:
								<tr>
									<td colSpan="8" align="center">
										Data Not Found..!
									</td>
								</tr>								
							}
							</tbody>
						</table>
						<Row>
							<Col md={12} >
								<table width="100%" >
									<tr>
										<td rowSpan="3" >
											<label>Notes :-</label>
											<textarea name="i__notes" className="form-control input-sm" rows="3" ></textarea>
										</td>
										<td width="100" align="right" >Total : &nbsp;</td>
										<td width="150" >
											<input type="text" readOnly name="i__total" id="i__total" className="form-control input-sm" value={parseFloat(total.total).toFixed(2)} style={{ textAlign: 'right' }} />
										</td>
									</tr>
									<tr>
										<td align="right"  >Discount : &nbsp;</td>
										<td>
											<input type="number" name="i__discount" id="i__discount" className="form-control input-sm" onChange={(e)=>{this.setState({i__discount: e.target.value});}} value={this.state.i__discount} style={{ textAlign: 'right' }} />
										</td>
									</tr>
									<tr>
										<td align="right"  >Net Amount : &nbsp;</td>
										<td>
											<input type="text" name="i__netamount" id="i__netamount" readOnly className="form-control input-sm" value={parseFloat(total.netamount).toFixed(2)} style={{ textAlign: 'right' }} />
										</td>
									</tr>
								</table>
							</Col>
						</Row>
						<Row>
							<Col md={12} >
								<hr />
								<div className="text-right" >
									<button type="submit" className="btn btn-success btn-sm" disabled={this.state.saveText!='SAVE REPORT'} >
										{this.state.saveText}
									</button>
								</div>
							</Col>
						</Row>
					</Col>
					<Col md={3} >
						<Row>
							<Col md={12} >
								<h5 className="group-title" >Group
								{this.state.showGroupLoader ? <span className="loader-right" >
										<i className="fa fa-spinner fa-spin"></i>
								</span> : null}
								</h5>
								<ul className="group-data scroll-style-3" >
								{this.state.groupList.map((group,key)=>{
									return <li key={key} 
											onClick={this.handleGroupClick.bind(this,group.id)} >{group.groupname}</li>;
								})
								}
								</ul>
							</Col>
							<Col md={12} style={{ marginTop: '-15px' }}  >
								<h5 className="group-title" >Profile
								{this.state.showProfileLoader ? <span className="loader-right" >
										<i className="fa fa-spinner fa-spin"></i>
								</span> : null}
								</h5>
								<ul className="group-data scroll-style-3" >
								{Object.keys(this.state.profileView).length > 0 ?
									this.state.profileView.map((profile,key)=>{
									return <li key={key} 
											onClick={this.handleProfileClick.bind(this,profile.id)} >{profile.profilename}</li>;
								}) : <li className="not-found" >
									data not found..!
								</li>
								}
								</ul>
							</Col>
							<Col md={12} style={{ marginTop: '-15px' }}  >
								<h5 className="group-title" >Test
									<input type="text" style={{ marginLeft: '35px',  width: '140px' }} onChange={this.handleTestSearch.bind(this)} />
								{this.state.showTestLoader ? <span className="loader-right" >
										<i className="fa fa-spinner fa-spin"></i>
								</span> : null}
								</h5>
								<ul className="group-data scroll-style-3" >
								{this.state.testView.map((test,key)=>{
									return <li key={key} onClick={this.handleTestClick.bind(this,test.id,'','')} >{test.testname}</li>;
								})
								}
								</ul>
							</Col>
						</Row>
					</Col>
					
				</Row>
				</form>
				
				<Modal size="lg" show={this.state.showSearchModal} onHide={()=>{this.setState({showSearchModal:false})}} className="" >
					<Modal.Header closeButton >
						<Modal.Title>Search Patient</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<PatientSearch afterFinish={this.setSelectedPatient} />
					</Modal.Body>
				</Modal>
				
			</div>
		);
	}
	
}
export default Entry;
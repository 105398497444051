import React, { Component } from 'react';
import Cookies from 'universal-cookie';


class Roles {
	
	static Can(groupName,menuName,option)
	{
		try
		{
			const cookie = new Cookies();
			var role = window.localStorage.getItem('lab_userRoles');
			//var role = cookie.get('lab_userRoles');
			var roleList = JSON.parse(role);
			
			if(Object.keys(roleList).length>0)
			{
				var dt = roleList.filter(function(obj){
					return ((obj.groupname==groupName) && (obj.menuname==menuName));
				});
				
				if(dt.length>0)
				{
					var len = dt.length;
					if(len>0)
					{
						var opt = dt[len-1][option];
						return (opt==='1') ? true : false;
					}
					else
					{
						return false;
					}
				}
				else
				{
					return false;
				}
			}
			else
			{
				return false;
			}			
		}
		catch(error)
		{
			console.log(error);
			return false;
		}
	}
	
	static CanMenu(groupName,menuName)
	{
		try
		{
			const cookie = new Cookies();
			
			var role = window.localStorage.getItem('lab_userRoles');
			
			var roleList = JSON.parse(role);
			if(Object.keys(roleList).length>0)
			{
				var dt = roleList.filter(function(obj){
					return ((obj.groupname==groupName) && (obj.menuname==menuName));
				});
				
				if(dt.length>0)
				{
					return true;
				}
				else
				{
					return false;
				}
			}
			else
			{
				return false;
			}			
		}
		catch(error)
		{
			console.log(error);
			return false;
		}
	}
	
}
export default Roles;
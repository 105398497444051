import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {copyRight,imagePath} from '../../../utils/Variables';
import * as Reqst from '../../../utils/Reqst';
import Sanitize from '../../../utils/Sanitize';
import {notify} from 'react-notify-toast';
import {Nav,Navbar,NavDropdown,Row,Col,Table,Modal} from 'react-bootstrap';
import NOIMAGE from '../../../assets/img/noimage.jpg';
import ARROW_TOP_RIGHT from '../../../assets/img/arrow-top-right.png';
import FullPageLoader from '../../../containers/FullPageLoader';
import ReactFileReader from 'react-file-reader';

const styles = {
	patientImg: {
		width: '169px',
		cursor: 'pointer',
		border: '1px solid #dcdcdc',
	}
}

class Add extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginId: Sanitize.getData('loginUser_id'),
			companyId: Sanitize.getData('labUser_clientid'),
			masterId: Sanitize.getData('labUser_masterid'),
			saveText: 'UPDATE PROFILE',
			isRedirect: false,
			showLoader: false,
			showFullLoader: false,
			showAddModal: false,
			groupData: [],
			patientId: '',
			patientCode: '',
			selectedFile: '',
			isUpdated: '0',
			editData: this.props.editData,
		}
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleFiles = this.handleFiles.bind(this);
	}
	
	componentDidMount(){
		
		//this.loadPatientId();
		var pic = this.props.editData.patientpicture;
		if(pic.length>5)
		{
			pic = imagePath.patientImage+pic;
			this.setState({selectedFile: pic});
		}
	}
	
	loadPatientId(){
		this.setState({showFullLoader:true});
		var token = Reqst.TimeStamp();
		Reqst.axGet('patientmaster/getpatientid/'+this.state.masterId+'/'+this.state.companyId+'/'+token).then(res=>{
			if(res['data'].status=='1')
			{
				this.setState({patientId: res['data'].pid, patientCode: res['data'].pcode, showFullLoader:false});	
			}
			else
			{
				this.setState({showFullLoader:false});	
			}
		});
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		this.setState({showLoader:true,saveText:'Updating..'});
		//var formData = Sanitize.objectifyForm($("#frmAdd").serializeArray());
		Reqst.axPost('patientmaster/updateprofile',$("#frmAdd").serialize()).then(res=>{
			if(res['data'].status=='1')
			{
				notify.show(res['data'].msg,'success');
				this.setState({showLoader:false,saveText:'UPDATE PROFILE'});
				if(this.props.afterFinish) this.props.afterFinish();
			}
			else
			{
				notify.show(res['data'].msg,'error');
				this.setState({showLoader:false,saveText:'UPDATE PROFILE'});
			}
		});
	}
	
	handleFiles(files){
		try{
			
			this.setState({selectedFile: files.base64,isUpdated: '1'});
		}
		catch(error){ }
	}


	getData(field){
		var l = this.state.editData;
		return l[field];
	}
	
	setData(field,e){
		var l = this.state.editData;
		l[field] = e.target.value;
		this.setState({editData: l });
	}
	
	render(){
		
		const IMG = this.state.selectedFile.length>5 ? this.state.selectedFile : NOIMAGE;
		
		return(
			<div>			
				<FullPageLoader loading={this.state.showFullLoader} />
				
				<form  action="" method="post" encType="multipart/form-data" id="frmAdd" onSubmit={this.handleFormSubmit} >
					<input type="hidden" name="i__id" value={this.getData('id')} />
					<input type="hidden" name="i__lid" value={this.state.loginId} />
					<input type="hidden" name="i__company" value={this.state.companyId} />
					<input type="hidden" name="i__master" value={this.state.masterId} />
					<input type="hidden" name="i__picture" value={this.state.selectedFile} />
					<input type="hidden" name="i__pictureupdated" value={this.state.isUpdated} />
					
					<Row>
						<Col md={9} >
							<Row>
								<Col md={6} >
									<label >Patient Id <span className="text-danger">*</span></label>
									<input type="text" name="i__code" id="i__code" className="form-control input-sm"  value={this.getData('patientcode')} readOnly />
								</Col>
								<Col md={6} >
									<label >Patient Type <span className="text-danger">*</span></label>
									<select name="i__type" id="i__type" className="form-control input-sm" required >
										<option value="NORMAL" selected={this.getData('patienttype')=='NORMAL' ? 'selected' : ''} > NORMAL </option>
										<option value="VIP" selected={this.getData('patienttype')=='VIP' ? 'selected' : ''} > VIP </option>
										<option value="VVIP" selected={this.getData('patienttype')=='VVIP' ? 'selected' : ''} > VVIP </option>
									</select>
								</Col>
							</Row>
							<Row>
								<Col md={6} >
									<label >Patient Name <span className="text-danger">*</span></label>
									<input type="text" name="i__name" id="i__name" className="form-control input-sm" required value={this.getData('patientname')} onChange={this.setData.bind(this,'patientname')} />
								</Col>
								<Col md={6} >
									<label >Patient Mobile <span className="text-danger">*</span></label>
									<input type="number" name="i__mobile" id="i__mobile" className="form-control input-sm" required value={this.getData('mobile')} onChange={this.setData.bind(this,'mobile')} />
								</Col>
							</Row>
							<Row>
								<Col md={4} >
									<label >Date of Birth <span className="text-danger"></span></label>
									<input type="date" name="i__dob" id="i__dob" className="form-control input-sm" value={this.getData('dob')} onChange={this.setData.bind(this,'dob')} />
								</Col>
								<Col md={2} >
									<label >Age <span className="text-danger"></span></label>
									<input type="number" name="i__age" id="i__age" className="form-control input-sm" value={this.getData('age')} onChange={this.setData.bind(this,'age')} />
								</Col>
								<Col md={6} >
									<label >Gender <span className="text-danger">*</span></label>
									<select name="i__gender" id="i__gender" className="form-control input-sm" required >
										<option value="Male" selected={this.getData('gender')=='Male' ? 'selected' : ''}  > Male </option>
										<option value="Female" selected={this.getData('gender')=='Female' ? 'selected' : ''} > Female </option>
										<option value="TG" selected={this.getData('gender')=='TG' ? 'selected' : ''} > TG </option>
									</select>
								</Col>
							</Row>
							<Row>
								<Col md={6} >
									<label >Place <span className="text-danger"></span></label>
									<input type="text" name="i__place" id="i__place" className="form-control input-sm" value={this.getData('patientplace')} onChange={this.setData.bind(this,'patientplace')}  />
										<label >Email Id <span className="text-danger"></span></label>
									<input type="email" name="i__email" id="i__email" className="form-control input-sm"  value={this.getData('email')} onChange={this.setData.bind(this,'email')} />
								</Col>
								<Col md={6} >
									<label >Address <span className="text-danger"></span></label>
									<textarea type="text" name="i__address" id="i__address" className="form-control input-sm"  rows="5" value={this.getData('address')} onChange={this.setData.bind(this,'address')} ></textarea>
								</Col>
							</Row>
							<Row>
								<Col md={6} >
									<label >Whatsapp <span className="text-danger"></span></label>
									<input type="number" name="i__whatsapp" id="i__whatsapp" className="form-control input-sm" value={this.getData('whatsapp')} onChange={this.setData.bind(this,'whatsapp')}  />
								</Col>
								<Col md={6} >
									<label>Active Status <span className="text-danger">*</span></label>
									<select name="i__status" id="i__status" className="form-control input-sm" required >
										<option value="1" selected={this.getData('teststatus')=='1' ? 'selected' : ''} > Active </option>
										<option value="0" selected={this.getData('teststatus')=='0' ? 'selected' : ''}  > In Active </option>
									</select>
								</Col>
								<Col md={12} >
									<label>Notes <span className="text-danger"></span></label>
									<textarea className="form-control input-sm" rows="3" name="i__remarks" id="i__remarks" value={this.getData('remarks')} onChange={this.setData.bind(this,'remarks')} ></textarea>
								</Col>
							</Row>
							
						</Col>
						<Col md={3} >
							<ReactFileReader handleFiles={this.handleFiles} base64={true} >
								<img src={IMG} style={styles.patientImg} />
							</ReactFileReader>
							{this.state.selectedFile.length>5 ?
							<button type="button" className="btn btn-warning btn-block btn-sm" onClick={()=>{this.setState({selectedFile:'',isUpdated:'0'})}} >
								Remove Image
							</button> : null}
							
							<div className="text-center" >
								<img src={ARROW_TOP_RIGHT} />
								<br />
								Click over the image to upload new one.!
							</div>
							
						</Col>
					</Row>
					
					
					
				
					<Row>
						<Col md={12} >
							<div className="text-right" style={{ marginTop: 25 }} >
								{/*<button type="reset" className="btn btn-danger btn-sm btn-danger" disabled={this.state.saveText!='UPDATE PROFILE'} >
									<i className="fa fa-remove"></i> CLEAR
								</button>*/}
							&nbsp;
								<button type="submit" className="btn btn-primary btn-sm btn-success_2" disabled={this.state.saveText!='UPDATE PROFILE'} >
									<i className="fa fa-check"></i> {this.state.saveText} 
								</button>
							</div>
						</Col>
					</Row>
				</form>
			</div>
		);
	}
	
}
export default Add;
import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {appName,copyRight} from '../../utils/Variables';
import * as Reqst from '../../utils/Reqst';
import Sanitize from '../../utils/Sanitize';
import Roles from '../../utils/Roles';
import {notify} from 'react-notify-toast';
import {Helmet} from "react-helmet";
import {Nav,Navbar,NavDropdown,Row,Col,Table,Modal,DropdownButton, Dropdown} from 'react-bootstrap';
import Toggle from 'react-bootstrap-toggle';
import "react-bootstrap-toggle/dist/bootstrap2-toggle.css";

import PageTitle from '../../containers/PageTitle';

//import * as Roles from './RoleList';

const styles = {
	uCase: {
		textTransform: 'uppercase',
	}
}

class EditRoles extends Component{
	constructor(props){
		super(props);
		this.state = {
			loginId: Sanitize.getData('loginUser_id'),
			companyId: Sanitize.getData('labUser_clientid'),
			masterId: Sanitize.getData('labUser_masterid'),
			loginText: 'LOGIN',
			isRedirect: false,
			showLoader: false,
			showAddModal: false,
			showEditModal: false,
			dataList: [],
			dataView: [],
		}
		
		
	}
	
	componentDidMount(){
		
		this.loadRoleList();
	}
	
	loadRoleList(){
		this.setState({showLoader:true});
		Reqst.axGet('userrole/getrolelist/'+this.state.masterId+'/'+this.state.companyId).then(res=>{
			if(res['data'].status=='1')
			{
				this.setState({dataView: res['data'].data, showLoader:false});
			}
			else
			{
				this.setState({showLoader:false});
			}
		});
	}
	
	render(){
		
		let sno=1;
		
		return(
			<div>
				<Row>
					<Col md={12} >
						<Table hover >
							<thead>
								<tr>
									<th>S.No</th>
									<th>Module</th>
									<th>Menu Group</th>
									<th>Menu</th>
									<th>List</th>
									<th>Add</th>
									<th>Edit/Update</th>
									<th>Delete</th>
									<th>Print</th>
									<th>Export</th>
								</tr>
							</thead>
							<tbody>
							{
								this.state.dataView.map((role,key)=>{
									return <tr key={key} >
										<td>{sno++}</td>
										<td style={styles.uCase} >{role.module}</td>
										<td style={styles.uCase} >{role.groupid}</td>
										<td style={styles.uCase}  >{role.menuid}</td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
										<td></td>
									</tr>;
								})
							}
							</tbody>
						</Table>
					</Col>
				</Row>
			</div>
		);
	}
	
}
export default EditRoles;